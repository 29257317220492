/* Style.module.css */
.selectedImages {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: 20px;
  margin-top: 2rem;
  border-radius: 10px;
  height: auto;
}

.selectedImage, .selectedFile {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 1; */
  background: #f8f9fa;
}

.selectedImage img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc3545;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fileIcon {
  font-size: 4rem;
  color: grey;
  margin-bottom: 1rem;
}

.fileName {
  text-align: center;
  word-wrap: break-word;
}
