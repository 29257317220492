.successImg {
  width: 8rem;
  height: 8rem;
}

.gretting {
  text-transform: uppercase;
  font-size: 3rem !important;
}
.word-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}
.wordTag {
  width: 40rem;
  text-align: center;
  font-size: 1.2rem;
}

.upload_button {
    margin-right: 1rem ;
  }

@media only screen and (max-width: 650px) {
  .wordTag {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }

  .gretting {
    text-transform: uppercase;
    font-size: 2rem !important;
    margin-top: 10px;
  }
  .successImg {
    width: 5rem;
    height: 5rem;
  }

  .upload_button {
    margin-right: 5px ;
  }
}

@media only screen and (max-width: 600px) {
   
.buttonBlock{
    margin-top: 1rem;
}
  }
