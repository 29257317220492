.property-card {
  /* box-shadow:
    1px 1px 100px rgba(0, 0, 0, 0.07)
  ;
; */
}
:root {
  --bg: #e8b5db;
  --ribbon1: #4f86f7;
  --ribbon2: #ff7f7f;
  --ribbon3: #bf7fff;
}
/* $theme-bg: #1e1935;
$theme-bg2: lighten(#1e1935, 30%);
$theme-color: #fff;
$ribbon-width: 300px; */

.c-ribbon {
  position: absolute;
  display: block;
  background: #198754;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  width: 10rem;
  z-index: 100;
  transform: rotate(-45deg);
  top: 1px;
  left: -61px;
}
&:hover {
  box-shadow: 5px 5px 5px darken(#1e1935, 5%);
}
.ribbon {
  position: absolute;
  top: 10px;
  left: -3px;
  background-color: var(--ribbon1);
  color: white;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(-30deg);
  transform-origin: left top;
  z-index: 1 !important;
  line-height: 34px;
}

.ribbon:before,
.ribbon:after {
  height: 0;
  width: 0;
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0;
  border-color: transparent var(--ribbon1);
}

.ribbon:before {
  left: -20px;
  border-width: 15px 20px 20px 0;
}

.ribbon:after {
  right: -20px;
  border-width: 15px 0 20px 20px;
}

.card-header {
  /* border: none !important; */
}
/* src/PropertyCard.css */
.property-card {
  /* margin: 20px; */
  padding: 15px;
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
  margin-top: 1rem;
}
.property-image {
  width: 100%;
  height: 300px; /* Set a fixed height for the carousel */
  overflow: hidden;
  border-radius: 5px;
}

.property-details {
  flex-grow: 1;
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}

.property-location {
  font-size: 14px;
  color: gray;
}

.property-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  margin-bottom: 0px;
}

.property-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 0px;
  margin-top: 5px;
}

.more-button {
  align-self: flex-start;
}

.property-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.property-actions button {
  margin-bottom: 5px;
}
/* src/PropertyList.css */
.property-location {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.badge-like {
  background-color: #f0f0f0; /* Background color of the badge */
  padding: 3px 8px;
  border-radius: 6px; /* Adjust as needed */
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.location-label {
  font-weight: bold;
  margin-right: 5px;
  color: #333; /* Darken the color for better readability */
}

.location-value {
  color: #555; /* Adjust color as needed */
}

.mainDataDiv {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 1rem;
  gap: 15px;
}

.badgeDataDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 2rem;
  border: 1px solid #000;
  padding: 1rem 1rem;
  border-radius: 2rem;
}
.iconDataDiv .faBed {
  font-size: 18px;
  display: flex;
  align-items: center;
  /* color: rgb(32, 237, 87); */
  justify-content: center;
  font-weight: 600;
}
.roomDataDiv .small {
  /* font-size: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
.small {
  font-weight: 600;
  font-size: 14px;
}
/* App.css */

.pdf-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  margin: 20px;
}

.pdf-preview {
  height: 400px;
  overflow: hidden;
}

.pdf-actions {
  padding: 10px;
  text-align: center;
  background-color: #f5f5f5;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
}

.download-button:hover {
  background-color: #0056b3;
}

@media (max-width: 1090px) {
  .mainCardBody {
    flex-direction: column;
  }
  .property-actions {
    margin-top: 2.4rem !important;
    flex-direction: row !important;
  }
  .property-card {
    border: 3px solid;
    display: flex;
    height: auto !important;
    width: auto;
  }

  .property-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .cardBody {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .property-image {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
    object-fit: cover !important;
  }

  .property-details {
    margin-left: 0;
    margin-top: 15px;
  }
  .property-card {
    border: 3px solid;
    display: flex;
    height: auto !important;
    width: auto;
  }

  .property-actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* flex-direction: row; */
  }
  .property-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .property-actions button {
    width: 100%; /* Ensure buttons are full width on smaller screens */
  }
  .mainDataDiv {
  }
  .badgeDataDiv {
    padding: 10px;
    border-radius: 3px;
    height: auto;
  }
}

.mainPropertyCard {
  width: 100%;
  position: relative;
  /* height: 30rem; */
  /* border: 2px solid red; */
  margin-top: 1rem;
  padding: 10px;
  border-radius: 6px;
}
/* .mainPropertyCardSecond{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
} */
/* .mainPropertySecondFirst {
  display: flex;
  width: 90%;
} */
/* .swiper-image {
  width: 55%;
  border-radius: 0.35rem !important;
} */
/* .main-image {
  height: 20rem;
  width: 100%;
  object-fit: fill !important;
  border-radius: 0.35rem !important;
} */
.swiper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.main-video {
  /* max-width: 20rem !important; */
  height: 20rem;
  width: 100%;
  object-fit: cover !important;
  border-radius: 0.35rem !important;
}

/* .actions-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -2rem;
} */

@media (max-width: 1350px) {
  .mainPropertyCard {
    width: 100%;
    /* height: 36rem; */
    /* border: 2px solid red; */
    margin-top: 1rem;
    padding: 15px;
    border: 2px;
  }
  .main-image {
    /* max-width: 30rem !important; */
    height: 20rem;
    width: 100%;
    object-fit: fill !important;
    border-radius: 0.35rem !important;
  }
  .main-video {
    height: 20rem;
    width: 100%;
  }
  .mainPropertySecondFirst {
    display: flex;
    width: 100%;
  }
  .swiper-image {
    width: 55%;
    border-radius: 0.35rem !important;
  }
  .location-value {
    display: inline-block;
    max-width: 100px; /* Adjust this value as needed */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

@media (max-width: 1120px) {
  .mainPropertyCard {
    width: 100%;
    height: 38rem;
    /* border: 2px solid red; */
    margin-top: 1rem;
    padding: 15px;
    border: 2px;
  }
  .location-value {
    display: inline-block;
    max-width: 100px; /* Adjust this value as needed */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

@media (max-width: 991px) {
  .mainPropertyCard {
    width: 100%;
    height: auto;
    /* border: 2px solid red; */
    margin-top: 1rem;
    padding: 0px;
    border: 2px;
  }
  .actions-btn {
    flex-direction: row;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    /* margin-left: -2rem; */
    width: 100%;
  }
}

@media (max-width: 1080px) {
  .mainPropertyCard {
    width: 100%;
    height: auto;
  }
  .mainPropertyCardSecond {
    flex-direction: column;
  }
  .actions-btn {
    flex-direction: row;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 1rem;
    /* margin-left: -2rem; */
    width: 100%;
  }
}

@media (max-width: 600px) {
  .mainPropertyCardSecond {
    flex-direction: column;
  }
  .mainPropertySecondFirst {
    flex-direction: column;
  }
  .actions-btn {
    flex-direction: row;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    /* margin-left: -2rem; */
    width: 100%;
  }
  .swiper-image {
    width: 100% !important;
    border-radius: 0.35rem !important;
  }
  .main-image,
  .swiper-image {
    height: auto !important;
  }
  .mainPropertyCardFirst {
    left: 10px !important;
  }
}

.mainPropertyCardFirst {
  margin-bottom: 15px;
}
.main-image {
  border-radius: 6px;
}
.swiper-image {
  width: 100%;
  height: 20rem;
  border-radius: 6px;
}
.mainPropertyCardFirst {
  position: absolute;
  top: 30px;
  left: 35px;
  z-index: 9;
}

.property-list-div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
}

p.property-price {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
  color: var(--dark-color);
}
.property-footer {
  padding: 0 15px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.property-details {
  padding: 15px 15px 15px;
  border-bottom: 1px solid var(--gray-divider);
  margin-bottom: 20px;
}
.property-description p {
  font-weight: 300;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  line-height: 155%;
}
.actions-btn {
  display: inline-flex;
  gap: 10px;
}
.actions-btn .btn {
  padding: 10px 12px;
  height: auto;
}

.actions-btn .btn i {
  padding-right: 0px;
}
