.card-header {
  border: none !important;
}
/* src/PropertyCard.css */
.property-card {
  /* margin: 20px; */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 1rem;
 
}
.property-image {
  width: 100%;
  height: 300px; /* Set a fixed height for the carousel */
  overflow: hidden;
  border-radius: 5px;
}
.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.property-details {
  /* flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.property-location {
  font-size: 14px;
  color: gray;
}

.property-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.property-description {
  font-size: 14px;
  color: #666;
}

.more-button {
  align-self: flex-start;
}

.property-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.property-actions button {
  margin-bottom: 5px;
}
/* src/PropertyList.css */
.property-location {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.badge-like {
  background-color: #f0f0f0; /* Background color of the badge */
  padding: 3px 8px;
  border-radius: 15px; /* Adjust as needed */
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.location-label {
  font-weight: bold;
  margin-right: 5px;
  color: #333; /* Darken the color for better readability */
}

.location-value {
  color: #555; /* Adjust color as needed */
}

.mainDataDiv{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 1rem;
}

.badgeDataDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 2rem;
  border: 2px solid rgb(32, 237, 87);
  padding: 1rem 1rem;
  border-radius: 2rem;

}
.iconDataDiv .faBed{
  font-size: 18px;
  display: flex;
  align-items: center;
  /* color: rgb(32, 237, 87); */
  justify-content: center;
  font-weight: 600;
}
.roomDataDiv .small{
  /* font-size: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  
}
.small{
  font-weight: 600;
  font-size: 14px;
}
/* App.css */

.pdf-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  margin: 20px;
}

.pdf-preview {
  height: 400px;
  overflow: hidden;
}

.pdf-actions {
  padding: 10px;
  text-align: center;
  background-color: #f5f5f5;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
}

.download-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .cardBody {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .property-image {
    width: 100%;
    height: auto; /* Adjust height for smaller screens */
    object-fit: cover !important;
  }

  .property-details {
    margin-left: 0;
    margin-top: 15px;
  }

  .property-actions {
    align-items: center;
    margin-top: 15px;
    width: 100%; /* Ensure full width on smaller screens */
    display: flex;
    flex-direction: row;
  }

  .property-actions button {
    width: 100%; /* Ensure buttons are full width on smaller screens */

  }
}
