@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.notificationCard {
  display: flex;
  align-items: center;
  /* justify-content: center; */

}

.notificationCardDescription {
  margin-left: 1rem;
}

.singleCard {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  background-color: #EFF4FC;
  border-radius: 10px;
  padding: 10px 10px;
}
/* .unRead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  padding: 10px 10px;
  background-color: #EFF4FC;

} */
.singleCardInside{
  width: 100%;
}
.unRead .btndetail {
  background: #fff;
  color: #000;            
}
.read{
    background: #ED7D3A;
    color: #fff;     
}
.buttonData {
  display: flex;
  align-items: center;
}

.footerCard{
    display: flex;
    align-items: center;

}