/* Style.css */
.parentComment {
    margin-left: 0; /* Parent comment is not indented */
    padding: 10px; /* Optional padding for better visual separation */
  }
  
.childComment {
    margin-left: 20px; /* Indentation for child comments */
    padding: 10px; /* Optional padding for better visual separation */
}

/* .{
  outline: none;
} */

body {
  background: #ffffff;
  color: #4e5c79;
  font-size: 15px;
  /* font-family: 'Jost', sans-serif; */
  margin: 0;
  overflow-x: hidden !important;
  font-weight: 400;
}
.modal-open {
  overflow: hidden !important;
}
.progress-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
}
.insideMainStep {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: center;
}
.step-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  align-items: center;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
  color: white;
  margin-right: 10px;
}

.step-number.completed {
  background-color: #ff9b20;
}

.step-number.current {
  background-color: #4caf50;
}

.step-title {
  font-size: 14px;
}

.line {
  width: 100%;
  height: 2px;
  background-color: grey;
  display: flex;
  align-items: center;

  justify-content: center;

  position: absolute;
  left: calc(100% + 5px);
}

.step-container:last-child .line {
  display: none;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}
a {
  color: #0c2339;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
}

.visible {
  opacity: 1;
  height: auto;
  transition: opacity 0.5s ease, height 0.5s ease;
}

a:hover,
a:focus {
  text-decoration: none;
}

a.link {
  color: #02418f;
}
a.link:hover,
a.link:focus,
a:hover,
a:focus {
  /* color: #02418f; */
}
a.text-success:focus,
a.text-success:hover {
  color: #4caf50 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #02418f !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ff9b20 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #f33066 !important;
}
a.white-link {
  color: #ffffff;
}
a.white-link:hover,
a.white-link:focus {
  color: #263238;
}
.text-light-theme {
  color: #1da0ff !important;
}
.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}
section {
  padding: 80px 0 80px;
}
section.min {
  padding: 50px 0 50px;
}
p {
  line-height: 1.8;
}

.gray-bg {
  /* background:#f7f9fc url(../img/pattern.png); */
  background-size: cover;
  background-position: center;
}
.gray {
  background: #f7f9fc !important;
}
.gray-simple {
  background: #ecf3fa !important;
}
.bg-light {
  background: #eff4fc !important;
}
.bg-title {
  background: #074da3 !important;
}
.bg-1 {
  background: #f7d0cb;
}
.bg-2 {
  background: #dde9ed;
}
.tbl {
  display: table;
}
.full-height {
  height: 100%;
}
.rtl {
  position: relative;
}
.mr-2 {
  margin-right: 8px;
}
.mr-1 {
  margin-right: 4.5px;
}
.alert p {
  margin: 0;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
.no-ul-list {
  padding: 0;
}
.no-ul-list li {
  list-style: none;
}
.progress {
  margin-bottom: 1.5em;
}
.full-width {
  width: 100%;
}
p,
ul,
ol,
dl,
dt,
dd,
blockquote,
address {
  margin: 0 0 10px;
}
.owl-carousel .item {
  padding: 0 15px;
}
.explore-content p {
  font-size: 19px;
  font-style: italic;
}
.explore-content h1,
.explore-content h2 {
  font-weight: 600;
}
.shadow-0 {
  box-shadow: none !important;
}
/*------------ GLobal Settings: Heading-------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0c2339;
  font-weight: 700;
  text-transform: capitalize;
  /* font-family: 'Jost', sans-serif; */
}

h5,
h6 {
  color: #0c2339;
}

.font-2 {
  /* font-family: 'Montserrat', sans-serif; */
}

h1 {
  line-height: 40px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 30px;
}

h3 {
  line-height: 30px;
  font-size: 24px;
}

h4 {
  line-height: 26px;
  font-size: 21px;
}

h5 {
  line-height: 22px;
  font-size: 18px;
}

h6 {
  line-height: 20px;
  font-size: 16px;
}
.lead {
  font-size: 20px !important;
}
.lead-i {
  /* font-family: 'Lora', serif; */
  font-size: 22px !important;
  font-style: italic;
}
.display-5 {
  font-size: 3rem;
}

.display-6 {
  font-size: 36px;
}

.display-7 {
  font-size: 26px;
}

.box {
  border-radius: 4px;
  padding: 10px;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td,
.no-wrap th {
  white-space: nowrap;
}

.circle {
  border-radius: 100%;
}

/*------------ Global Settings: Cutom Height ----------------*/
.ht-10 {
  height: 10px;
}
.ht-20 {
  height: 20px;
}
.ht-30 {
  height: 30px;
}
.ht-40 {
  height: 40px;
}
.ht-50 {
  height: 50px;
}
.ht-60 {
  height: 60px;
}
.ht-70 {
  height: 70px;
}
.ht-80 {
  height: 80px;
}
.ht-80 {
  height: 80px;
}
.ht-100 {
  height: 100px;
}
.ht-110 {
  height: 110px;
}
.ht-120 {
  height: 120px;
}
.ht-130 {
  height: 130px;
}
.ht-140 {
  height: 140px;
}
.ht-150 {
  height: 150px;
}
.ht-160 {
  height: 160px;
}
.ht-170 {
  height: 170px;
}
.ht-180 {
  height: 180px;
}
.ht-190 {
  height: 190px;
}
.ht-200 {
  height: 200px;
}
.ht-400 {
  height: 400px;
}
.ht-500 {
  height: 500px;
}
.ht-100 {
  height: 100%;
  min-height: 580px;
}
.h-100 {
  height: 100vh;
}
.h-05 {
  height: 0.1rem !important;
}
.h-1 {
  height: 0.25rem !important;
}
.h-2 {
  height: 0.5rem !important;
}
.h-3 {
  height: 0.75rem !important;
}
.h-4 {
  height: 1rem !important;
}
.h-5 {
  height: 1.25rem !important;
}
.h-6 {
  height: 1.5rem !important;
}
.h-7 {
  height: 1.75rem !important;
}
.h-8 {
  height: 2rem !important;
}
.h-9 {
  height: 2.25rem !important;
}
.h-10 {
  height: 2.5rem !important;
}
.h-11 {
  height: 2.75rem !important;
}
.h-12 {
  height: 3rem !important;
}
.h-13 {
  height: 3.25rem !important;
}
.h-14 {
  height: 3.75rem !important;
}
.h-15 {
  height: 4rem !important;
}
.h-16 {
  height: 4.25rem !important;
}
.h-17 {
  height: 4.5rem !important;
}
.h-18 {
  height: 4.75rem !important;
}
.h-19 {
  height: 5rem !important;
}
.h-20 {
  height: 5.25rem !important;
}
.h-25 {
  height: 6rem !important;
}
.h-30 {
  height: 7rem !important;
}
.h-35 {
  height: 8rem !important;
}
.h-40 {
  height: 9rem !important;
}
.h-45 {
  height: 10rem !important;
}
.h-50 {
  height: 15rem !important;
}

/*------------ Global Settings: Custom Width ----------------*/
.w-1 {
  width: 0.25rem !important;
}
.w-2 {
  width: 0.5rem !important;
}
.w-3 {
  width: 0.75rem !important;
}
.w-4 {
  width: 1rem !important;
}
.w-5 {
  width: 1.25rem !important;
}
.w-6 {
  width: 1.5rem !important;
}
.w-7 {
  width: 1.75rem !important;
}
.w-8 {
  width: 2rem !important;
}
.w-9 {
  width: 2.25rem !important;
}
.w-10 {
  width: 2.5rem !important;
}
.w-11 {
  width: 2.75rem !important;
}
.w-12 {
  width: 3rem !important;
}
.w-13 {
  width: 3.25rem !important;
}
.w-14 {
  width: 3.75rem !important;
}
.w-15 {
  width: 4rem !important;
}
.w-16 {
  width: 4.25rem !important;
}
.w-17 {
  width: 4.5rem !important;
}
.w-18 {
  width: 4.75rem !important;
}
.w-19 {
  width: 5rem !important;
}
.w-20 {
  width: 5.25rem !important;
}
.w-25 {
  width: 6rem !important;
}
.w-30 {
  width: 7rem !important;
}
.w-35 {
  width: 8rem !important;
}
.w-40 {
  width: 9rem !important;
}
.w-45 {
  width: 10rem !important;
}
.w-50s {
  width: 15rem !important;
}
.start-10 {
  left: 10% !important;
}
.start-20 {
  left: 20% !important;
}
.start-30 {
  left: 30% !important;
}
.start-40 {
  left: 40% !important;
}
.start-50 {
  left: 50% !important;
}
.start-60 {
  left: 60% !important;
}
.start-70 {
  left: 70% !important;
}
.start-80 {
  left: 80% !important;
}
.start-90 {
  left: 90% !important;
}
.end-10 {
  right: 10% !important;
}
.end-20 {
  right: 20% !important;
}
.end-30 {
  right: 30% !important;
}
.end-40 {
  right: 40% !important;
}
.end-50 {
  right: 50% !important;
}
.end-60 {
  right: 60% !important;
}
.end-70 {
  right: 70% !important;
}
.end-80 {
  right: 80% !important;
}
.end-90 {
  right: 90% !important;
}
/*------------ Global Settings: Dot BG ----------------*/
.bg-dot {
  background-size: 0.75rem 0.75rem;
}
.bg-dot.primary {
  background-image: radial-gradient(#074da3 2px, transparent 2.5px);
}
.bg-dot.blue {
  background-image: radial-gradient(#074da3 2px, transparent 2.5px);
}
.bg-dot.success {
  background-image: radial-gradient(#1ca774 2px, transparent 2.5px);
}
.bg-dot.yellow {
  background-image: radial-gradient(#fab758 2px, transparent 2.5px);
}
.bg-dot.danger {
  background-image: radial-gradient(#a70a29 2px, transparent 2.5px);
}
.bg-dot.info {
  background-image: radial-gradient(#008aff 2px, transparent 2.5px);
}
.bg-dot.purple {
  background-image: radial-gradient(#6363f5 2px, transparent 2.5px);
}
.bg-dot.light {
  background-image: radial-gradient(#f1f5f8 2px, transparent 2.5px);
}

.bg-line.blue {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #074da3 1.6px,
    #074da3 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}
.bg-line.primary {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #074da3 1.6px,
    #074da3 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}
.bg-line.yellow {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #fab758 1.6px,
    #fab758 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}
.bg-line.success {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #1ca774 1.6px,
    #1ca774 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}
.bg-line.danger {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #a70a29 1.6px,
    #a70a29 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}
.bg-line.purple {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #6363f5 1.6px,
    #6363f5 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}
.bg-line.light {
  background: repeating-linear-gradient(
    -55deg,
    rgba(255, 255, 255, 0) 0.8px,
    #f1f5f8 1.6px,
    #f1f5f8 3px,
    rgba(255, 255, 255, 0) 3.8px,
    rgba(255, 255, 255, 0) 10px
  );
}

/*------------ Global Settings: Typography ----------------*/
.type--fade {
  opacity: 0.5;
}
.fs-xs {
  font-size: 10px;
}
.fs-sm {
  font-size: 13.5px;
}
blockquote {
  font-size: 1.78571429em;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  border-left: 0;
}
blockquote {
  font-style: italic;
}
blockquote:not(:last-child) {
  margin-bottom: 1.04em;
}
.card {
  border: none;
  overflow: hidden;
  background: #ffffff;
}
.card-header {
  background: #ffffff;
  padding: 0.8rem 1rem;
  border-color: #e2e8ec;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-header h4,
.card-header h5,
.card-header h6 {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}

/*------------ Global Settings: Animation ----------------*/
.animate-bounce {
  animation: bounce 2s infinite;
}
.animate-leftright {
  animation: leftright 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes leftright {
  0%,
  100% {
    transform: translatex(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translatex(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/*------------ Global Settings: Cutom Avatar ----------------*/
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: -0.01rem;
  border-radius: 100%;
  height: auto;
}

/*------------ Global Settings: Social Links Colors ----------------*/
.color--facebook {
  color: #3b5998 !important;
}
.color--twitter {
  color: #00aced !important;
}
.color--googleplus {
  color: #dd4b39 !important;
}
.color--instagram {
  color: #125688 !important;
}
.color--pinterest {
  color: #cb2027 !important;
}
.color--dribbble {
  color: #ea4c89 !important;
}
.color--behance {
  color: #053eff !important;
}
.color--linkedin {
  color: #1685b9 !important;
}
.bg--facebook {
  background: #3b5998 !important;
  color: #fff !important;
}
.bg--twitter {
  background: #00aced !important;
  color: #fff !important;
}
.bg--googleplus {
  background: #dd4b39 !important;
  color: #fff !important;
}
.bg--instagram {
  background: #125688 !important;
  color: #fff !important;
}
.bg--pinterest {
  background: #cb2027 !important;
  color: #fff !important;
}
.bg--dribbble {
  background: #ea4c89 !important;
  color: #fff !important;
}
.bg--behance {
  background: #053eff !important;
  color: #fff !important;
}
.bg--linkedin {
  background: #1685b9 !important;
  color: #fff !important;
}
.btn--facebook {
  background: #ffffff !important;
  border: 1px solid #3b5998 !important;
  color: #3b5998 !important;
}
.btn--facebook:focus,
.btn--facebook:hover,
.btn--facebook:active {
  background: #3b5998 !important;
  border: 1px solid #3b5998 !important;
  color: #ffffff !important;
}
.btn--twitter {
  background: #ffffff !important;
  border: 1px solid #00aced !important;
  color: #00aced !important;
}
.btn--twitter:hover,
.btn--twitter:focus,
.btn--twitter:active {
  background: #00aced !important;
  border: 1px solid #00aced !important;
  color: #ffffff !important;
}
.btn--googleplus {
  background: #ffffff !important;
  border: 1px solid #dd4b39 !important;
  color: #dd4b39 !important;
}
.btn--googleplus:hover,
.btn--googleplus:focus,
.btn--googleplus:active {
  background: #dd4b39 !important;
  border: 1px solid #dd4b39 !important;
  color: #ffffff !important;
}
.btn--instagram {
  background: #ffffff !important;
  border: 1px solid #125688 !important;
  color: #125688 !important;
}
.btn--instagram:hover,
.btn--instagram:focus,
.btn--instagram:active {
  background: #125688 !important;
  border: 1px solid #125688 !important;
  color: #ffffff !important;
}
.btn--pinterest {
  background: #ffffff !important;
  border: 1px solid #cb2027 !important;
  color: #cb2027 !important;
}
.btn--pinterest:hover,
.btn--pinterest:focus,
.btn--pinterest:active {
  background: #cb2027 !important;
  border: 1px solid #cb2027 !important;
  color: #ffffff !important;
}
.btn--dribbble {
  background: #ffffff !important;
  border: 1px solid #ea4c89 !important;
  color: #ea4c89 !important;
}
.btn--dribbble:hover,
.btn--dribbble:focus,
.btn--dribbble:active {
  background: #ea4c89 !important;
  border: 1px solid #ea4c89 !important;
  color: #ffffff !important;
}
.btn--behance {
  background: #ffffff !important;
  border: 1px solid #053eff !important;
  color: #053eff !important;
}
.btn--behance:hover,
.btn--behance:focus,
.btn--behance:active {
  background: #053eff !important;
  border: 1px solid #053eff !important;
  color: #ffffff !important;
}
.btn--linkedin {
  background: #ffffff !important;
  border: 1px solid #1685b9 !important;
  color: #1685b9 !important;
}
.btn--linkedin:hover,
.btn--linkedin:focus,
.btn--linkedin:active {
  background: #1685b9 !important;
  border: 1px solid #1685b9 !important;
  color: #ffffff !important;
}
.height-100,
.height-90,
.height-80,
.height-70,
.height-60,
.height-50,
.height-40,
.height-30,
.height-20,
.height-10 {
  height: auto;
  padding: 5em 0;
}

/*------------ Global Settings: Background & Image ----------------*/
.bg-img-holder {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity 0.3s linear;
  -webkit-transition: opacity 0.3s linear;
  opacity: 0;
  background: #252525;
}
.bg-img-holder:not([class*="col-"]) {
  width: 100%;
}
.bg-img-holder.background--bottom {
  background-position: 50% 100% !important;
}
.bg-img-holder.background--top {
  background-position: 50% 0% !important;
}
.image--light .bg-img-holder {
  background: none;
}
.bg-img-holder img {
  display: none;
}
.imagebg.border--round {
  overflow: hidden;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: "";
  background: #252525;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #4a90e2;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}
[data-scrim-bottom] {
  position: relative;
}
[data-scrim-bottom]:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, #252525)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #252525 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #252525 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  backface-visibility: hidden;
}
[data-scrim-bottom]:not(.image--light) h1,
[data-scrim-bottom]:not(.image--light) h2,
[data-scrim-bottom]:not(.image--light) h3,
[data-scrim-bottom]:not(.image--light) h4,
[data-scrim-bottom]:not(.image--light) h5,
[data-scrim-bottom]:not(.image--light) h6 {
  color: #a5a5a5;
  color: #f1f1f1;
}
[data-scrim-bottom]:not(.image--light) p,
[data-scrim-bottom]:not(.image--light) span,
[data-scrim-bottom]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-bottom].image--light:before {
  background: #fff;
}
[data-scrim-bottom="1"]:before {
  opacity: 0.1;
}
[data-scrim-bottom="2"]:before {
  opacity: 0.2;
}
[data-scrim-bottom="3"]:before {
  opacity: 0.3;
}
[data-scrim-bottom="4"]:before {
  opacity: 0.4;
}
[data-scrim-bottom="5"]:before {
  opacity: 0.5;
}
[data-scrim-bottom="6"]:before {
  opacity: 0.6;
}
[data-scrim-bottom="7"]:before {
  opacity: 0.7;
}
[data-scrim-bottom="8"]:before {
  opacity: 0.8;
}
[data-scrim-bottom="9"]:before {
  opacity: 0.9;
}
[data-scrim-bottom="10"]:before {
  opacity: 1;
}
[data-scrim-top] {
  position: relative;
}
[data-scrim-top]:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left bottom,
    left bottom,
    color-stop(0%, #252525),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    bottom,
    #252525 0%,
    rgba(0, 0, 0, 0) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #252525 0%, rgba(0, 0, 0, 0) 100%);
  top: 0;
  left: 0;
  z-index: 1;
}
[data-scrim-top]:not(.image--light) h1,
[data-scrim-top]:not(.image--light) h2,
[data-scrim-top]:not(.image--light) h3,
[data-scrim-top]:not(.image--light) h4,
[data-scrim-top]:not(.image--light) h5,
[data-scrim-top]:not(.image--light) h6 {
  color: #fff;
}
[data-scrim-top]:not(.image--light) p,
[data-scrim-top]:not(.image--light) span,
[data-scrim-top]:not(.image--light) ul {
  color: #e6e6e6;
}
[data-scrim-top].image--light:before {
  background: #fff;
}
[data-scrim-top="1"]:before {
  opacity: 0.1;
}
[data-scrim-top="2"]:before {
  opacity: 0.2;
}
[data-scrim-top="3"]:before {
  opacity: 0.3;
}
[data-scrim-top="4"]:before {
  opacity: 0.4;
}
[data-scrim-top="5"]:before {
  opacity: 0.5;
}
[data-scrim-top="6"]:before {
  opacity: 0.6;
}
[data-scrim-top="7"]:before {
  opacity: 0.7;
}
[data-scrim-top="8"]:before {
  opacity: 0.8;
}
[data-scrim-top="9"]:before {
  opacity: 0.9;
}
[data-scrim-top="10"]:before {
  opacity: 1;
}
.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p {
  color: #fff;
}
.parallax {
  overflow: hidden;
}
/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}
.imagebg:not(.image--light) span {
  color: #fff;
}
/*------------ Global Settings: Spinner ----------------*/

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: text-bottom;
  border: 3px solid;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

/* .spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
} */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: text-bottom;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 30px;
  height: 30px;
}

/*------------ Global Settings: BG Cover -------------*/
.object-fit {
  object-fit: cover;
}
.bg-cover {
  background-size: cover !important;
  background-position: center !important;
}
.image-bg {
  background-size: cover !important;
  background-position: center !important;
}
.default-bg {
  background-size: initial !important;
  background-position: initial !important;
}
.image-bg-wrap {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}
.image-bg-wrap:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #182538;
  display: block;
  content: "";
  opacity: 0.8;
}

/*-------------- Global Settings: Thumb size ----------------*/
.thumb-sm {
  height: 32px;
  width: 32px;
}

.thumb-md {
  height: 48px;
  width: 48px;
}

.thumb-lg {
  height: 88px;
  width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--20 {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--25 {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--30 {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--40 {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--50 {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--60 {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--70 {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--80 {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--90 {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.square--100 {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hide {
  display: none;
}

/*-------------- Global Settings: Text Colors ----------------*/
.text-primary {
  color: #074da3 !important;
}
.text-white {
  color: #ffffff !important;
}
.text-danger {
  color: #a70a29 !important;
}
.text-muted {
  color: #a0abb8 !important;
}
.text-muted-2 {
  color: #8a98a9 !important;
}
.text-warning {
  color: #f69520 !important;
}
.text-success {
  color: #009868 !important;
}
.text-info {
  color: #008aff !important;
}
.text-royal {
  color: #073aa8 !important;
}
.text-orange {
  color: #e95f33 !important;
}
.text-black {
  color: #041b2d !important;
}
.text-purple {
  color: #6363f5 !important;
}
.text-seegreen {
  color: #0a5f59 !important;
}
.text-sm-muted {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 44, 63, 0.6);
}
.text-sm {
  font-size: 12px;
}
/*-------------- Global Settings: Alerts & Notification --------------*/
.alert-primary {
  color: #074da3;
  background-color: rgba(7, 77, 163, 0.12);
  border-color: rgba(7, 77, 163, 0.2);
}
.alert-success {
  color: #009868;
  background-color: rgba(0, 152, 104, 0.12);
  border-color: rgba(0, 152, 104, 0.2);
}
.alert-warning {
  color: #f69520;
  background-color: rgba(246, 149, 32, 0.12);
  border-color: rgba(246, 149, 32, 0.2);
}
.alert-info {
  color: #008aff;
  background-color: rgba(0, 138, 255, 0.12);
  border-color: rgba(0, 138, 255, 0.2);
}
.alert-danger {
  color: #a70a29;
  background-color: rgba(167, 10, 41, 0.12);
  border-color: rgba(167, 10, 41, 0.2);
}
.alert-dark {
  color: #041b2d;
  background-color: rgba(4, 27, 45, 0.12);
  border-color: rgba(4, 27, 45, 0.2);
}
.alert-purple {
  color: #6363f5;
  background-color: rgba(99, 99, 245, 0.12);
  border-color: rgba(99, 99, 245, 0.2);
}
.alert-orange {
  color: #e95f33;
  background-color: rgba(233, 95, 51, 0.12);
  border-color: rgba(233, 95, 51, 0.2);
}
.alert-seegreen {
  color: #0a5f59;
  background-color: rgba(10, 95, 89, 0.12);
  border-color: rgba(10, 95, 89, 0.2);
}
.alert-royal {
  color: #073aa8;
  background-color: rgba(7, 58, 168, 0.12);
  border-color: rgba(7, 58, 168, 0.2);
}
.alert-secondary {
  color: #4b5d6f;
  background-color: #d6dfe8;
  border-color: #d6dfe8;
}
/*-------------- Global Settings: Background Colors ----------------*/
.bg-primary {
  background-color: #074da3 !important;
}
.bg-success {
  background-color: #009868 !important;
}
.bg-info {
  background-color: #008aff !important;
}
.bg-warning {
  background-color: #f69520 !important;
}
.bg-danger {
  background-color: #a70a29 !important;
}
.bg-purple {
  background-color: #6363f5 !important;
}
.bg-orange {
  background-color: #e95f33 !important;
}
.bg-seegreen {
  background-color: #0a5f59 !important;
}
.bg-inverse {
  background-color: #3e4555 !important;
}
.bg-royal {
  background-color: #073aa8 !important;
}
.bg-dark {
  background-color: #041b2d !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.bg-light-primary {
  background-color: rgba(7, 77, 163, 0.1) !important;
}
.bg-light-success {
  background-color: rgba(0, 152, 104, 0.1) !important;
}
.bg-light-info {
  background-color: rgba(0, 138, 255, 0.1) !important;
}
.bg-light-warning {
  background-color: rgba(246, 149, 32, 0.1) !important;
}
.bg-light-danger {
  background-color: rgba(167, 10, 41, 0.1) !important;
}
.bg-light-purple {
  background-color: rgba(99, 99, 245, 0.1) !important;
}
.bg-light-orange {
  background-color: rgba(233, 95, 51, 0.1) !important;
}
.bg-light-seegreen {
  background-color: rgba(10, 95, 89, 0.1) !important;
}
.bg-light-royal {
  background-color: rgba(7, 58, 168, 0.1) !important;
}
.bg-light-dark {
  background-color: rgba(4, 27, 45, 0.1) !important;
}
.bg-transparent {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg--graysmoke {
  background-image: linear-gradient(
    180deg,
    rgba(244, 244, 249, 0.8) 50%,
    rgba(244, 244, 249, 0.05) 100%
  );
}
.shape--bg:after {
  width: 48%;
  height: 72%;
  top: 21%;
  left: 52%;
}
.shape--bg:before {
  width: 48%;
  height: 72%;
  top: 21%;
  right: 52%;
}
/*----------- Global Settings: Simple List -----------*/
ul.simple-list,
ul.colored-list,
ul.simple {
  padding: 0;
  margin: 0;
}
ul.simple-list li,
ul.colored-list li {
  list-style: none;
  position: relative;
  color: rgba(0, 44, 63, 0.6);
  font-weight: 500;
  padding: 0.2rem 0rem 0.2rem 1.4rem;
}
ul.simple-list li:before {
  content: "\f192";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  left: 0;
}
ul.colored-list li:before {
  content: "\f192";
  font-family: "Font Awesome 6 Free";
  color: #074da3;
  position: absolute;
  left: 0;
}
/*-------------- Global Settings: Labels ----------------*/
.label {
  padding: 4px 15px;
  color: #ffffff;
  font-weight: 500;
  border-radius: 4px;
  font-size: 75%;
}
.label-primary {
  background-color: #074da3;
}
.label-success {
  background-color: #009868;
}
.label-info {
  background-color: #008aff;
}
.label-warning {
  background-color: #f69520;
}
.label-danger {
  background-color: #a70a29;
}
.label-royal {
  background-color: #073aa8;
}
.label-purple {
  background-color: #6363f5;
}
.label-orange {
  background-color: #e95f33;
}
.label-seegreen {
  background-color: #0a5f59;
}
.label-dark {
  background-color: #041b2d;
}
.label-inverse {
  background-color: #3e4555;
}
.label-white {
  background-color: #ffffff;
}
.label-light-primary {
  background-color: rgba(7, 77, 163, 0.12);
  color: #074da3;
}
.label-light-success {
  background-color: rgba(0, 152, 104, 0.12);
  color: #009868;
}
.label-light-info {
  background-color: rgba(0, 138, 255, 0.12);
  color: #008aff;
}
.label-light-warning {
  background-color: rgba(246, 149, 32, 0.12);
  color: #f69520;
}
.label-light-danger {
  background-color: rgba(167, 10, 41, 0.12);
  color: #a70a29;
}
.label-light-royal {
  background-color: rgba(7, 58, 168, 0.12);
  color: #073aa8;
}
.label-light-purple {
  background-color: rgba(99, 99, 245, 0.12);
  color: #6363f5;
}
.label-light-orange {
  background-color: rgba(233, 95, 51, 0.12);
  color: #e95f33;
}
.label-light-seegreen {
  background-color: rgba(10, 95, 89, 0.12);
  color: #0a5f59;
}
.label-light-dark {
  background-color: rgba(4, 27, 45, 0.12);
  color: #041b2d;
}
.label-light-inverse {
  background-color: rgba(62, 69, 85, 0.1);
  color: #3e4555;
}

/*------------ Global Settings: Table ------------*/
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid transparent;
  border-top: 0px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f7f9fb;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 1px solid #eaeff5;
  border-bottom: none;
}
.table tr th,
.table tr td {
  border-color: #eaeff5;
  padding: 12px 15px;
  vertical-align: middle;
}
.table.tbl-big tr th,
.table.tbl-big tr td {
  padding: 20px 15px;
}
.table.tbl-big.center tr th,
.table.tbl-big.center tr td {
  padding: 20px 15px;
  text-align: center;
}
table.table tr th {
  font-weight: 600;
}
.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #32383e !important;
}
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
/*------------- Global Settings: Badge ----------------*/
.badge {
  font-weight: 400;
}

.badge-xs {
  font-size: 9px;
}

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.badge-success {
  background-color: #009868;
}
.badge-info {
  background-color: #008aff;
}
.badge-primary {
  background-color: #074da3;
}
.badge-warning {
  background-color: #f69520;
}
.badge-danger {
  background-color: #a70a29;
}
.badge-royal {
  background-color: #073aa8;
}
.badge-purple {
  background-color: #6363f5;
}
.badge-orange {
  background-color: #e95f33;
}
.badge-seegreen {
  background-color: #0a5f59;
}
.badge-dark {
  background-color: #041b2d;
}
.badge-inverse {
  background-color: #3e4555;
}

/*------------- Global Settings: dropdown ----------------*/
.dropdown-item {
  padding: 8px 1rem;
  color: #607394;
  font-size: 15.5px;
}

/*------------- Global Settings: Buttons ----------------*/
.btn {
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: all ease 0.4s;
  -o-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-radius: 0.4rem;
}

.btn:focus,
.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-lg {
  padding: 1em 1.5em;
  height: 62px;
  font-size: 1em;
}
.btn-md {
  padding: 1em 1.5em;
  height: 45px;
  font-size: 0.8rem;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  height: 35px;
  font-size: 12px;
}

.btn-xs {
  padding: 0.2rem 0.5rem;
  height: 30px;
  font-size: 10px;
}

.btn-rounded {
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
}

.btn-arrow {
  position: relative;
}

.btn-danger {
  background: #a70a29;
  border-color: #a70a29;
  color: #ffffff;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #db1c40 !important;
  border-color: #db1c40 !important;
  color: #ffffff !important;
}
.btn-outline-danger {
  background: transparent;
  border-color: #a70a29;
  color: #a70a29;
}
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active {
  background: #a70a29 !important;
  border-color: #a70a29 !important;
  color: #ffffff !important;
}
.btn-light-danger {
  background: rgba(167, 10, 41, 0.12);
  border-color: rgba(167, 10, 41, 0.2);
  color: rgba(167, 10, 41, 1);
}
.btn-light-danger:hover,
.btn-light-danger:focus,
.btn-light-danger:active {
  background: #a70a29 !important;
  border-color: #a70a29 !important;
  color: #ffffff !important;
}
.btn-success {
  background: #009868;
  border-color: #009868;
  color: #ffffff;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #049b6b !important;
  border-color: #049b6b !important;
  color: #ffffff !important;
}
.btn-outline-success {
  background: #ffffff;
  border-color: #009868;
  color: #009868;
}
.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active {
  background: #009868 !important;
  border-color: #009868 !important;
  color: #ffffff !important;
}
.btn-light-success {
  background: rgba(0, 152, 104, 0.12);
  border-color: rgba(0, 152, 104, 0.2);
  color: #009868;
}
.btn-light-success:hover,
.btn-light-success:focus,
.btn-light-success:active {
  background: #009868 !important;
  border-color: #009868 !important;
  color: #ffffff !important;
}
.btn-warning {
  background: #f69520;
  border-color: #f69520;
  color: #ffffff;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #ed8d1b !important;
  border-color: #ed8d1b !important;
  color: #ffffff !important;
}
.btn-outline-warning {
  background: #ffffff;
  border-color: #f69520;
  color: #f69520;
}
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active {
  background: #f69520 !important;
  border-color: #f69520 !important;
  color: #ffffff !important;
}
.btn-light-warning {
  background: rgba(246, 149, 32, 0.12);
  border-color: rgba(246, 149, 32, 0.2);
  color: #f69520;
}
.btn-light-warning:hover,
.btn-light-warning:focus,
.btn-light-warning:active {
  background: #f69520 !important;
  border-color: #f69520 !important;
  color: #ffffff !important;
}
.btn-primary {
  background: #074da3;
  border-color: #074da3;
  color: #ffffff;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #188ef1 !important;
  border-color: #188ef1 !important;
  color: #ffffff !important;
}
.btn-outline-primary {
  background: #ffffff;
  border-color: #074da3;
  color: #074da3;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  background: #074da3 !important;
  border-color: #074da3 !important;
  color: #ffffff !important;
}
.btn-light-primary {
  background: rgba(7, 77, 163, 0.12);
  border-color: rgba(7, 77, 163, 0.2);
  color: #074da3;
}
.btn-light-primary:hover,
.btn-light-primary:focus,
.btn-light-primary:active {
  background: #074da3 !important;
  border-color: #074da3 !important;
  color: #ffffff !important;
}
.btn-info {
  background: #008aff;
  border-color: #008aff;
  color: #ffffff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #0484f0 !important;
  border-color: #0484f0 !important;
  color: #ffffff !important;
}
.btn-info-outline {
  background: #ffffff;
  border-color: #008aff;
  color: #008aff;
}
.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active {
  background: #008aff !important;
  border-color: #008aff !important;
  color: #ffffff !important;
}
.btn-light-info {
  background: rgba(0, 138, 255, 0.12);
  border-color: rgba(0, 138, 255, 0.2);
  color: #008aff;
}
.btn-light-info:hover,
.btn-light-info:focus,
.btn-light-info:active {
  background: #008aff !important;
  border-color: #008aff !important;
  color: #ffffff !important;
}
.btn-royal {
  background: #073aa8;
  border-color: #073aa8;
  color: #ffffff;
}
.btn-royal:hover,
.btn-royal:focus,
.btn-royal:active {
  background: #07379e !important;
  border-color: #07379e !important;
  color: #ffffff !important;
}
.btn-outline-royal {
  background: #ffffff;
  border-color: #073aa8;
  color: #073aa8;
}
.btn-outline-royal:hover,
.btn-outline-royal:focus,
.btn-outline-royal:active {
  background: #073aa8 !important;
  border-color: #073aa8 !important;
  color: #ffffff !important;
}
.btn-light-royal {
  background: rgba(1, 126, 250, 0.1);
  border-color: rgba(1, 126, 250, 0.65);
  color: #073aa8;
}
.btn-light-royal:hover,
.btn-light-royal:focus,
.btn-light-royal:active {
  background: #073aa8 !important;
  border-color: #073aa8 !important;
  color: #ffffff !important;
}
.btn-purple {
  background: #6363f5;
  border-color: #6363f5;
  color: #ffffff;
}
.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active {
  background: #5a5aed !important;
  border-color: #5a5aed !important;
  color: #ffffff !important;
}
.btn-outline-purple {
  background: #ffffff;
  border-color: #6363f5;
  color: #6363f5;
}
.btn-outline-purple:hover,
.btn-outline-purple:focus,
.btn-outline-purple:active {
  background: #6363f5 !important;
  border-color: #6363f5 !important;
  color: #ffffff !important;
}
.btn-light-purple {
  background: rgba(99, 99, 245, 0.12);
  border-color: rgba(99, 99, 245, 0.2);
  color: #6363f5;
}
.btn-light-purple:hover,
.btn-light-purple:focus,
.btn-light-purple:active {
  background: #6363f5 !important;
  border-color: #6363f5 !important;
  color: #ffffff !important;
}
.btn-orange {
  background: #e95f33;
  border-color: #e95f33;
  color: #ffffff;
}
.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active {
  background: #e86035 !important;
  border-color: #e86035 !important;
  color: #ffffff !important;
}
.btn-outline-orange {
  background: #ffffff;
  border-color: #e95f33;
  color: #e95f33;
}
.btn-outline-orange:hover,
.btn-outline-orange:focus,
.btn-outline-orange:active {
  background: #e95f33 !important;
  border-color: #e95f33 !important;
  color: #ffffff !important;
}
.btn-light-orange {
  background: rgba(233, 95, 51, 0.12);
  border-color: rgba(233, 95, 51, 0.2);
  color: #e95f33;
}
.btn-light-orange:hover,
.btn-light-orange:focus,
.btn-light-orange:active {
  background: #e95f33 !important;
  border-color: #e95f33 !important;
  color: #ffffff !important;
}
.btn-seegreen {
  background: #0a5f59;
  border-color: #0a5f59;
  color: #ffffff;
}
.btn-seegreen:hover,
.btn-seegreen:focus,
.btn-seegreen:active {
  background: #075a54 !important;
  border-color: #075a54 !important;
  color: #ffffff !important;
}
.btn-outline-seegreen {
  background: #ffffff;
  border-color: #0a5f59;
  color: #0a5f59;
}
.btn-outline-seegreen:hover,
.btn-outline-seegreen:focus,
.btn-outline-seegreen:active {
  background: #0a5f59 !important;
  border-color: #0a5f59 !important;
  color: #ffffff !important;
}
.btn-light-seegreen {
  background: rgba(10, 95, 89, 0.12);
  border-color: rgba(10, 95, 89, 0.2);
  color: #0a5f59;
}
.btn-light-seegreen:hover,
.btn-light-seegreen:focus,
.btn-light-seegreen:active {
  background: #0a5f59 !important;
  border-color: #0a5f59 !important;
  color: #ffffff !important;
}
.btn-dark {
  background: #041b2d;
  border-color: #041b2d;
  color: #ffffff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  background: #02121f !important;
  border-color: #02121f !important;
  color: #ffffff !important;
}
.btn-outline-dark {
  background: #ffffff;
  border-color: #041b2d;
  color: #041b2d;
}
.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active {
  background: #041b2d !important;
  border-color: #041b2d !important;
  color: #ffffff !important;
}
.btn-light-dark {
  background: rgba(4, 27, 45, 0.12);
  border-color: rgba(4, 27, 45, 0.2);
  color: #041b2d;
}
.btn-light-dark:hover,
.btn-light-dark:focus,
.btn-light-dark:active {
  background: #041b2d !important;
  border-color: #041b2d !important;
  color: #ffffff !important;
}
.btn-whites {
  background: #ffffff;
  border-color: #ffffff;
  color: #073aa8;
}
.btn-whites:hover,
.btn-whites:focus,
.btn-whites:active {
  background: #ffffff !important;
  border-color: #ffffff !important;
  color: #046acf !important;
}
.btn-outline-whites {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-outline-whites:hover,
.btn-outline-whites:focus,
.btn-outline-whites:active {
  background: #ffffff !important;
  border-color: #ffffff !important;
  color: #046acf !important;
}
.btn-light-white {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.65);
  color: #ffffff;
}
.btn-light-whites:hover,
.btn-light-whites:focus,
.btn-light-whites:active {
  background: #ffffff !important;
  border-color: #ffffff !important;
  color: #046acf !important;
}
.btn-gray {
  background: #f2f8fd;
  border-color: #f2f8fd;
  color: #24486c;
}
.btn-gray:hover,
.btn-gray:focus,
.btn-gray:active {
  background: #ffffff !important;
  border-color: #ffffff !important;
  color: #046acf !important;
}
.btn-outline-gray {
  background: transparent;
  border-color: #f2f8fd;
  color: #24486c;
}
.btn-outline-gray:hover,
.btn-outline-gray:focus,
.btn-outline-gray:active {
  background: #f2f8fd !important;
  border-color: #f2f8fd !important;
  color: #17ac6a !important;
}

/*----------- Global Settings: Video ------------------*/
.cover .video-block:not(:first-child):not(:last-child) {
  margin: 2.77em 0;
}
.video-play-icon {
  width: 6.42857143em;
  height: 6.42857143em;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  display: inline-block;
  border: 2px solid #ffffff;
  cursor: pointer;
  line-height: 6em;
  background: #ffffff;
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
}
.video-play-icon a {
  font-size: 3em;
  color: #17ac6a;
}
/*----------- Global Settings: Pagination ------------------*/
.pagination {
  display: table;
  padding-left: 0;
  border-radius: 4px;
  margin: 20px auto 0 !important;
}

.pagination > li > a,
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 0;
  margin: 5px;
  color: #074da3;
  text-decoration: none;
  background-color: transparent;
  border-radius: 2px;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 1;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover,
.pagination > li > a:focus,
.pagination > li > a:hover {
  z-index: 2;
  color: #ffffff;
  cursor: pointer;
  background-color: #074da3;
  border-color: none;
}

.pagination li:first-child a {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: #0c3966;
}

.pagination li:last-child a {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: #0c3966;
}
.pagination > li {
  display: inline;
}
.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #074da3;
  border-color: none;
}

/*---------- Global Settings: Section Heading Style ---------*/
.sec-heading {
  margin-bottom: 3rem;
}
.sec-heading.lg {
  margin-bottom: 30px;
}
.sec-heading.center {
  text-align: center;
}
.sec-heading.light h2,
.sec-heading.light p {
  color: #ffffff;
}
.sec-heading p {
  margin-bottom: 5px;
  line-height: 1.7;
}
.sec-heading2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
/*----------- Global Settings: Custom Checkbox & Radio Button ----------------*/

/*--------------- GLobal Settings: On off switch ---------------*/
.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0 auto;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "";
  padding-right: 27px;
  background-color: #27b737;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "";
  padding-right: 24px;
  background-color: #3e4555;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #ffffff;
  height: 23px;
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 35px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
/*----------- GLobal Settings: Custom Radio Button ------------*/

/*------------- GLobal Settings: Bootstrap 4 hack --------------*/
.card-columns {
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.card-columns .card {
  margin-bottom: 30px;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
  .nav-left-side ul.attributes {
    float: none;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

/*---------- GLobal Settings: Section Heading Style ---------*/
.sec-heading {
  margin-bottom: 3rem;
}
.sec-heading.lg {
  margin-bottom: 30px;
}
.sec-heading.center {
  text-align: center;
}
.sec-heading.light h2,
.sec-heading.light p {
  color: #ffffff;
}
.sec-heading p {
  margin-bottom: 5px;
  line-height: 1.7;
}
.single-items {
  padding: 0 10px;
}
.single-slide {
  padding: 0 7px;
}
.property-slide,
.item-slide {
  margin: 0 -15px;
}
.sec-heading2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.sec-left {
  flex: 1;
}
.sec-left h3 {
  font-size: 20px;
  margin-bottom: 0px;
}
.sec-right a {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
}
/*------------ GLobal Settings: All Forms Style ---------------*/
.form-control {
  /* height: 56px; */
  font-size: 16px;
  box-shadow: none;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e0ecf5;
  background-clip: initial;
}
.submit-form .form-control,
.submit-page .form-control,
.form-submit .form-control {
  background: #f8fbfd;
  border: 1px solid #eff3f6;
  border-radius: 3px;
  color: #646e77;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.form-control.simple {
  background: #f2f3f5;
  border-color: #f2f3f5;
  border-radius: 4px;
}
.select2-container {
  width: 100% !important;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border-radius: 0;
  border: none;
  height: 57px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #626a70;
  line-height: 57px;
  text-align: left;
  padding: 0 15px 0 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  position: absolute;
  top: 1px;
  right: 10px;
  width: 20px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #6877a5;
}
.full-search-2 .form-control::-webkit-input-placeholder {
  color: #6877a5;
}
.full-search-2 .form-control:-ms-input-placeholder {
  color: #6877a5;
}
.full-search-2 .form-control::placeholder {
  color: #6877a5;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  right: 14px;
  top: 1px;
}
.image-bg .form-control,
.bg--dark .form-control {
  color: #495057 !important;
}
/* select.form-control:not([size]):not([multiple]) {
  height: 56px;
} */
select.form-control.fc-lg:not([size]):not([multiple]) {
  height: 62px;
}
.form-control.fc-lg {
  height: 62px;
}
textarea.form-control {
  height: 180px;
}
.simple-form .form-floating > .form-control-plaintext ~ label::after,
.simple-form .form-floating > .form-control:focus ~ label::after,
.simple-form
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label::after,
.simple-form .form-floating > .form-select ~ label::after,
.login-form .form-floating > .form-control-plaintext ~ label::after,
.login-form .form-floating > .form-control:focus ~ label::after,
.login-form
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label::after,
.login-form .form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: transparent !important;
  border-radius: var(--bs-border-radius);
}
/*------------- Form --------------*/
.tip-topdata {
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 15px;
  height: 15px;
  display: inline-block;
  background: #394d8c;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  margin-left: 5px;
  font-size: 9px;
}
.tip-topdata:before {
  content: attr(data-tip);
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  z-index: 999;
  white-space: nowrap;
  bottom: 9999px;
  left: 0;
  background: #394d8c;
  color: #ffffff;
  padding: 7px 15px;
  line-height: 25px;
  border-radius: 3px;
  top: -42px;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-out;
  -moz-transition: opacity 0.4s ease-out;
  -o-transition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
  text-shadow: none;
  display: inline-table;
}

.tip-topdata:hover:before {
  opacity: 1;
}
.form-submit .select2-container--default .select2-selection--single {
  background: #f8fbfd;
  border: 1px solid #eff3f6;
  border-radius: 0;
  border: none;
  height: 56px;
  border: 1px solid #ebedf5;
  border-radius: 4px;
  padding-left: 15px;
}
.form-submit
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 55px;
}
/**************************************
	Header Navigation Styles
***************************************/
.nav-brand,
.nav-brand:focus,
.nav-brand:hover,
.nav-menu > li > a {
  color: #0c2339;
}
.nav-brand.text-logo {
  display: inline-flex;
  align-items: center;
}
.header.header-transparent .nav-brand.text-logo.exchange,
.header.header-transparent .nav-brand.text-logo.exchange h5 {
  color: #ffffff !important;
}
.header.header-transparent.header-fixed .nav-brand.text-logo.exchange,
.header .navigation.navigation-portrait .nav-brand.text-logo.exchange {
  color: #074da3 !important;
}
.header.header-transparent.header-fixed .nav-brand.text-logo.exchange h5,
.header .navigation.navigation-portrait .nav-brand.text-logo.exchange h5 {
  color: #252c41 !important;
}
.menu__list,
.nav-dropdown,
.nav-menu,
ul {
  list-style: none;
}

.menu__link,
.navigation,
.navigation * {
  -webkit-tap-highlight-color: transparent;
}

.navigation,
.navigation * {
  box-sizing: border-box;
}

.navigation {
  width: 100%;
  display: table;
  position: relative;
}

.nav-toggle,
.navigation-hidden .nav-header {
  display: none;
}

.navigation-portrait {
  height: 48px;
}

.navigation-fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.navigation-hidden {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.align-to-right {
  float: right;
}

.nav-header {
  float: left;
}

.nav-brand {
  padding: 0px 15px 0px 0px;
  font-size: 24px;
  margin-right: 1rem;
  text-decoration: none !important;
}

.deatils ul li a,
.indicate a,
.logo h1 a,
.nav-button,
.nav-dropdown > li > a,
.nav-menu > li > a,
nav a {
  text-decoration: none;
}

.navigation-portrait .nav-brand {
  font-size: 18px;
  line-height: 48px;
}

.nav-logo > img {
  height: 48px;
  margin: 11px auto;
  padding: 0 15px;
  float: left;
}

.nav-logo:focus > img {
  outline: initial;
}

.deatils ul li a,
.indicate a,
.menu__link:focus,
.menu__link:hover,
nav a,
nav a:focus,
nav a:hover {
  outline: 0;
}

.navigation-portrait .nav-logo > img {
  height: 36px;
  margin: 6px auto 6px 15px;
  padding: 0;
}

.nav-toggle {
  width: 30px;
  height: 30px;
  padding: 6px 2px 0;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  right: 15px;
  cursor: pointer;
}

.nav-toggle:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #70798b;
  border-radius: 10px;
  box-shadow: 0 0.5em 0 0 #70798b, 0 1em 0 0 #70798b;
}

.navigation-portrait .nav-toggle {
  display: block;
}

.navigation-portrait .nav-menus-wrapper {
  width: 320px;
  height: 100%;
  top: 0;
  left: -400px;
  position: fixed;
  background-color: #fff;
  z-index: 20000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.8s;
  transition-timing-function: ease;
}

.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right {
  left: auto;
  right: -400px;
}

.nav-menus-wrapper.nav-menus-wrapper-open {
  left: 0;
}

.nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open {
  right: 0;
}

.nav-menus-wrapper-close-button {
  width: 30px;
  height: 40px;
  margin: 10px 7px;
  display: none;
  float: right;
  color: #70798b;
  font-size: 26px;
  cursor: pointer;
}

.navigation-portrait .nav-menus-wrapper-close-button {
  display: block;
}

.nav-menu {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.navigation-portrait .nav-menu {
  width: 100%;
}

.navigation-landscape .nav-menu.nav-menu-centered {
  float: none;
  text-align: center;
}

.nav-menu > li {
  display: inline-block;
  float: left;
  text-align: left;
}

.navigation-portrait .nav-menu > li {
  width: 100%;
  position: relative;
  border-top: solid 1px #f0f0f0;
}

.navigation-portrait .nav-menu > li:last-child {
  border-bottom: solid 1px #f0f0f0;
}

.nav-menu + .nav-menu > li:first-child {
  border-top: none;
}

.navigation-landscape .nav-menu.nav-menu-centered > li {
  float: none;
}

.nav-menu > li > a {
  padding: 13px 12px;
  display: inline-block;
  transition: color 0.3s, background 0.3s;
  font-weight: 500;
  font-size: 14px;
}

.navigation-portrait .nav-menu > li > a {
  height: auto;
  width: 100%;
  padding: 12px 15px 12px 26px;
  color: #0a4078 !important;
}
.header-transparent .navigation-portrait .nav-menu > .active > a,
.header-transparent .navigation-portrait .nav-menu > .focus > a,
.header-transparent .navigation-portrait .nav-menu > li:hover > a {
  color: #0a4078 !important;
}
.nav-menu > .active > a,
.nav-menu > .focus > a,
.nav-menu > li:hover > a {
  color: #074da3 !important;
}
.header-transparent .nav-menu > .active > a,
.header-transparent .nav-menu > .focus > a,
.header-transparent .nav-menu > li:hover > a {
  color: #ffffff !important;
}
.header-transparent.dark .nav-menu > .active > a,
.header-transparent.dark .nav-menu > .focus > a,
.header-transparent.dark .nav-menu > li:hover > a {
  color: #0c2339 !important;
}
.header-transparent.dark.header-fixed .nav-menu > .active > a,
.header-transparent.dark.header-fixed .nav-menu > .focus > a,
.header-transparent.dark.header-fixed .nav-menu > li:hover > a {
  color: #074da3 !important;
}
.header-transparent.header-fixed .nav-menu > .active > a,
.header-transparent.header-fixed .nav-menu > .focus > a,
.header-transparent.header-fixed .nav-menu > li:hover > a {
  color: #074da3 !important;
}
.nav-menu > li > a > [class*="ion-"],
.nav-menu > li > a > i {
  width: 18px;
  height: 16px;
  line-height: 16px;
  -ms-transform: scale(1.4);
  transform: scale(1.4);
}

.nav-menu > li > a > [class*="ion-"] {
  width: 16px;
  display: inline-block;
  transform: scale(1.8);
}

.navigation-portrait .nav-menu.nav-menu-social {
  width: 100%;
  text-align: center;
}

.nav-menu.nav-menu-social > li {
  text-align: center;
  float: none;
  border: none !important;
}

.navigation-portrait .nav-menu.nav-menu-social > li {
  width: auto;
}

.nav-menu.nav-menu-social > li > a > [class*="ion-"] {
  font-size: 12px;
}

.nav-menu.nav-menu-social > li > a > .fa {
  font-size: 14px;
}

.navigation-portrait .nav-menu.nav-menu-social > li > a {
  padding: 15px;
}

.submenu-indicator {
  margin-left: 6px;
  margin-top: 6px;
  float: right;
  transition: all 0.3s;
}

.navigation-portrait .submenu-indicator {
  width: 54px;
  height: 44px;
  margin-top: 0;
  position: absolute;
  text-align: center;
  z-index: 20000;
}

.submenu-indicator-chevron {
  height: 6px;
  width: 6px;
  display: block;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent #0c2339 #0c2339 transparent;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: border 0.3s;
}

.navigation-portrait .submenu-indicator-chevron {
  position: absolute;
  top: 18px;
  left: 24px;
}

.nav-menu > .active > a .submenu-indicator-chevron,
.nav-menu > .focus > a .submenu-indicator-chevron,
.nav-menu > li:hover > a .submenu-indicator-chevron {
  border-color: transparent #074da3 #074da3 transparent;
}

.navigation-portrait .submenu-indicator.submenu-indicator-up {
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.nav-overlay-panel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  z-index: 19999;
}

.no-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nav-search {
  height: 70px;
  float: right;
  z-index: 19998;
}

.navigation-portrait .nav-search {
  height: 48px;
  padding: 0 10px;
  margin-right: 52px;
}

.navigation-hidden .nav-search {
  display: none;
}

.nav-search-button {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  background-color: #fbfcfd;
}

.nav-search-icon {
  width: 14px;
  height: 14px;
  margin: 2px 8px 8px 4px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #70798b;
  text-align: left;
  text-indent: -9999px;
  border: 2px solid;
  border-radius: 50%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: color 0.3s;
}

.nav-search-icon:after,
.nav-search-icon:before {
  content: "";
  pointer-events: none;
}

.nav-search-icon:before {
  width: 2px;
  height: 11px;
  top: 11px;
  position: absolute;
  left: 50%;
  border-radius: 0 0 1px 1px;
  box-shadow: inset 0 0 0 32px;
  transform: translateX(-50%);
}

.nav-search-button:hover .nav-search-icon {
  color: #00a94f;
}

.navigation-portrait .nav-search-button {
  width: 50px;
  height: 48px;
  line-height: 46px;
  font-size: 22px;
}

.nav-search > form {
  width: 100%;
  height: 100%;
  padding: 0 auto;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
}

.nav-search-inner {
  width: 70%;
  height: 70px;
  margin: auto;
  display: table;
}

.navigation-portrait .nav-search-inner {
  height: 48px;
}

.nav-search-inner input[type="search"],
.nav-search-inner input[type="text"] {
  height: 70px;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  font-size: 26px;
  text-align: center;
  color: #70798b;
  outline: 0;
  line-height: 70px;
  border: none;
  background-color: transparent;
  transition: all 0.3s;
}

.navigation-portrait .nav-search-inner input[type="search"],
.navigation-portrait .nav-search-inner input[type="text"] {
  height: 48px;
  font-size: 18px;
  line-height: 48px;
}

.nav-search-close-button {
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: normal;
  color: #70798b;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
}

.nav-button,
.nav-text {
  display: inline-block;
  font-size: 14px;
}

.navigation-portrait .nav-search-close-button {
  top: 10px;
  right: 14px;
}

.nav-button {
  margin: 18px 15px 0;
  padding: 8px 14px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  background-color: #00a94f;
  transition: opacity 0.3s;
}

.nav-button:focus,
.nav-button:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}

.navigation-portrait .nav-button {
  width: calc(100% - 52px);
  margin: 17px 26px;
}

.nav-text {
  margin: 25px 15px;
  color: #70798b;
}

.navigation-portrait .nav-text {
  width: calc(100% - 52px);
  margin: 12px 26px 0;
}

.navigation-portrait .nav-text + ul {
  margin-top: 15px;
}

.nav-dropdown {
  min-width: 250px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 99;
  white-space: nowrap;
}

.navigation-portrait .nav-dropdown {
  width: 100%;
  position: static;
  left: 0;
}

.nav-dropdown .nav-dropdown {
  left: 100%;
}

.nav-menu > li .nav-dropdown {
  border: none;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  -webkit-box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
  border-radius: 0.4rem;
}
.nav-menu > li > .nav-dropdown:before {
  background-color: #ffffff;
  content: "";
  height: 20px;
  left: 25px;
  position: absolute;
  top: -10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 20px;
  -webkit-transition: all 0.1s ease 0s;
  -moz-transition: all 0.1s ease 0s;
  -o-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
}
.nav-dropdown > li {
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  text-align: left;
}

.nav-dropdown > li > a {
  width: 100%;
  padding: 12px 20px 14px 10px;
  border-bottom: 1px solid #e4e8ec;
  display: inline-block;
  float: left;
  color: #445869;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  /* font-family: 'Jost', sans-serif; */
  font-size: 14px;
}
#navigation span.label-new {
  font-size: 11px;
  font-weight: 500;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgb(76 175 80 / 12%);
  padding: 2px 12px;
  border-radius: 0.2rem;
  color: #4caf50;
}
.nav-dropdown > li:last-child > a {
  border-bottom: none;
}
.nav-dropdown > li > a:hover,
.nav-dropdown > li > a:focus {
  padding-left: 20px;
  color: #074da3;
}
.social-icon a i,
.social-icons a i {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 16px;
}

.nav-dropdown > .focus > a,
.nav-dropdown > li:hover > a {
  color: #074da3;
}

.nav-dropdown.nav-dropdown-left {
  right: 0;
}

.nav-dropdown > li > .nav-dropdown-left {
  left: auto;
  right: 100%;
}

.navigation-landscape .nav-dropdown-left > li > a {
  text-align: right;
}

.navigation-portrait .nav-dropdown > li > a {
  padding: 12px 20px 12px 30px;
  color: #0a4078;
}

.navigation-portrait .nav-dropdown > li > ul > li > a {
  padding-left: 50px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > a {
  padding-left: 70px;
}

.navigation-portrait .nav-dropdown > li > ul > li > ul > li > ul > li > a {
  padding-left: 90px;
}

.navigation-portrait
  .nav-dropdown
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > ul
  > li
  > a {
  padding-left: 110px;
}

.nav-dropdown .submenu-indicator {
  right: 15px;
  top: 16px;
  position: absolute;
}

.menu__list,
.navbar,
nav a {
  position: relative;
}

.navigation-portrait .submenu-indicator {
  right: 0;
  top: 0;
}

.nav-dropdown .submenu-indicator .submenu-indicator-chevron {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navigation-portrait
  .nav-dropdown
  .submenu-indicator
  .submenu-indicator-chevron {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-dropdown > .focus > a .submenu-indicator-chevron,
.nav-dropdown > li:hover > a .submenu-indicator-chevron {
  border-color: transparent #074da3 #074da3 transparent;
}

.navigation-landscape .nav-dropdown-left .submenu-indicator {
  left: 10px;
}

.navigation-landscape
  .nav-dropdown-left
  .submenu-indicator
  .submenu-indicator-chevron {
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

nav a {
  display: inline-block;
}

.header {
  background: #fff;
  position: relative;
  padding: 1rem 0;
}

.header-top {
  padding: 1em 0;
  background: #05222a;
}

.indicate {
  float: left;
}

.deatils {
  float: right;
}

.indicate a {
  font-size: 1.1em;
  color: #c6c7c7;
  vertical-align: middle;
}

.deatils ul li i,
.indicate i {
  font-size: 1.2em;
  color: #888f90;
  vertical-align: middle;
}

.indicate i {
  margin-right: 0.5em;
}

.deatils ul li {
  display: inline-block;
  margin: 0 5px;
}

.deatils ul li i {
  margin-right: 0.5em;
}

.deatils ul li a {
  font-size: 1.1em;
  color: #c6c7c7;
  vertical-align: middle;
}

.social-icons {
  float: right;
  margin-top: 0.7em;
}

.logo h1 a {
  color: #fff;
  font-size: 1.3em;
}

.logo span {
  display: block;
  font-size: 0.32em;
  letter-spacing: 4px;
}

.header-bottom {
  padding: 1em 0;
}

.navbar-nav {
  float: left;
  margin: 0;
}

.navbar-default {
  background: #fff;
  border: none !important;
}

.navbar-default .navbar-nav > li > a {
  color: #05222a;
  font-size: 1.3em;
  font-weight: 900;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #02b875;
  background-color: #fff;
}

.navbar {
  min-height: 50px;
  margin-bottom: 0;
  border: 1px solid transparent;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #05222a;
  background-color: #fff;
}

.menu__list {
  -webkit-flex-wrap: inherit;
  flex-wrap: inherit;
}

.menu__item {
  display: block;
  margin: 1em 0;
}

.menu__link {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
}

.menu--francisco .menu__item {
  margin: 0 1.5em;
}

.menu--francisco .menu__link {
  position: relative;
  overflow: hidden;
  height: 3em;
  padding: 1em 0;
  text-align: center;
  color: #b5b5b5;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.menu--francisco .menu__link:focus,
.menu--francisco .menu__link:hover {
  color: #929292;
}

.menu--francisco .menu__item--current .menu__link {
  color: #02b875;
}

.menu--francisco .menu__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  opacity: 0;
  background: #02b875;
  -webkit-transform: translate3d(0, -3em, 0);
  transform: translate3d(0, -3em, 0);
  -webkit-transition: -webkit-transform 0s 0.3s, opacity 0.2s;
  transition: transform 0s 0.3s, opacity 0.2s;
}

.menu--francisco .menu__item--current .menu__link::before,
.menu--francisco .menu__link:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.1s;
  transition: transform 0.5s, opacity 0.1s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.menu--francisco .menu__helper {
  display: block;
  pointer-events: none;
}

.menu--francisco .menu__item--current .menu__helper,
.menu__link:hover .menu__helper {
  -webkit-animation: anim-francisco 0.3s forwards;
  animation: anim-francisco 0.3s forwards;
}

@-webkit-keyframes anim-francisco {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes anim-francisco {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.nav-menu.nav-menu-social > li.add-listing a {
  display: inline-flex;
  color: #ffffff !important;
  height: 52px;
  padding: 10px 20px;
  border-radius: 0.3rem;
  align-items: center;
  justify-content: center;
}
.nav-menu.nav-menu-social > li.add-listing:hover a,
.nav-menu.nav-menu-social > li.add-listing:focus a {
  color: #ffffff !important;
}
.nav-menu.nav-menu-social > li.add-listing.light a {
  background: #ffffff !important;
  color: #041b2d !important;
}
.header-fixed .nav-menu.nav-menu-social > li.add-listing.light a {
  background-color: rgba(7, 77, 163, 1) !important;
  color: #ffffff !important;
}
.nav-brand img {
  max-width: 135px;
  position: relative;
}
.nav-menu.nav-menu-social .text-invers {
  color: #ffffff;
}
.header-fixed .nav-menu.nav-menu-social .text-invers {
  color: #074da3;
}
.header .navigation.navigation-portrait .nav-menu-social {
  display: none;
}
.navigation-portrait .nav-header {
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  justify-content: space-between;
}
.navigation-portrait .mobile_nav {
  margin-right: 4rem;
}
.navigation-portrait .mobile_nav ul {
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
}
.navigation-portrait .mobile_nav ul li {
  list-style: none;
  margin-left: 1rem;
  display: inline-flex;
}
.navigation-portrait .account-drop .btn.btn-order-by-filt {
  box-shadow: none;
  padding: 0px 0px;
  font-weight: 600;
  height: auto;
}
/*--------- Dashboard Dropdown ----------*/
.btn-group.account-drop {
  position: relative;
}
.account-drop .dropdown-menu a {
  padding: 0.6rem 0;
  font-size: 14px;
}
.account-drop .dropdown-menu {
  top: 60px !important;
  right: 0 !important;
  background: #fff;
  box-shadow: none;
  border: solid 1px #ebedf3;
  min-width: 200px;
  left: initial !important;
  padding: 0.4rem 1rem;
}
.account-drop .avater-img {
  max-width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.account-drop .btn.btn-order-by-filt {
  box-shadow: none;
  padding: 6px 0px;
  font-weight: 600;
}
.top-header {
  background: #002b4d;
  padding: 12px 0;
  color: #fff;
}
.cn-info ul {
  padding: 0;
  margin: 0;
}
.cn-info ul li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
}
.cn-info ul li:last-child {
  margin-right: 0;
}
.cn-info ul li i {
  margin-right: 5px;
}
.top-social {
  padding: 0;
  margin: 0;
  float: right;
}
ul:last-child {
  margin: 0;
}
.top-social li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
}
.top-social li:last-child {
  margin-right: 0;
}
.top-social li a {
  color: #fff;
}
/*===================================================
 Homes Banner
==================================================*/
.light-bg {
  background: #def2ff;
}
.image-bg {
  background-size: cover !important;
  background-position: center !important;
}
.image-cover {
  background-size: cover !important;
  background-position: center !important;
}
.image-bottom {
  background-size: auto !important;
  background-position: bottom !important;
}
.hero-banner {
  padding: 5em 0 5em;
  display: flex;
  min-height: 600px;
  justify-content: center;
  align-items: center;
}
.hero-search-wrap {
  position: relative;
  background: #ffffff;
  max-width: 540px;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 40px 40px;
  box-shadow: 0 20px 30px rgba(0, 22, 84, 0.15);
}
.hero-search-wrap.hs-2 {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 1rem 1rem 0rem;
}
.hero-search-wrap.full-width {
  width: 100%;
  max-width: 100%;
}
.hero-search h1 {
  text-transform: capitalize;
  font-size: 35px;
}
.hero-banner.dark-text h1,
.hero-banner.dark-text p,
.hero-banner.dark-text h1,
.hero-banner.dark-text p {
  color: #4d5968;
}
/*--------------- Video Banner --------------*/
.vedio-banner {
  position: relative;
  background-color: black;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 640px;
  width: 100%;
  overflow: hidden;
}
.vedio-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #032137;
  opacity: 0.6;
}
.vedio-banner video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

/*-------------- Homes Banner: Hero Header -----------------*/
.hero-header {
  background: #232323;
  background-position: center;
  background-size: cover;
  padding: 4em 0;
  position: relative;
}
.large-banner {
  padding: 5em 0 3em;
  display: flex;
  min-height: 660px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.verticle-center {
  text-align: center;
}
.hero-header {
  margin: 0;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: column;
  -webkit-flex-direction: column;
}
.hero-banner > * {
  position: relative;
  z-index: 1;
}

.hero-banner h1 {
  font-weight: 800;
  line-height: 1.3;
}
.hero-banner p {
  font-size: 18px;
}
.hero-banner p.small {
  font-size: 15px;
}
.hero-banner p.sm-small {
  font-size: 15px;
}
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: "";
  background: #041e2f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
.searches-lists {
  position: relative;
  display: block;
  margin-top: 2rem;
}
.searches-lists ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.searches-lists ul li {
  list-style: none;
  margin: 3px 5px;
}
.searches-lists ul li:first-child {
  margin-left: 0;
}
.searches-lists ul li span {
  font-size: 13px;
  font-weight: 600;
  color: #132a3c;
}
.searches-lists ul li a {
  font-size: 12px;
  font-weight: 600;
  background: #ffffff;
  padding: 4px 10px;
  border-radius: 2px;
  transition: all ease 0.4s;
}
.searches-lists ul li a:hover,
.searches-lists ul li a:focus,
.searches-lists ul li a:active,
.searches-lists ul li a.active {
  background: #074da3;
  color: #ffffff;
}
/*------------ Homes Banner: Banner Form ------------*/
.signup-frm {
  max-width: 510px;
  margin-top: 15px;
  display: block;
  position: relative;
}
.signup-frm .form-control {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  background-color: #ffffff;
  border: 1px solid #cfd8dc;
  padding: 22px 20px 18px;
  border-radius: 2px;
  height: 60px;
}
.signup-frm .btn {
  bottom: 4px;
  position: absolute;
  right: 4px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 18px 25px 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: normal;
  width: auto;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  height: 52px;
}

/*---- Advance Slide Search Box ---------------*/
.full-search-2 {
  background: #ffffff;
  padding: 25px 25px 10px;
  border-radius: 6px;
}
.full-search-2 .hero-search h1 {
  margin-bottom: 1rem;
  text-align: center;
}
.btn.reset-btn-outline {
  border: 2px solid #bec2cc;
  padding: 14px 25px 14px 25px;
  transition: all ease 0.4s;
  width: 100%;
  color: #000000;
}
.no-ul-list.third-row {
  display: table;
  width: 100%;
  margin-bottom: 15px;
}
.no-ul-list.third-row li {
  width: 50%;
  float: left;
  margin-bottom: 7px;
}
.btn.reset-btn-outline:hover,
.btn.reset-btn-outline:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.btn.search-btn-outline {
  border: 2px solid #074da3;
  padding: 14px 25px 14px 25px;
  transition: all ease 0.4s;
  width: 100%;
  color: #074da3;
}
.btn.search-btn-outline:hover,
.btn.search-btn-outline:focus {
  background: #074da3;
  color: #ffffff;
}
#module > a,
#module > a:hover,
#module > a:focus {
  width: 100%;
  color: #0c2339;
  padding: 14px 10px 14px 45px;
  position: relative;
  line-height: 1.25;
  display: inline-block;
}
#module a.collapsed:after {
  content: "Advanced Search";
  font-size: 18px;
}

#module a:not(.collapsed):after {
  content: "Advanced Search";
  font-size: 18px;
}
#module a.collapsed:before,
#module a:not(.collapsed):before {
  content: "\2b";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  font-size: 14px;
  width: 30px;
  height: 30px;
  background: #000000;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: #ffffff;
  left: 0;
  position: absolute;
  top: 9px;
}
#module a:not(.collapsed):before {
  content: "\f068";
  font-weight: 900;
  font-family: "Font Awesome 6 Free";
  font-size: 14px;
  width: 30px;
  height: 30px;
  background: #000000;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: #ffffff;
  left: 0;
  position: absolute;
  top: 9px;
}
/*----------- Home Slider Banner -------------*/
.home-slider .item,
.property-slider .item {
  transition: all 0.6s;
  border-radius: 0;
  position: relative;
  height: 600px;
}
.home-slider .item {
  min-height: 520px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 100vh;
}
.home-slider .item {
  background-size: cover !important;
  background-position: center !important;
}
.fullwidth-home-slider div {
  height: 100%;
}
.home-slider-container {
  position: relative;
  height: 100%;
  max-width: 450px;
  display: block;
  padding: 2rem;
  background: #ffffff;
  border-radius: 0.1rem;
  -webkit-box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.3);
}
.home-slider .item:before {
  content: "";
  position: absolute;
  background: linear-gradient(to bottom, transparent 10%, #03141c);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.home-slider-price {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}
.home-slider-container .listing-price-with-compare {
  position: relative;
  margin: 1rem 0;
  bottom: auto;
  padding: 0;
}
.home-slider-container .listing-price-with-compare .lpc-right a {
  background: #f4f7fb;
  color: #687592;
}
.home-slider-container .modern-pro-wrap {
  position: relative;
  top: 0;
  margin-bottom: 15px;
  left: auto;
}
.home-slider-container .modern-pro-wrap span {
  position: relative;
  padding: 8px 20px;
  z-index: 1;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: none;
  text-transform: capitalize;
}
.home-slider-container .modern-pro-wrap .property-type {
  background: #323d50;
}
.home-slider-price i {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.8;
}
.home-slider-title {
  display: inline-block;
  margin-bottom: 10px;
  width: 100%;
}
.home-slider-title a {
  font-size: 1.2rem;
}
.home-slider-title h3 {
  margin-bottom: 5px;
}
.home-slider-title span i {
  margin-right: 5px;
}
.simple-search-wrap {
  max-width: 580px;
  margin: 0;
}
.home-slider-desc .read-more {
  display: inline-block;
  padding: 10px 20px;
  color: #ffffff !important;
  border-radius: 4px;
}
.slide-property-info {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}
.slide-property-info ul {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.slide-property-info ul li {
  display: inline-block;
  flex: 0 0 33.333333%;
  width: 33.3333%;
  list-style: none;
}
.simple-search-wrap h1 {
  color: #ffffff;
}

.large-banner {
  padding: 5em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.full-search-2 {
  background: #ffffff;
  padding: 18px 25px 10px;
  border-radius: 6px;
}
.full-search-2.italian-search .form-group {
  margin-bottom: 0px;
  height: 100%;
}
.full-search-2.transparent {
  background: transparent;
  padding: 15px 25px 15px;
}
.full-search-2 .hero-search h1 {
  margin-bottom: 1rem;
  text-align: center;
}
.btn.reset-btn-outline {
  border: 2px solid #bec2cc;
  padding: 14px 25px 14px 25px;
  transition: all ease 0.4s;
  width: 100%;
  color: #000000;
}
.btn.reset-btn {
  border: 2px solid #bec2cc;
  background: #000000;
  height: 60px;
  transition: all ease 0.4s;
  border-radius: 4px;
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.no-ul-list.first-row,
.no-ul-list {
  display: table;
  width: 100%;
  margin-bottom: 15px;
}
.no-ul-list.third-row {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.no-ul-list.second-row li {
  max-width: 50%;
  float: left;
  margin-bottom: 7px;
  flex: 0 0 50%;
}
.no-ul-list.third-row li {
  max-width: 50%;
  float: left;
  margin-bottom: 7px;
  flex: 0 0 50%;
}
.btn.reset-btn-outline:hover,
.btn.reset-btn-outline:focus {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.btn.search-btn-outline {
  border: 2px solid #074da3;
  padding: 14px 25px 14px 25px;
  transition: all ease 0.4s;
  width: 100%;
  color: #074da3;
}
.btn.search-btn-outline:hover,
.btn.search-btn-outline:focus {
  background: #074da3;
  color: #ffffff !important;
}
h1.italian-header-capt {
  font-weight: 500;
  margin: 0 auto;
  /* font-family: 'Lora', serif; */
  font-style: italic;
  font-size: 50px;
  margin-bottom: 1em;
  text-align: center;
}
h1.big-header-capt {
  font-weight: 700;
  margin: 0 auto;
  margin-bottom: 0.4em;
  text-align: center;
  text-transform: capitalize;
}
.italian-search .input-with-icon .form-control,
.italian-search .input-with-shadow .form-control,
.italian-search .input-with-shadow .select2-container,
.italian-search .input-with-icon .select2-container {
  border: none;
  border-radius: 3px;
  padding-left: 45px;
  height: 55px;
  background: #ffffff;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #bec2cc;
}
.small-padd {
  padding: 0 5px !important;
}
.full-search-2.hero-search-radius {
  background: #ffffff;
  padding: 0.6rem;
  border-radius: 0.3rem;
}
.full-search-2.hero-search-radius.shadow {
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.2);
}
.full-search-2.hero-search-radius.shadow-hard {
  box-shadow: 0px 10px 12px rgb(56 147 206 / 10%);
  -webkit-box-shadow: 0px 10px 12px rgb(56 147 206 / 10%);
}
.italian-search.hero-search-radius .input-with-icon .form-control,
.italian-search.hero-search-radius .input-with-shadow .form-control,
.italian-search.hero-search-radius .input-with-shadow .select2-container,
.italian-search.hero-search-radius .input-with-icon .select2-container {
  border: none;
  border-radius: 3px;
  padding-left: 45px;
  height: 55px;
  background: #ffffff;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}
.italian-search.hero-search-radius .btn.search-btn {
  border-radius: 0.2rem;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.italian-search.hero-search-radius .btn.search-btn.black {
  background: #1a2340 !important;
}

.full-search-2.hero-search-radius {
  position: relative;
}
.full-search-2.hero-search-radius #advance-search {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.ad-search,
.ad-search:hover,
.ad-search:focus {
  height: 55px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  color: #9db0c3 !important;
  border-radius: 0.2rem;
  font-size: 22px;
  align-items: center;
  justify-content: center;
}

.italian-search.hero-search-radius
  #advance-search
  .input-with-icon
  .form-control,
.italian-search.hero-search-radius
  #advance-search
  .input-with-shadow
  .form-control,
.italian-search.hero-search-radius
  #advance-search
  .input-with-shadow
  .select2-container,
.italian-search.hero-search-radius
  #advance-search
  .input-with-icon
  .select2-container {
  border: 1px solid #dde0e6;
}
.choose-propert-type {
  width: 100%;
  position: relative;
  height: 100%;
}
.choose-propert-type ul {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.choose-propert-type ul li {
  font-weight: 600;
  color: #0c2339;
}
.choose-propert-type [type="radio"]:checked + label:before,
.choose-propert-type [type="radio"]:not(:checked) + label:before {
  border: 2px solid #c1cbe0;
  border-radius: 50%;
  background: transparent !important;
}
.choose-propert-type [type="radio"]:checked + label:after,
.choose-propert-type [type="radio"]:not(:checked) + label:after {
  width: 10px;
  height: 10px;
  background: #00ba74 !important;
  top: 4px;
  left: 4px;
}
/*-------------Home Map ----------------*/
.map-search-box {
  background: #ffffff;
  padding: 1em;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
button.btn.adv-btn {
  background: #2b4db9;
  color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.hm-map-container {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fw-map {
  height: 600px;
}
.hm-map-container.column-map {
  width: 50%;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.hm-map-container.column-map.right-pos-map {
  right: 0;
}
.hm-map-container.column-map.left-pos-map {
  left: 0;
}
.hm-map-container #map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  overflow: hidden;
}
.map-popup-wrap {
  max-width: 300px;
  position: relative;
}
.infoBox > img {
  position: absolute;
  right: 15px;
  top: 38px;
  z-index: 2;
  cursor: pointer;
}
.cluster-visible {
  background: transparent !important;
  color: #ffffff !important;
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: radial-gradient(
    circle,
    rgba(37, 64, 162, 1) 35%,
    rgba(12, 32, 107, 1) 100%
  ) !important;
  border-radius: 50%;
  font-size: 16px !important;
}
.gm-style img {
  max-width: 100%;
}
.map-popup span.property-type {
  font-weight: 500;
  padding: 4px 12px;
  margin: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1rem;
  background: rgb(76 175 80 / 10%);
  color: #4caf50;
  border-radius: 0.3rem;
}
.map-popup .price-features-wrapper,
.map-popup .listing-detail-wrapper {
  margin: 0 0.5rem;
}
.map-popup h4.listing-name {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
}
.map-popup h6.listing-card-info-price.price-prefix .price-suffix {
  color: #87949f;
  font-size: 15px;
  font-weight: 500;
}
/*------- Fullscreen Map Section ---------------- */

.fs-container {
  height: 100% !important;
  width: 100%;
  position: relative;
  z-index: 4;
  float: left;
  background: #fff;
}
.fs-left-map-box {
  float: left;
}
.fs-container #map {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 990;
  margin-bottom: 0px;
}
.fs-left-map-box .hm-map-container.fw-map {
  width: 40%;
  height: 100%;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.home-map-banner.full-wrapious {
  position: relative;
  top: 0;
  left: 0;
  height: 585px;
  width: 100%;
  overflow: hidden;
}
.half-map .fs-inner-container {
  position: relative;
  width: 60%;
  background: #ffffff;
  min-height: 100vh;
  z-index: 10;
  float: right;
}
.hm-map-container.fw-map #map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  overflow: hidden;
}
.fs-content {
  max-height: 100%;
  overflow: auto;
  padding: 0;
  overflow: visible;
}
.half-map .fs-content {
  padding: 2em;
}
.fs-container section.search {
  padding: 32px 40px 47px 40px;
}

.fs-container .fs-listings {
  padding: 0 25px;
  margin: 0;
}

.fs-switcher {
  padding: 0 25px;
  margin: 0;
}

/* Inner Containers */
.fs-inner-container {
  width: 50%;
  height: 100%;
  float: left;
  display: inline-block;
}
.img-wrap {
  height: 310px;
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  margin: 10px 0;
  border-radius: 4px;
}
.img-wrap:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  background: linear-gradient(to bottom, transparent 25%, #1e2a4c);
  opacity: 0.8;
  z-index: 11;
  transition: 0.3s ease-out;
}

.home-map-banner.full-wrapious {
  position: relative;
  top: 0;
  left: 0;
  height: 585px;
  width: 100%;
  overflow: hidden;
}
.home-map-banner.full-wrapious .advance-search-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: +33;
  transition: all 0.4s;
  max-width: 1170px;
  margin: 0 auto !important;
  width: 100%;
  right: 0;
}
.location-listing {
  border: 1px solid #eaebf1;
  width: 100%;
  border-radius: 0.4rem;
  margin-top: 25px;
}
.location-listing-thumb {
  width: 100%;
  padding: 10px 10px 0;
  display: inline-block;
}
.location-listing-thumb a {
  display: inline-block;
}
.location-listing-thumb img {
  border-radius: 0.4rem;
}
.location-listing-caption {
  text-align: center;
  padding: 1rem 0;
}
.location-listing-caption h4 {
  margin: 0;
  font-size: 16px;
}
.location-listing-caption span {
  font-size: 13px;
  font-weight: 600;
}
/*------------------ New Search ---------------*/
.hero-header .search-form,
.hero-header .search-big-form {
  margin-top: 2rem;
}
.search-form .form-control {
  height: 60px !important;
  border-radius: 0px;
  padding-left: 40px;
  box-shadow: none;
}
.search-form .btn {
  height: 60px !important;
  border-radius: 0px;
  box-shadow: none;
}
.search-form .select2-container--default .select2-selection--single {
  height: 60px;
  border: none;
}
.search-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 58px;
}
.search-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 60px;
}
.search-big-form .form-control {
  height: 70px !important;
  border-radius: 0px;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 45px;
  box-shadow: none;
}
.search-big-form .btn {
  height: 70px !important;
  border-radius: 0px;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  box-shadow: none;
}
.search-big-form .select2-container--default .select2-selection--single {
  height: 70px;
  border: none;
}
.search-big-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 68px;
}
.search-big-form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 70px;
}
.search-form .form-group i {
  position: absolute;
  color: #bbc6d4;
  left: 15px;
  top: 24px;
}
.search-big-form .form-group i {
  position: absolute;
  color: #bbc6d4;
  left: 13px;
  top: 25px;
  font-size: 22px;
}
.search-big-form.no-border .form-control:hover,
.search-big-form.no-border .btn:hover,
.search-big-form.no-border .form-control:focus,
.search-big-form.no-border .btn:focus,
.search-form.no-border .form-control:hover,
.search-form.no-border .btn:hover,
.search-form.no-border .form-control:focus,
.search-form.no-border .btn:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.search-big-form.no-border .form-control,
.search-big-form.no-border .btn,
.search-form.no-border .form-control,
.search-form.no-border .btn {
  border: none;
}
form.search-big-form.search-shadow,
form.search-form.search-shadow {
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}
.br-all .select2-container--default .select2-selection--single {
  border: 1px solid #e0ecf5 !important;
}
.select2-dropdown {
  border: 1px solid #e0ecf5;
  z-index: +33333;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e0ecf5;
}

.search-header-banner {
  background: #f6faff;
  padding: 2em 0;
}

/*----------- Input With Icons ------------*/
.input-with-icon {
  position: relative;
  width: 100%;
}
.input-with-icon .form-control,
.input-with-shadow .form-control,
.input-with-shadow .select2-container,
.input-with-icon .select2-container {
  border: none;
  border-radius: 5px;
  padding-left: 45px;
  height: 60px;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 0 6px 1px rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 6px 1px rgba(62, 28, 131, 0.1);
}
.input-with-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  font-size: 18px;
  color: #a2b2bf;
  font-style: normal;
  pointer-events: none;
}
.input-with-icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  cursor: normal;
}
.hero-search-content.side-form .form-control,
.hero-search-content.side-form
  .select2-container--default
  .select2-selection--single {
  background: #edf1fb !important;
  box-shadow: none !important;
}
.hero-search-content.side-form
  .select2-container--default
  .select2-selection--single {
  background: #edf1fb !important;
  box-shadow: none !important;
  border-radius: 5px;
  padding-left: 15px !important;
}

.hero-search-content.side-form label,
label {
  font-size: 14px;
  font-weight: 500;
  color: #053864;
  /* font-family: 'Jost', sans-serif; */
}
.hero-search-content.side-form .form-control::-webkit-input-placeholder {
  color: #6877a5;
}
.hero-search-content.side-form .form-control:-ms-input-placeholder {
  color: #6877a5;
}
.hero-search-content.side-form .form-control::placeholder {
  color: #6877a5;
}

/*----------------- Search By Location --------------*/
.location-property-wrap {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  transition: all ease 0.4s;
  border: 1px solid #e2e6f1;
}
.location-property-thumb {
  position: relative;
  overflow: hidden;
}
.location-property-thumb img {
  transition: all ease 0.4s;
}
.location-property-content {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.lp-content-flex {
  flex: 1;
}
.lp-content-flex .lp-content-title {
  font-size: 17px;
  margin-bottom: 0px;
}
.lp-content-right .lp-property-view {
  width: 40px;
  height: 40px;
  background: #009688;
  color: #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.4s;
  border-radius: 0.3rem;
}
.location-property-wrap:hover .location-property-thumb img,
.location-property-wrap:focus .location-property-thumb img {
  transform: scale(1.5);
}
/*--------------- Sidebar Search -------------*/
.filter-sidebar {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  transition: 0.5s;
  padding-top: 50px;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.filter-sidebar .show-hide-sidebar {
  padding: 10px 20px;
  overflow-y: scroll;
  position: relative;
  width: calc(100% + 17px);
  min-height: 300px;
  display: block;
  height: 100%;
}
.filter-sidebar .ur-detail-wrap {
  padding: 0 1.4em 2em 1.4em;
}
.filter-sidebar .closebtn {
  position: absolute;
  top: 10px;
  right: 12px;
  font-size: 36px;
  margin-left: 50px;
  width: 30px;
  height: 30px;
  background: #1ac790;
  text-align: center;
  font-size: 11px;
  padding: 0;
  line-height: 32px;
  color: #ffffff;
  border-radius: 50%;
}

.simple-sidebar {
  border-radius: 0.4rem;
  border: 1px solid #eaf2f9;
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
}
.simple-sidebar .input-with-icon .form-control,
.simple-sidebar .input-with-shadow .form-control,
.simple-sidebar .input-with-shadow .select2-container,
.simple-sidebar .input-with-icon .select2-container {
  box-shadow: none;
  border: 1px solid #e7ebf1;
}
.search-sidebar_header {
  display: none;
  position: relative;
  width: 100%;
  height: 3rem;
  padding: 0rem 0rem 0.5rem;
  background-color: #fff;
  border-bottom: 1px solid #dfe4ec;
  margin-bottom: 1rem;
}
.search-sidebar_header .ssh_heading {
  margin: 0;
  flex: 1;
  font-size: 16px;
}
.simple-sidebar button.w3-bar-item.w3-button.w3-large {
  background: #ffffff;
  border: none !important;
  cursor: pointer;
  outline: none !important;
  color: #465169;
}
@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

/*---------------- Sidebar Search Style 2 --------------*/
.search-styles-2 {
  border-radius: 0.4rem;
  border: 1px solid #e6eaf1;
  background: #ffffff;
  margin-bottom: 30px;
  overflow: hidden;
}
.search-inner {
  padding: 2rem 0 1rem;
}
.single_search_boxed {
  padding: 0;
  border-top: 1px solid #eef0f5;
  display: inline-block;
  width: 100%;
}
.search-styles-2 label {
  font-size: 13px;
  font-weight: 500;
  color: #455892;
}
.single_search_boxed:first-child {
  border-top: none;
}
.widget-boxed-header {
  padding: 0;
  width: 100%;
  position: relative;
  display: block;
}
.widget-boxed-header h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
}
.widget-boxed-header h4 a {
  height: 100%;
  display: block;
  color: #0c2339 !important;
  position: relative;
  padding: 1rem 0rem;
}
.widget-boxed-header h4 a:before {
  content: "\f068";
  position: absolute;
  width: 25px;
  height: 25px;
  right: 0;
  top: 52%;
  transform: translate(-10%, -52%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f7;
  font-family: "Font Awesome 6 Free";
  font-size: 10px;
  color: #445977;
}
.widget-boxed-header h4 a.collapsed:before {
  content: "\2b";
  background: #f5f7fb;
  color: #140c40;
}
.hm_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1.5rem 20px 1.5rem;
  background-color: #fff;
  border-bottom: 1px solid #ecf0f7;
}
.hm_nav_title {
  font-size: 17px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  /* font-family: "Campton W00 Bold","Helvetica Neue",arial,sans-serif; */
  font-weight: 400;
  font-style: normal;
  line-height: 1.3;
  font-weight: 800;
  text-align: center;
  position: absolute;
  letter-spacing: 0.4px;
  left: 0;
  right: 0;
  margin: 0;
}
span.mod-close {
  font-size: 15px;
  color: #1a1e2b !important;
  cursor: pointer;
  z-index: 1;
  box-shadow: none !important;
}
span.hm_nav_clear {
  font-weight: 600;
  color: #140c40;
  font-size: 13px;
}
.filter-search-box .form-group .form-control {
  margin: 0 !important;
  box-shadow: none !important;
  background: #eff4fc;
  border: 1px solid transparent;
  color: #707e9c;
}
.filter-search-box,
.form-group.filter_button {
  padding: 0em;
}
.widget-boxed-header .selected {
  display: block;
  line-height: 1.2;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  color: #72809d;
}
.filter_wraps .radio-custom + .radio-custom-label:before {
  margin-right: 0px;
}
.filter_wraps [type="radio"]:checked + label,
.filter_wraps [type="radio"]:not(:checked) + label {
  padding-left: 0;
  width: 100%;
}
.filter_wraps [type="radio"]:checked + label:before,
.filter_wraps [type="radio"]:not(:checked) + label:before {
  right: 0;
  left: auto;
}
.filter_wraps [type="radio"]:checked + label:after,
.filter_wraps [type="radio"]:not(:checked) + label:after {
  right: 3px;
  left: auto;
}
.filter-list li {
  padding: 1rem 0;
  border-bottom: 1px dashed rgba(20, 12, 64, 0.12);
  width: 100%;
  display: inline-block;
  padding-left: 1.5em;
}
.filter-list li:last-child {
  border-bottom: none;
}

/*------------- Advance Search ------------*/
.advance-search .form-group {
  margin-bottom: 0rem;
}
.advance-search .search-big-form .form-group i {
  color: #074da3;
}
.adv-search-filter {
  float: right;
  margin-bottom: 1rem;
  display: inline-block;
}
.adv-filter-box {
  display: inline-block;
  margin-left: 15px;
}
.btn-flit {
  width: 42px;
  border-radius: 2px;
  border: 1px solid #d1dade;
  color: #646e7b;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-flit.active {
  color: #074da3;
}
.btn.btn-order-by-filt {
  background: transparent;
  border: 1px solid #d1dade;
  color: #646e7b;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.btn.btn-order-by-filt i {
  margin-left: 5px;
}
.back-brow {
  display: inline-block;
  margin-bottom: 1rem;
}
.back-btn {
  font-weight: 600;
  opacity: 0.9;
}
.back-btn i {
  margin-right: 5px;
}
.back-btn:hover,
.back-btn:focus {
  opacity: 1;
}

/*====================================
	Property Style
======================================*/
.btm.slick-slider .property-listing,
.btm.slick-slider .agents-grid {
  margin-bottom: 15px !important;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%) !important;
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%) !important;
}

.property-listing.property-1 .listing-img-wrapper img {
  max-height: 240px;
  width: 100%;
}
/*------------ Property Style: Classic Style Real Estate -----------*/
.property_item {
  background: #fff;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -moz-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.image {
  position: relative;
}
.image a {
  display: block;
}
.sb-date {
  position: absolute;
  top: 30px;
  left: 30px;
  display: inline-block;
  background: rgba(23, 42, 76, 0.4);
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 50px;
  font-size: 14px;
}
.sb-date i {
  margin-right: 7px;
}
span.tag_t {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: inline-block;
  padding: 7px 15px;
  background: #22326b;
  border-radius: 4px;
  color: #ffffff;
}
.proerty_content {
  position: relative;
  padding: 2em 2em 1.5em;
}
.proerty_text {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.proerty_text .captlize {
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  line-height: 1;
  margin-bottom: 5px;
}
.property_meta {
  display: block;
  margin: 2em 0 1rem;
}
.classical-list .proerty_price {
  margin: 0;
  font-weight: 600;
  color: #049869;
}
.property_add {
  color: #72809d;
}
.classical-list .btn {
  font-size: 14px;
  padding: 12px 18px;
}

/*--------------- New Update ------------------*/
.prt_saveed_12lk .toggler {
  color: #bec7d8;
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1;
}
.prt_saveed_12lk .toggler [type="checkbox"]:checked,
.toggler [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.prt_saveed_12lk .toggler input:checked + i {
  color: #ff5722;
}

/*----------------- Download App Design ---------------*/
.content_block_2 .content-box .btn-box .download-btn.play-store {
  margin-right: 30px;
}
.content_block_2 .content-box .btn-box .download-btn {
  position: relative;
  display: inline-block;
  float: left;
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  margin: 0.5rem 0;
  color: #222e64;
  padding: 16px 30px 15px 80px;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0 5px 5px rgba(232, 23, 23, 0.2);
  border: 1px solid #e81717;
  background: #ffffff;
}
.content_block_2 .content-box .btn-box .download-btn i {
  position: absolute;
  color: #e81717;
  left: 30px;
  top: 16px;
  font-size: 40px;
}
.content_block_2 .content-box .btn-box .download-btn span {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #828aaf;
  /* font-family: 'Jost', sans-serif; */
  font-weight: 500;
}
.content_block_2 .content-box .btn-box .download-btn h3 {
  position: relative;
  margin-bottom: 0;
  display: block;
  font-size: 24px;
  line-height: 26px;
  color: #242f65;
  font-weight: 600;
  transition: all 500ms ease;
}
.content_block_2 .content-box .btn-box .download-btn.app-store h3 {
  color: #2798ff;
}
.content_block_2 .content-box .btn-box .download-btn.app-store i {
  color: #242f65;
}
.content_block_2 .content-box .btn-box .download-btn.app-store {
  box-shadow: 0 5px 5px rgba(34, 46, 100, 0.2);
  border: 1px solid #242f65;
}
.form-control:focus {
  box-shadow: none;
}
.caption-wrap-content h2,
.caption-wrap-content p {
  color: #ffffff;
}
.caption-wrap-content a:hover,
.caption-wrap-content h2:focus {
  color: #ffffff;
  background: #074da3;
  border-color: #074da3;
}
/*---------- Slick Arrow ---------*/
.property-listing.property-2 .slick-slide a:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  z-index: 1;
  opacity: 0.15;
}
.property-listing.property-2.modern .slick-slide a:before {
  opacity: 0.1;
}
.property-listing .slick-arrow.slick-next {
  transform-origin: 100% 0%;
}
.property-listing .slick-arrow {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 0.4rem;
  opacity: 1;
  transition: 0.1s;
  z-index: 1;
}
.slick-next:before {
  content: "\f061";
  font-family: "Font Awesome 6 Free";
}
.slick-prev:before {
  content: "\f060";
  font-family: "Font Awesome 6 Free";
}
.property-listing .slick-next {
  right: 5px;
}
.property-listing .slick-prev {
  left: 5px;
}
.property-listing .slick-arrow:before {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  opacity: 0;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.4);
  font-size: 10px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 0.4rem;
  box-shadow: 0 5px 24px rgba(31, 37, 59, 0.15);
  transition: 0.2s;
}
.property-listing .slick-arrow.slick-next:before {
  text-align: center;
  left: 0;
}
.property-listing .slick-arrow.slick-prev:before {
  text-align: center;
  right: 0;
}
.property-listing:hover .slick-arrow:before {
  opacity: 1;
}

/* Search Type Buttons*/
.property-search-type {
  display: table;
  padding-bottom: 35px;
  position: relative;
  margin: 0 auto;
}

.property-search-type input[type="radio"] {
  display: none;
}

.property-search-type label {
  background-color: #fff;
  color: #333;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 11px 25px;
  margin: 0 15px 0 0;
  float: left;
  transition: all 0.2s;
  border-radius: 4px;
}

.property-search-type label:hover,
.property-search-type label.active {
  background-color: #074da3;
  color: #fff;
}

.property-search-type-arrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(3px, 0, 0);
}
/*------------------------------------------------
	Detail Page Style 
------------------------------------------------*/
.block-wrap {
  display: block;
  width: 100%;
  background: #ffffff;
  padding: 40px;
  padding-top: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.block-wraps {
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 30px;
  overflow: hidden;
}
.block-wrap:last-child {
  margin-bottom: 0px;
}
.block-wraps .block-header {
  display: table;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
.block-header {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e8eef7;
}
.nav-tabs .nav-link {
  margin-bottom: 1px;
}
.block-wraps-header .block-body {
  padding: 1.5rem;
}
.block-header .block-title {
  font-size: 16px;
}
.block-body {
  line-height: 1.7;
  display: inline-block;
  width: 100%;
}
.avl-features {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dw-proprty-info {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.avl-features li {
  display: block;
  list-style: none;
  position: relative;
  margin: 10px 0 10px;
  padding-left: 30px;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  text-transform: capitalize;
}
.avl-features li:before {
  content: "\f058";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  color: #009688;
  width: 20px;
  height: 20px;
  background: rgb(0 150 136 / 10%);
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  left: 0;
  top: 5px;
  font-size: 10px;
}
.dw-proprty-info li {
  display: block;
  list-style: none;
  position: relative;
  margin: 6px 0;
  color: #074da3;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.dw-proprty-info li strong {
  display: block;
  color: #2a3c77;
}
.accordion {
  border: 1px solid #e2e6ea;
  border-radius: 4px;
}
.accordion .card {
  border: none;
}

.card-header {
  background: #f5f8fb;
  border-bottom: 1px solid #e2e6ea;
}
.accordion .btn-link {
  color: #5c657f;
  text-decoration: none;
}
.accordion .btn-link > span {
  font-size: 12px;
  font-weight: 400;
  margin-left: 15px;
  background: #e8ecf1;
  padding: 6px 15px;
  border-radius: 2px;
}
.sidebar-property-slide .slick-prev,
.sidebar-property-slide .slick-next {
  top: -26px;
}
.sidebar-property-slide .slick-prev {
  left: auto;
  right: 60px;
}
.sidebar-property-slide .slick-arrow:before {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  opacity: 1;
  color: #333c56;
}
.sidebar-property-slide .single-items {
  padding: 0 0px;
}
.accordion .card-title {
  margin-bottom: 0;
}
.mfp-ready .mfp-figure {
  opacity: 1 !important;
}
/*----- Single Advance Slider -----*/
.single-advance-property .item-slick.slick-slide.slick-current.slick-active {
  outline: none !important;
}
.single-advance-property .slick-slide img {
  display: block;
  max-width: 100%;
}
.single-advance-property .slider-for img {
  width: 100%;
  min-height: 100%;
}
.single-advance-property .slider-nav {
  margin: auto;
}
.single-advance-property .slider-nav .item-slick {
  max-width: 240px;
  margin-right: 15px;
  outline: none !important;
  cursor: pointer;
}
.single-advance-property .slider-nav .item-slick img {
  max-width: 100%;
  background-size: cover;
  background-position: center;
}
.single-advance-property .slider-nav {
  margin: auto;
}
.single-advance-property .slider-nav .item-slick {
  max-width: 240px;
  margin-right: 0px;
  outline: none !important;
  cursor: pointer;
}
.single-advance-property .slider-nav .item-slick img {
  max-width: 100%;
  background-size: cover;
  background-position: center;
}
.single-advance-property .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 50;
  margin-top: -12px;
}
.single-advance-property .slick-prev {
  left: 0;
}
.single-advance-property .slick-next {
  right: 0;
}
.single-advance-caption {
  padding: 2em;
}
.property-name-info {
  margin-bottom: 2rem;
}
.property-name-info .property-name {
  margin-bottom: 5px;
}
.property-price-info {
  margin-bottom: 2rem;
}
.property-price-info .property-price {
  margin-bottom: 5px;
}
.property-statement ul {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
}
.property-statement ul li {
  display: inline-block;
  width: 50%;
  float: left;
  vertical-align: top;
}
.property-statement ul li > i {
  float: left;
  font-size: 35px;
  margin-right: 10px;
  margin-top: 5px;
}
.ps-trep {
  margin-left: 50px;
  vertical-align: top;
}
.ps-trep span {
  margin-bottom: 3px;
  display: inline-block;
  color: #2a3c77;
}
.ps-trep .ps-type {
  color: #074da3;
}
.single-advance-property .slick-next {
  right: 20px;
}
/*--- Magnific ----*/
.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close:hover {
  cursor: pointer;
}

.mfp-container:hover {
  cursor: default;
}

.image-source-link {
  color: #98c3d1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
.mfp-ready .mfp-figure {
  opacity: 0;
}
.mfp-zoom-in .mfp-figure,
.mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 0;
  transition: all 0.3s ease-out;
  transform: scale(0.95);
}

.mfp-zoom-in.mfp-bg .mfp-zoom-in .mfp-preloader {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-image-loaded .mfp-figure,
.mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg .mfp-zoom-in.mfp-ready .mfp-preloader {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-figure,
.mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler {
  transform: scale(0.95);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg .mfp-zoom-in.mfp-removing .mfp-preloader {
  opacity: 0;
}
.mfp-iframe-scaler {
  overflow: visible;
}
.mfp-zoom-out-cur {
  cursor: auto;
}
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer;
}
.page-title {
  width: 100%;
  height: 200px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background: #074da3;
}
.page-title .ipt-title {
  color: #ffffff;
}
.ipn-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.overlio-40 {
  margin-top: -40px;
}
.ipt-title {
  margin-bottom: 5px;
}
.ipn-subtitle {
  font-size: 20px;
  font-family: initial;
  font-style: italic;
}

.page-title.bb-title {
  background-size: cover !important;
  background-position: center !important;
}
.page-title.bb-title .ipt-title {
  color: #ffffff;
}
.page-title.bb-title .ipn-subtitle {
  color: #ffffff;
  opacity: 0.8;
}
/*----------- Property Reviews ---------------------*/
.author-review ul {
  margin: 0;
  padding: 0;
}
li.article_comments_wrap {
  border-bottom: 1px dashed #e3e7ea;
  margin-bottom: 20px;
}
li.article_comments_wrap:last-child {
  border: none;
  margin: 0;
}
.author-review article {
  overflow: hidden;
  padding-bottom: 25px;
}
.author-review article .article_comments_thumb {
  float: left;
  width: 80px;
}
.author-review article .article_comments_thumb img {
  max-width: 80px;
  border-radius: 50%;
}

.author-review article .comment-details {
  overflow: hidden;
  padding-left: 30px;
}
.author-review article .comment-details .comment-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.author-review article .comment-details .comment-meta .comment-left-meta {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
}
.author-review
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .author-name {
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 0px;
}
.author-review
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .comment-date {
  color: #637582;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 7px 0 0;
}
.author-review article .comment-details .comment-text {
  margin: 18px 0 0;
}
.author-review article .comment-details .comment-text p {
  margin: 0;
}
.author-review article .comment-details .comment-text {
  margin: 19px 0 0;
}
.reviews-checked {
  text-align: center;
  display: table;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
}

/*----------- Neare Places ---------------------*/
.nearby-wrap {
  margin-bottom: 2rem;
}
.nearby-wrap:last-child {
  margin-bottom: 0rem;
}
.neary_section_list {
  width: 100%;
  position: relative;
  display: block;
}
.neary_section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #e8eaf1;
  padding: 10px 0;
}

.neary_section:last-child {
  border-bottom: none;
}
.neary_section_first {
  flex: 1;
}
.neary_section_first .nearby_place_title {
  font-size: 14px;
  margin: 0;
}
.neary_section_last {
  display: flex;
  align-items: center;
}
.nearby_place_rate {
  padding: 5px 5px;
  height: auto;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 4px;
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.nearby_place_rate i {
  font-size: 8px;
  margin-right: 4px;
  position: relative;
}
.nearby_place_rate.mid {
  background: #ff9800;
}
.nearby_place_rate.good {
  background: #03a07c;
}
.nearby_place_rate.high {
  background: #8bc34a;
}
.nearby_place_rate.poor {
  background: #f44336;
}

/*------------ List Property Sidebar -----------*/

.sides_list_property {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.8rem;
  border: 1px solid #e7eaec;
  border-radius: 0.4rem;
  background: #ffffff;
  margin-bottom: 15px;
}
.sides_list_property_thumb {
  width: 125px;
  height: 75px;
  border-radius: 4px;
  overflow: hidden;
}
.sides_list_property_thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.sides_list_property_detail {
  padding-left: 0.7rem;
  width: 100%;
}
.sides_list_property_detail .lists_property_status {
  font-size: 13px;
}
.sides_list_property_detail h4 {
  font-size: 16px;
  line-height: 1;
  margin: 0px 0 2px;
}
.sides_list_property_detail span {
  font-size: 13px;
}
.sides_list_property_detail span i {
  margin-right: 4px;
}
.lists_property_price {
  display: flex;
  align-items: center;
}
.lists_property_price .lists_property_types {
  flex: 1;
}
.lists_property_price {
  display: flex;
  align-items: center;
  padding: 8px 0;
}
.property_types_vlix {
  display: inline-block;
  padding: 4px 15px;
  color: #f95d02;
  background: rgba(249, 93, 2, 0.1);
  border-radius: 50px;
  font-size: 13px;
}
.property_types_vlix.sale {
  color: #03a98a;
  background: rgba(3, 169, 138, 0.1);
}
.property_types_vlix.buy {
  color: #a871e4;
  background: rgba(168, 113, 228, 0.1);
}
/*----------- Compare property ---------------*/
.compare-slide-menu {
  position: fixed;
  top: 0;
  transition: 0.4s ease-in-out;
  height: 100vh;
  background-color: #fff;
  right: -320px;
  width: 320px;
  padding: 0;
  z-index: 999999;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.csm-content {
  overflow-y: scroll;
  height: 100%;
  width: 340px;
}

.compare-slide-menu.active {
  right: 0;
}
.csm-properties {
  padding: 30px 20px;
  padding-bottom: 0;
}
.csm-content > h4 {
  font-size: 18px;
  display: block;
  margin: 0 0 0 0;
  padding: 30px;
  position: relative;
  color: #fff;
  width: 100%;
  background-color: #274abb;
}
/* Trigger */
.csm-trigger {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
  width: 50px;
  background-color: #3151b7;
  color: #fff;
  border-radius: 3px 0 0 3px;
  text-align: center;
  line-height: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
}

.csm-trigger:before {
  content: "\e6ac";
  font-family: themify;
  display: inline-block;
  transition: 0.3s ease-in-out;
}
.csm-buttons {
  padding: 0 30px;
  padding-bottom: 30px;
}
.compare-slide-menu.active .csm-trigger:before {
  transform: rotate(180deg);
}
.csm-buttons a.button {
  padding: 10px 20px;
  font-size: 15px;
  display: inline-block;
  border-radius: 3px;
}
.csm-buttons a.button.btn-compare,
.csm-buttons a.button.reset:hover {
  background-color: #3151b7;
  color: #ffffff;
}
.csm-buttons a.button.btn-reset,
.csm-buttons a.button.reset:hover {
  background-color: #ffe6e6;
  color: #ea1e1e;
}
.property-listing:hover .remove-compare,
.property-listing:focus .remove-compare {
  opacity: 1;
}
.compare-slide-menu .property-listing {
  margin-bottom: 25px;
}
.remove-compare {
  position: absolute;
  top: 15px;
  left: 15px;
  opacity: 0;
  z-index: 4;
  transition: all ease 0.4s;
}
.remove-compare > a {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #f32945;
  color: #ffffff;
  border-radius: 3px;
  font-size: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/*--------------- Compare Property ------------------*/
.comp-property {
  display: block;
  position: relative;
  padding: 0 10px;
  min-height: 260px;
}
.comp-property-blank {
  display: block;
  height: 259px;
}
.compare-slide-menu .property-listing.property-2 .listing-name {
  font-size: 13px;
}
.compare-slide-menu .listing-card-info-price {
  font-size: 18px;
}
span.remove-from-compare {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #e22828;
  color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  line-height: 25px;
  opacity: 0;
  transition: all ease 0.4s;
}
.clp-img {
  position: relative;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.clp-title {
  padding: 0px 0;
}
.clp-title h4 {
  font-size: 17px;
  margin: 0 0 2px 0;
}
.comp-property:hover .remove-from-compare,
.comp-property:focus .remove-from-compare {
  opacity: 1;
}
.show-mb {
  display: none !important;
}
.pricing-5 {
  overflow: hidden;
}
.pricing-5 div[class*="col-"] {
  transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  padding: 0;
}
.pricing-5 .pricing__title {
  min-height: 144px;
  padding: 1.625em 0;
  position: relative;
}
.pricing-5 .pricing__title h6,
.pricing-5 .pricing__title h5 {
  margin: 0;
}
.pricing-5 .pricing__title h6 {
  opacity: 0.5;
}
.pricing-5 .pricing__price {
  margin-top: 0.8125em;
}
.pricing-5 ul {
  margin: 0;
  padding: 0;
}
.pricing-5 ul li {
  line-height: 1.625em;
  padding: 1.625em 0;
  list-style: none;
}
.pricing-5 ul li:nth-child(odd) {
  background: #f0f6fa;
}
.pricing-5 ul li span:not(.btn__text) {
  font-style: italic;
}
.checkmark,
.crossmark {
  width: 1.625em;
  height: 1.625em;
  line-height: 1.625em;
  background: #64b671;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  text-align: center;
}
.crossmark {
  background: #e34747;
}
.checkmark:before,
.crossmark:before {
  content: "\2713";
  position: absolute;
  color: #fff;
  width: 100%;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.crossmark:before {
  content: "\2715";
}
.pricing-5 ul li span:not(:first-child) {
  display: inline-block;
  margin-left: 0.8125em;
  position: relative;
  bottom: 6px;
}
@media (min-width: 990px) {
  .pricing-5 ul li {
    height: 4.875em;
  }
}

/*------------ Property Detail Detail -------------*/
.gallery_bottom_block {
  padding: 1em 0;
  border-bottom: 1px solid #e9ecf3;
}
.property_info_detail_wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.property_info_detail_wrap_first {
  flex: 1;
}
.prt-sect-pric h3 {
  margin: 0;
  font-size: 30px;
}
.prt-sect-pric h3 sub {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
}
.pr-price-into h2 > i {
  font-size: 16px;
  font-weight: 400;
  font-style: initial;
  opacity: 0.9;
}
.pr-price-into h2 {
  margin-bottom: 0;
}
.pr-price-into h2 {
  margin-bottom: 0;
}
.pr-single-info {
  display: inline-block;
  margin-left: 0.5rem;
  position: relative;
}
.pr-single-info:first-child {
  margin: 0;
}
span.view-pr {
  margin-left: 6px;
  position: relative;
  top: -3px;
}
.pr-single-info > a {
  color: #ffffff !important;
  font-size: 18px;
  width: 42px;
  height: 42px;
  border-radius: 2px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background: #1c4c75;
  justify-content: center;
}
.btn-share,
.btn-share:hover,
.btn-share:focus {
  background: #1c4c75;
  color: #ffffff;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
  width: 42px;
  height: 42px;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.share-opt-wrap .dropdown-menu {
  text-align: center;
  bottom: 55px;
  top: initial !important;
  left: 0 !important;
  padding: 7px 0 9px;
  border: 1px solid #e8e9f1;
}
.share-opt-wrap .dropdown-menu a {
  padding: 2px 6px 0;
  line-height: 1;
  position: relative;
  top: 3px;
  display: inline-block;
  border-bottom: none;
}
.dropdown-menu.show a i {
  margin-right: 7px;
}
.cl-facebook i {
  color: #395693 !important;
}
.cl-twitter i {
  color: #1c9cea !important;
}
.cl-gplus i {
  color: #d23d35 !important;
}
.cl-instagram i {
  color: #c82f99 !important;
}
.property_info_detail_wrap.light {
  bottom: 2rem;
  color: #ffffff;
}

.prties_list {
  display: block;
  position: relative;
  width: 100%;
}
.prties_list ul {
  display: block;
  width: 100%;
  padding: 0;
  margin: 5px 0 0 0;
}
.prties_list ul li {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  margin-right: 1rem;
  position: relative;
}
.prties_list ul li:before {
  content: ".";
  position: absolute;
  left: -9px;
  top: 2px;
  font-size: 25px;
  line-height: 0;
  border-radius: 50%;
}
.prties_list ul li:first-child:before {
  display: none;
}
.like_share_wrap {
  background-color: #fff;
  color: #707070;
  border-radius: 0.4rem;
  padding: 20px;
  transition: 0.25s;
  margin-bottom: 20px;
}
ul.like_share_list {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
}
ul.like_share_list li {
  flex: 0 0 50%;
  padding: 0 5px;
  list-style: none;
  width: 50%;
}
ul.like_share_list li a {
  width: 100%;
  color: #03a98a !important;
  background: rgba(3, 169, 138, 0.1);
  border: 1px solid #03a98a;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 14px;
}
ul.like_share_list li a:hover,
ul.like_share_list li a:focus,
ul.like_share_list li a:active {
  width: 100%;
  color: #03a98a !important;
  background: rgba(3, 169, 138, 0.1);
  border: 1px solid #03a98a;
  border-radius: 0.4rem;
  font-weight: 600;
  font-size: 14px;
}
ul.like_share_list li:last-child a {
  background: rgba(249, 93, 2, 0.1);
  color: #f95d02 !important;
  border-color: #f95d02;
}
ul.like_share_list li a i {
  margin-right: 7px;
}
.btn.cop_btn {
  width: 100%;
  height: 56px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.downld_rport {
  background: #ffffff;
  border: 1px solid #e6eaf3;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 100%;
}
.downld_rport ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.downld_rport ul li {
  width: 50%;
  flex: 0 0 50%;
  padding: 1rem 1.5rem;
  font-size: 15px;
  font-weight: 600;
  color: #172e6f;
}
.downld_rport ul li:first-child {
  border-right: 1px solid #e6eaf3;
}
.downld_rport ul li i {
  font-size: 40px;
  margin-right: 10px;
  color: #03a98a;
  display: inline-table;
  float: left;
}
.downld_rport ul li span {
  font-size: 13px;
  color: #546cb1;
  display: block;
}
.downld_rport ul li.pdf i {
  color: #e62a1c;
}

/*----------------- Style 2 --------------*/
.property_block_wrap {
  display: block;
  width: 100%;
  background: #ffffff;
  padding: 0;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #e6eaf3;
}
.property_block_wrap.style-2 {
  border-radius: 6px;
  border: none;
}
.property_block_wrap.style-2 {
  border-radius: 6px;
  border: none;
}
.property_block_wrap.style-2 .block-body {
  padding: 0rem 1.5rem 1rem;
}
.property_block_wrap.style-2 .property_block_wrap_header h4 {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
}
.property_block_wrap.style-2 .property_block_wrap_header h4:after {
  font-family: "Font Awesome 5 Free";
  content: "\f106";
  position: relative;
  float: right;
  margin-right: 0px;
  transition: 0.2s ease-out;
  background: #f5f7fb;
  width: 26px;
  height: 26px;
  font-weight: 600;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.property_block_wrap.style-2 .property_block_wrap_header .collapsed h4:after {
  transform: rotate(180deg);
}
.property_block_wrap.style-2 .property_block_wrap_header h4:before {
  display: none;
}
.property_block_wrap.style-2 .property_block_wrap_header {
  padding: 1rem 1.5rem;
  border: none;
}
.property_block_wrap.style-2 .property_block_wrap_header.no-collapse h4:after {
  display: none;
}
ul.deatil_features {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
ul.deatil_features li {
  width: 33.333333%;
  flex: 0 0 33.333333%;
  display: inline-block;
  font-size: 14px;
  color: #8a98a9;
  margin-bottom: 1.2rem;
  line-height: 1.5;
}
ul.deatil_features li strong {
  color: #164268;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}
.nearby-wrap {
  margin-bottom: 2rem;
}
.nearby-wrap:last-child {
  margin-bottom: 0rem;
}
.neary_section_list {
  width: 100%;
  position: relative;
  display: block;
}
.neary_section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 0;
}

.neary_section:last-child {
  border-bottom: none;
}
.neary_section_first {
  flex: 1;
}
.neary_section_first .nearby_place_title {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}
.neary_section_last {
  display: flex;
  align-items: center;
}

.neary_section_first .nearby_place_title > small {
  margin-left: 10px;
}
.neary_section_last i {
  font-size: 10px;
  color: #ced1e4;
}
.neary_section_last i.filled {
  color: #ff9800;
}
.reviews-count {
  margin-left: 5px;
}
.nearby_header {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
}
.nearby_header_first {
  flex: 1;
}
.nearby_header_first h5 {
  margin: 0;
  font-size: 16px;
}
.nearby_header_last .nearby_powerd {
  display: flex;
  align-items: center;
}
.nearby_header_last .nearby_powerd img {
  max-width: 50px;
  margin-left: 10px;
}
.list-fx-features {
  margin-top: 20px;
  justify-content: flex-start;
}
.listing-card-info-icon {
  width: auto;
  flex: inherit;
  display: inline-flex;
  margin-right: 1.2rem;
  align-items: center;
}
.listing-card-info-icon:last-child {
  margin: 0;
}
.prt-price-fix {
  line-height: 1.4;
}
.prt-price-fix sub {
  font-size: 15px;
  font-weight: 400;
  color: #929fc5;
}
.featured_slick_gallery {
  position: relative;
}
.btn-view-pic {
  position: absolute;
  bottom: 30px;
  right: 50px;
  background: #ffffff;
  padding: 15px 26px;
  border-radius: 5px;
  font-weight: 600;
  color: #074da3;
}
.btn-view-pic.top {
  position: absolute;
  top: 30px;
  bottom: auto;
  right: 50px;
}

/*------------ Property Detail Style 3 -------------------*/
.property_block_wrap.style-3 {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  margin-top: -60px;
  border: none;
}
.pbw-flex-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f5fd;
  border-radius: 6px;
  padding: 2.5rem;
}
.pbw-flex {
  padding-left: 2rem;
}

/*------------ Property Detail Style 4 -------------------*/
.property_block_wrap.style-4 {
  background: transparent;
  border: none;
  text-align: center;
  color: #ffffff;
}
.property_block_wrap.style-4 .prt-types.sale {
  background: #3096ff;
  color: #ffffff;
  margin-bottom: 5px;
}
.property_block_wrap.style-4 .list-fx-features {
  margin-top: 20px;
  justify-content: center;
}
.property_block_wrap.style-4 .listing-card-info-icon {
  color: #6a84e2;
}
.pbwts-social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.pbwts-social ul {
  padding: 0;
  margin: 0;
}
.pbwts-social ul li {
  display: inline-flex;
  margin-right: 10px;
  font-weight: 600;
}
.pbwts-social ul li a {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #3096ff;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  border: 1px solid #3096ff;
  transition: all ease 0.4s;
}
.pbwts-social ul li a:hover,
.pbwts-social ul li a:focus {
  background: #ffffff;
  color: #3096ff;
  border: 1px solid #ffffff;
}
.property_block_wrap.style-4 .prt-price-fix sub {
  color: #9eceff;
}

/*----------------- Vedio -----------------*/
.property_video {
  position: relative;
}
.property_video .thumb {
  overflow: hidden;
  border-radius: 6px;
}
.bb-video-box {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.bb-video-box-inner {
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.bb-video-box-innerup {
  width: 65px;
  height: 65px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 25px;
}
.bb-video-box-innerup a {
  line-height: 0;
}
ul.list-gallery-inline {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
ul.list-gallery-inline li {
  width: 33.333333%;
  flex: 0 0 33.33333%;
  padding: 5px;
}
ul.list-gallery-inline li a {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.property_video:before {
  background: #17203a;
  border-radius: 6px;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  content: "";
  bottom: 0;
  opacity: 0.5;
}

/*---------- General Features: rating ---------*/
.rating-overview {
  display: flex;
  margin: 35px 0 25px;
  background: #fff;
  padding: 30px;
  padding-right: 20px;
  border-radius: 8px;
}
.rating-overview-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: #ffdead;
  width: 135px;
  margin-right: 20px;
  text-align: center;
  border-radius: 0;
  border-right: 1px solid #e8e8e8;
  padding-right: 27px;
}
.rating-overview-box-total {
  font-size: 58px;
  font-weight: 700;
  line-height: 1em;
  display: block;
  color: #08254c;
}
.rating-overview-box-percent {
  font-size: 15px;
  margin-bottom: 0;
  display: block;
}

.rating-overview-box .star-rating {
  font-size: 16px;
  margin: 0;
  display: block;
  letter-spacing: -0.5px;
}
.rating-bars {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin: 0;
  padding: 0;
  margin-top: 0;
  align-items: center;
}
.rating-bars-item {
  margin: 10px 15px;
  width: calc(50% - 30px);
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1366px) {
  .rating-bars-item {
    width: calc(50% - 30px);
  }
}
.rating-bars-name {
  font-weight: 500;
  color: #12344d;
  display: block;
  font-size: 14px;
  line-height: 22px;
}
.rating-bars-inner {
  display: flex;
  width: 100%;
}
.rating-bars-rating {
  display: inline-block;
  vertical-align: baseline;
  background: #f4f5f7;
  width: 100%;
  height: 6px;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #f4f5f7;
  align-self: center;
}
.rating-bars-rating-inner {
  height: 6px;
  display: block;
  background-color: #f4f5f7;
  position: relative;
  width: 0;
  transition: width 0.5s;
}
.rating-bars-rating.high .rating-bars-rating-inner {
  background-color: #00ba74;
}
.rating-bars-rating.good .rating-bars-rating-inner {
  background-color: #83ce36;
}
.rating-bars-rating.mid .rating-bars-rating-inner {
  background-color: #fbb851;
}
.rating-bars-rating.poor .rating-bars-rating-inner {
  background-color: #e6453c;
}
.rating-bars-item strong {
  color: #4c4f5a;
  font-size: 12px;
  font-weight: 600;
  align-self: center;
  background: #f4f5f7;
  border-radius: 50px;
  width: 46px;
  height: 22px;
  text-align: center;
  line-height: 21px;
}
.rating-overview-box .star-rating i {
  color: #ff9500;
}

/*------------------------------------------------------------
	How it Work
-------------------------------------------------------------*/
.middle-icon-features {
  display: table;
  width: 100%;
  text-align: center;
}
.middle-icon-large-features-box {
  width: 85px;
  height: 85px;
  line-height: 80px;
  text-align: center;
  transition: 0.25s ease-in-out;
  font-size: 30px;
  border-radius: 20px;
  margin: 25px auto;
  position: relative;
  border: 1px solid transparent;
}
.icon-features-wrap {
  text-align: center;
  position: relative;
}
.icon-features-wrap:before {
  /* content:url(../img/arrow.png); */
  position: absolute;
  width: auto;
  height: auto;
  background: transparent;
  left: 100%;
  top: 40px;
}
.middle-icon-features-item.remove .icon-features-wrap:before {
  display: none;
}
.middle-icon-features-content {
  padding: 10px 30px 0;
  text-align: center;
}
.middle-icon-features-content p {
  margin-bottom: 0;
}
.middle-icon-features-content h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.middle-icon-large-features-box:before {
  content: "";
  position: absolute;
  border-radius: 20px;
  width: 85px;
  height: 85px;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  background: transparent;
  border: 1px solid transparent;
}

.middle-icon-large-features-box.f-light-warning:before {
  background: rgba(255, 143, 0, 0.1);
  border-color: rgba(255, 143, 0, 0.3);
  color: #ff8f00 !important;
}
.middle-icon-large-features-box.f-light-success:before {
  background: rgba(15, 202, 152, 0.1);
  border-color: rgba(15, 202, 152, 0.3);
  color: #29cf8d !important;
}
.middle-icon-large-features-box.f-light-blue:before {
  color: #1d96ff !important;
  background: rgba(29, 150, 255, 0.1);
  border-color: rgba(29, 150, 255, 0.3);
}
.middle-icon-large-features-box.f-light-purple:before {
  color: #4d50d8 !important;
  background: rgb(77 80 216 / 10%);
  border-color: rgb(77 80 216 / 20%);
}
.middle-icon-large-features-box.f-light-danger:before {
  color: #f33066 !important;
  background: rgba(243, 48, 102, 0.1);
  border-color: rgba(243, 48, 102, 0.3);
}

/*------------- Achievement & Partnership ------------------*/
.achievement-wrap {
  position: relative;
  text-align: center;
  display: block;
  padding: 2rem 0 0;
}
.achievement-content h4 {
  margin-bottom: 5px;
  line-height: 1;
  font-size: 28px;
  color: #074da3;
}

/*------------- Category ------------------*/
.classical-cats-wrap {
  position: relative;
  display: block;
}
.classical-cats-wrap.shadows {
  box-shadow: 0 0 12px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 12px 0 rgb(19 115 234 / 10%);
}
a.classical-cats-boxs {
  position: relative;
  display: block;
  cursor: pointer;
  text-align: center;
}
.classical-cats-icon {
  display: inline-flex;
  margin-bottom: 0.5rem;
  width: 90px;
  height: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.classical-cats-wrap-content {
  position: relative;
  display: block;
  width: 100%;
}
.classical-cats-wrap-content h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
}
.classical-cats-wrap-content p {
  font-weight: 600;
  font-size: 12px;
  color: #335372;
  margin: 0;
}
.classical-cats-wrap.style_2 .classical-cats-icon {
  width: 80px;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/*------------- Trustscore ------------------*/
.trustscrore-wrap {
  position: relative;
  background: #ffffff;
  border-radius: 0.6rem;
  padding: 3.5rem 2rem;
}
.trustscrore-title {
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
}
.trustscrore-title h2 {
  font-size: 60px;
}
.trustscrore-rates {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 3rem;
}
.trustscrore-stars {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5rem;
  font-size: 13px;
  color: #ff9800;
  margin-bottom: 0.2rem;
}
.trustscrore-stars-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
}
.trustscrore-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
}
.trustscrore-subtitle {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
.trustscrore-brand img {
  max-width: 200px;
  margin: 0 auto;
}
.single-item-reviews-desc {
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
}
.single-item-reviews-desc p {
  font-size: 19px;
  margin: 0;
  font-family: "Jost";
  font-style: initial;
}
.single-item-reviews-info h4 {
  font-size: 15px;
  margin: 0;
}
.single-item-reviews-info p {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}
.counter-info-groups {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
  background: transparent;
  overflow: hidden;
  border-radius: 0.8rem;
}
.single-counter-wrap {
  position: relative;
  display: flex;
  width: 100%;
  background: #0c1620;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem 1.5rem;
  flex-direction: column;
}
.single-counter-wrap:last-child {
  background: #074da3;
  border-right: none;
}
.single-counter-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.single-counter-title h2 {
  font-size: 45px;
  color: #ffffff;
}
.single-counter-subtitle p {
  margin: 0;
  line-height: 1.6;
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.8;
  text-transform: uppercase;
}
/*------------------------------------------------------------
	Contact Info
-------------------------------------------------------------*/
.contact-info {
  margin-left: 1rem;
}
.cn-info-detail {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: start;
}
.cn-info-icon {
  float: left;
  display: inline-block;
  width: 50px;
  height: 50px;
}
.cn-info-icon i {
  font-size: 35px;
  color: #074da3;
}
.cn-info-title {
  font-size: 15px;
  margin-bottom: 2px;
}

/*------------------------------------------------------------
	PopUp Modal 
-------------------------------------------------------------*/
.modal {
  z-index: 99999;
}
.modal-body {
  padding: 2.5em 3em;
}
h4.modal-header-title {
  font-size: 3.5em;
  text-align: center;
  margin: 1rem 0 1em 0;
  font-weight: 800;
  line-height: 0;
}
.btn.pop-login {
  border-radius: 50px;
  padding: 20px 0;
  background: #074da3;
  border-color: #074da3;
  margin-top: 0.6rem;
}
.modal-divider {
  position: relative;
  margin: 20px 0;
  text-align: center;
}
.modal-divider:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-bottom: 1px solid #e1e5f2;
}
.modal-divider span {
  position: relative;
  background: #fff;
  padding: 0 20px;
}
.social-login ul {
  margin: 0;
  width: 100%;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.social-login ul li {
  display: inline-block;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 50%;
  width: 50%;
  list-style: none;
  padding: 0 10px;
}
.social-login ul li a.btn {
  width: 100%;
  border-radius: 5px;
  padding: 20px 0;
  color: #ffffff !important;
  background: #f4f5f7;
}
.social-login ul li a.btn i {
  margin-right: 7px;
}
.social-login ul li a.btn.connect-fb {
  background: #0a3e60;
}
.social-login ul li a.btn.connect-google {
  background: #cc4118;
}
.social-login ul li a.btn.connect-linkedin {
  background: #0073b0;
}
.social-login ul li a.btn.connect-twitter {
  background: #20a4ea;
}
.signup .modal-dialog {
  max-width: 880px;
  z-index: +999999;
}
.signup .form-group {
  margin-bottom: 1.5rem;
}
.modal-dialog-centered .btn {
  font-weight: 600;
}
span.mod-close {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 15px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 13px;
  color: #074da3;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 5px 24px rgba(31, 37, 59, 0.15);
  -webkit-box-shadow: 0 5px 24px rgba(31, 37, 59, 0.15);
}

/*------------------------------------------------------------
	Agency & Agents Design
-------------------------------------------------------------*/

/*------------ Agent Widgets ----------*/
.sides-widget {
  background-color: #fff;
  color: #707070;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.5s;
  margin-bottom: 30px;
}
.sides-widget {
  margin-bottom: 30px;
}
.sides-widget:last-child {
  margin-bottom: 0;
}
.sides-widget-header {
  position: relative;
  display: flex;
  padding: 1.5rem 1.4rem;
  color: #ffffff;
  align-items: center;
}
.sides-widget-details {
  padding-left: 20px;
  display: block;
  position: relative;
}
.sides-widget-body {
  padding: 1.5rem 1.4rem;
}
.sides-widget-details h4 {
  margin-bottom: 2px;
  color: #ffffff !important;
}
.sides-widget-details h4 > a {
  color: #ffffff !important;
}

.sides-widget-header .agent-photo {
  float: left;
  display: block;
}
.sides-widget-header .agent-photo img {
  border-radius: 50%;
  max-width: 60px;
}

.sides-widget-details span {
  color: rgba(255, 255, 255, 0.7);
}
.sides-widget-details span i {
  font-size: 18px;
  position: relative;
  top: 3px;
  color: #ffeb3b;
  margin-right: 10px;
}
.simple-form .form-control,
.modal-dialog-centered .form-control {
  box-shadow: none;
  background: #f8fbfd;
  border: 1px solid #eff3f6;
}
.simple-form .form-control {
  background: #fff;
}
.simple-form input.form-control {
  background: #fff;
  height: 42px;
}
/*--------------- Agency --------------*/
.agency {
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  background-color: #fff;
  display: block;
  border-radius: 8px;
  margin-bottom: 30px;
}
.agency-list {
  padding: 0;
  display: flex;
  padding-right: 30px;
  margin-bottom: 30px;
}
.agency-list .agency-avatar {
  flex: 1;
  padding: 1rem;
}
.agency-list .agency-avatar img {
  margin: 0;
  border-radius: 8px;
  width: 100%;
}
.agency-list .agency-content {
  padding: 0 40px;
  padding-top: 30px;
  padding-bottom: 20px;
  flex: 3;
}
.agency-list .agency-name {
  padding-left: 0;
  margin-bottom: 5px;
}
.agency-list .agency-desc {
  line-height: 1.7;
}
.agency-list .agency-name h4 {
  margin-bottom: 5px;
}
.agency-list .agency-name span {
  margin-top: 2px;
  font-size: 15px;
}
.agency-list .agency-name span i {
  margin-right: 5px;
}
.prt-detio span {
  padding: 5px 15px;
  display: inline-block;
  background: rgba(255, 87, 34, 0.1);
  border-radius: 50px;
  margin-bottom: 5px;
  font-size: 13px;
  color: #ff5722;
}
.agency-detail-info {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #909090;
  display: block;
  padding: 10px 0px;
}
.agency-detail-info li {
  position: relative;
  margin-left: 28px;
  line-height: 24px;
  padding: 3px 0;
  word-break: break-all;
}
.agency-detail-info li i {
  font-size: 18px;
  position: relative;
  top: 5px;
  left: -28px;
  position: absolute;
}
.agency-list .social-icons {
  left: 0;
  padding: 0;
  margin: 7px 0 0px 0;
  float: none;
}
.agency-list .social-icons li {
  display: inline-block;
  margin-right: 10px;
}
.agency-list .social-icons li a {
  border: 1px solid #e7ebfb;
  background: #f0f3ff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  top: 0 !important;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.agency-list .social-icons li a i {
  height: auto;
  width: auto;
}
/*--------- Change List Layout -------------*/
.fs-listings,
.listings-container {
  position: relative;
  z-index: 9;
}
.list-layout .property-listing.property-1 .listing-detail-wrapper {
  padding: 1rem 1rem 0.5rem;
}
.list-layout .property-listing.property-1 {
  display: flex;
  margin: 0 0 30px 0;
}
.list-layout .price-features-wrapper {
  padding: 0.1rem 1rem 0.6rem;
}
.fr-can-rating {
  color: #cbd7e2;
  letter-spacing: 0.2px;
}
.fr-can-rating .filled {
  color: #ff8100;
}
.listing-locate {
  flex: 1;
}
.list-layout .property-listing.property-1 .listing-img-wrapper {
  height: 0;
  min-height: 210px;
  flex: 2;
  overflow: hidden;
  border-radius: 3px 0 0 3px;
}
.list-layout .property-listing.property-1 .listing-img-wrapper a {
  min-height: 210px;
}
.list-layout .property-listing.property-1 .listing-name {
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 1.4;
}
.list-layout .property-listing.property-1 .listing-img-wrapper img {
  height: 210px;
  object-fit: cover;
}
.list-layout .property-listing.property-1 .listing-img-wrapper > a:before {
  background: transparent;
}
.list-layout .property-listing.property-1 .listing-content {
  flex: 3;
  position: relative;
}
.list-layout .property-listing.property-1 .listing-footer-wrapper {
  padding: 0.5rem 1rem 0.5rem;
  border-top: 1px solid #e5e9ec;
  width: 100%;
  display: flex;
  vertical-align: middle;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  left: 0;
}

.btn.btn-order-by-filt {
  background: transparent;
  box-shadow: 0 0 6px 1px rgba(62, 28, 131, 0.1);
  -webkit-box-shadow: 0 0 6px 1px rgba(62, 28, 131, 0.1);
  color: #646e7b;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: none;
  border-radius: 4px;
}

.btn.btn-order-by-filt.light {
  background: #ffffff;
}
.custom-drop .dropdown-menu {
  top: 34px !important;
  border: 1px solid #eceff3;
  right: 0 !important;
  left: initial !important;
}
.custom-drop .dropdown-menu a {
  display: block;
  padding: 5px 16px;
  color: #6e727d;
  transition: all 0.4s;
}
.filter-fl {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
}
.filter-fl h4 {
  flex: 1;
  margin: 0;
}
.item-shorting-box {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;
  width: 100%;
}
.item-shorting {
  flex: 1;
}
.shorting-by {
  display: inline-flex;
  width: 140px;
  margin-right: 10px;
}
.item-shorting-box-right {
  display: flex;
  align-items: center;
}
.shorting-list {
  display: block;
  padding: 0;
  margin: 0;
}
.shorting-list li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
}
.shorting-list li:last-child {
  margin-right: 0px;
}
.shorting-list li a {
  background: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all ease 0.4s;
}
.shorting-list li a.active,
.shorting-list li a:hover,
.shorting-list li a:focus {
  background: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #19ce6d;
}
.shorting-by .select2-selection.select2-selection--single {
  padding-left: 15px;
  border-radius: 4px;
  height: 40px;
}
.shorting-by .select2-selection.select2-selection--single {
  padding-left: 15px;
  border-radius: 4px;
  height: 48px;
}

.shorting-by
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 48px;
}
.shorting-by
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 48px;
}
/*--------------------- page Sidebar ------------------------*/
.page-sidebar {
  border-radius: 0.4rem;
  border: 1px solid #e6eaf1;
  background: #ffffff;
  padding: 25px;
  margin-bottom: 30px;
}
.form-group.simple .form-control {
  margin: 0 !important;
  box-shadow: none !important;
  background: #ffffff;
  border: 1px solid #e6eaf3;
  color: #707e9c;
  border-radius: 4px;
}
.form-group.simple span.select2-selection.select2-selection--single {
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid #e6eaf3;
}
.irs--flat .irs-line {
  top: 25px;
  height: 3px;
  background-color: #d9dfe8;
  border-radius: 50px;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
  display: none;
}
.irs--flat .irs-bar {
  top: 25px;
  height: 3px;
  background-color: #00b074;
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: -1px;
  left: 50%;
  width: 12px;
  height: 12px;
  margin-left: -3px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #00b074;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background-color: #00b074;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: #00b074;
}
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #00b074;
}

/*------------------------------------------------------------
	Blog Designs
-------------------------------------------------------------*/

/*----------- Blog Grid ---------*/
.blog-wrap-grid {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
}
.blog-thumb {
  padding: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.blog-thumb img {
  border-radius: 4px;
}
.blog-info {
  padding: 10px 20px;
}
.blog-info i {
  margin-right: 7px;
}
.blog-body {
  padding: 5px 20px 30px;
}
.bl-title {
  font-size: 19px;
  margin-bottom: 4px;
}
.bl-continue {
  position: relative;
  font-family: "Lora", serif;
  font-style: italic;
  font-size: 20px;
  margin-top: 12px;
  display: inline-block;
}

/*--------- Blog Detail Page -----------*/
.single-post-item {
  display: block;
  width: 100%;
  background: #ffffff;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 30px;
  border: 2px solid #e5e8f3;
}
.post-featured-img {
  display: block;
  margin-bottom: 1.5rem;
}
.post-featured-img img {
  border-radius: 6px;
}
.single-post-item .post-details .meta-comment-tag {
  padding: 0;
  list-style: none;
}
.single-post-item .post-details .meta-comment-tag li {
  display: inline-block;
  margin-right: 19px;
}
.single-post-item .post-details .meta-comment-tag li a {
  color: #6e7c90;
  display: block;
  letter-spacing: 0;
  line-height: 18px;
}
.single-post-item .post-details .meta-comment-tag li a .icons {
  margin-right: 10px;
}
.single-post-item .post-details .post-title {
  display: block;
  font-weight: 700;
  line-height: 1.4;
  margin: 2px 0 0;
}
.single-post-item .post-details p,
.single-post-item .post-details .text {
  color: #6e7c90;
  font-size: 16px;
  margin: 25px 0 0;
}
.blog-page .blog-details blockquote {
  background: #f7f9fc;
  padding: 28px 28px 28px 100px;
  margin: 50px 0;
  position: relative;
}
.blog-page .blog-details blockquote .icon {
  color: #074da3;
  position: absolute;
  font-size: 28px;
  line-height: 28px;
  left: 35px;
  top: 42%;
}
.blog-page .blog-details blockquote .name {
  color: #000c35;
  margin: 12px 0 0;
}
.blog-page .blog-details .post-bottom-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 43px 0 0;
}
.blog-page .blog-details .post-bottom-meta .post-tags {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.blog-page .blog-details .post-bottom-meta .post-tags .pbm-title {
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 20px;
}
.blog-page .blog-details .post-bottom-meta .post-tags .list {
  padding: 0;
  list-style: none;
}
.blog-page .blog-details .post-bottom-meta .post-tags .list li {
  display: inline-block;
  margin-bottom: 10px;
}
.blog-page .blog-details .post-bottom-meta .post-tags .list li a {
  border: 1px solid #e4e4e6;
  letter-spacing: 0;
  display: block;
  padding: 9px 20px;
}
.blog-page .blog-details .post-bottom-meta .post-tags .list li a:hover {
  color: #ffffff;
  background: #074da3;
  border-color: #074da3;
}
.blog-page .blog-details .post-bottom-meta .post-share .list {
  padding: 0;
  list-style: none;
}
.blog-page .blog-details .post-bottom-meta .post-share .list li {
  display: inline-block;
  margin-right: 22px;
}
.blog-page .blog-details .post-bottom-meta .post-share .list li:last-child {
  margin-right: 0;
}
.blog-page .blog-details .post-bottom-meta .post-share {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  text-align: right;
}
.blog-page .blog-details .post-bottom-meta .post-share .pbm-title {
  font-size: 18px;
}
.blog-page .blog-details .post-bottom-meta .post-share .list li a {
  color: #8c96b5;
  display: block;
  font-size: 16px;
  line-height: 22px;
}
.blog-page .blog-details .post-bottom-meta .post-share .list li a:hover {
  color: #ef4060;
}
.single-post-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #e4e4e6;
  padding: 48px 0 0;
  margin: 40px 0 0;
}
.single-post-pagination .prev-post {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}
.single-post-pagination .next-post {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  text-align: right;
}
.single-post-pagination .next-post .title,
.single-post-pagination .prev-post .title {
  font-size: 20px;
}
.single-post-pagination .post-pagination-center-grid {
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.single-post-pagination .post-pagination-center-grid a {
  font-size: 2.5rem;
  color: #074da3;
}
.single-post-pagination .prev-post a {
  display: block;
  overflow: hidden;
}
.single-post-pagination .prev-post a .title-with-link .intro {
  color: #6e7c90;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1;
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  margin: 0 0 9px;
}
.single-post-pagination .prev-post a .title-with-link .title {
  color: #000c35;
  line-height: 28px;
}

/*-------- Comment ---------*/
.blog-page .blog-details .comment-area .all-comments .comments-title {
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 35px;
}
.blog-page .blog-details .comment-area .all-comments .comment-list ul {
  padding: 0;
  list-style: none;
}
.blog-page .blog-details .comment-area .all-comments article {
  overflow: hidden;
  margin: 0 0 50px;
}
.blog-page .blog-details .comment-area .all-comments article .comment-author {
  float: left;
  width: 100px;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-author
  img {
  max-width: 100px;
  border-radius: 50%;
}
.blog-page .blog-details .comment-area .all-comments article .comment-details {
  overflow: hidden;
  padding-left: 30px;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-left-meta {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .author-name {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 2px;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .author-name
  .selected {
  font-size: 12px;
  color: #cacfef;
  margin-left: 4px;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-left-meta
  .comment-date {
  color: #ef4060;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 500;
  margin: 7px 0 0;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-reply {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  text-align: right;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-reply
  .reply {
  color: #6e7c90;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-meta
  .comment-reply
  .reply
  .icon {
  margin-right: 8px;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-text {
  margin: 19px 0 0;
}
.blog-page
  .blog-details
  .comment-area
  .all-comments
  article
  .comment-details
  .comment-text
  p {
  margin: 0;
}
.blog-page .blog-details .comment-area .all-comments .comment-list ul li ul {
  padding-left: 40px;
  list-style: none;
}
.posts-author {
  text-align: center;
}
.posts-author span.img {
  width: 120px;
  overflow: hidden;
  border-radius: 50%;
  height: 120px;
  border: 4px solid #e4eaf3;
  display: table;
  margin: 0 auto 1rem auto;
}
span.img img {
  border-radius: 50%;
  max-width: 120px;
}
h3.pa-name {
  font-size: 18px;
  margin-bottom: 5px;
}
.social-links {
  padding: 0;
  margin: 0 0 1rem 0;
}
.social-links {
  padding: 0;
  margin: 0 0 1rem 0;
}
.social-links li {
  display: inline-block;
  padding: 2px 10px;
}
.social-links li a {
  color: #8c96b5;
  font-size: 16px;
}
.social-links li a:hover,
.social-links li a:focus {
  color: #074da3;
}
/*-------------- Sidebar -----------*/

.single-widgets {
  border: 2px solid #e5e8f3;
  padding: 30px 28px 35px;
  background: #ffffff;
  margin: 0 0 40px;
  border-radius: 6px;
}
.single-widgets.widget_search form {
  position: relative;
}
.single-widgets.widget_search form input[type="search"] {
  border-radius: 4px;
  border: 1px solid #ebedf5;
  background: #f9fafc;
  font-size: 12px;
  padding: 10px 70px 10px 30px;
  height: 60px;
  width: 100%;
}
.single-widgets.widget_search form button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0px;
  cursor: pointer;
  border-radius: 4px;
  background: #074da3;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  line-height: 50px;
  height: 50px;
  width: 52px;
}
.single-widgets.widget_category ul {
  padding: 0;
  list-style: none;
}
.single-widgets.widget_category ul li {
  display: block;
  border-bottom: 1px solid #eaeaea;
  padding: 11px 0;
}
.single-widgets.widget_category ul li:first-child {
  border-top: none;
}
.single-widgets.widget_categories ul li a {
  display: block;
  overflow: hidden;
  letter-spacing: 0;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 24px;
}
.single-widgets.widget_category ul li a span {
  float: right;
}
.single-widgets.widget_thumb_post ul {
  padding: 0;
  list-style: none;
}
.single-widgets.widget_thumb_post ul li {
  display: block;
  overflow: hidden;
  margin: 0 0 15px;
}
.single-widgets.widget_thumb_post ul li .left {
  display: block;
  float: left;
  width: 80px;
}
.single-widgets.widget_thumb_post ul li .right {
  display: block;
  overflow: hidden;
}
.single-widgets.widget_thumb_post ul li .right .feed-title {
  display: block;
  overflow: hidden;
  letter-spacing: 0;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  margin: 0 0 7px;
  padding-left: 20px;
}
.single-widgets.widget_thumb_post ul li .right .post-date {
  color: #6e7c90;
  display: block;
  overflow: hidden;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 18px;
  padding-left: 20px;
}
.single-widgets.widget_thumb_post ul li .right .post-date i {
  margin-right: 5px;
}
.single-widgets.widget_thumb_post ul li .left img {
  width: 100%;
  border-radius: 3px;
}
.single-widgets.widget_tags ul {
  padding: 0;
  list-style: none;
}
.single-widgets.widget_tags ul li {
  display: inline-block;
  margin-bottom: 5px;
}
.single-widgets.widget_tags ul li a {
  border: 1px solid #eaeaea;
  letter-spacing: 0;
  font-size: 14px;
  display: block;
  padding: 9px 25px;
}
.single-widgets.widget_tags ul li a:hover,
.single-widgets.widget_tags ul li a:focus {
  color: #ffffff;
  background: #074da3;
  border-color: #074da3;
}

/*------------------------------------------------------------
	General Features
-------------------------------------------------------------*/
/*------------- Testimonials ----------*/
.testimonial-wrap {
  padding: 30px 25px;
  background: #fff;
  border-radius: 8px;
  position: relative;
  border: 1px solid #e5e9ec;
}
.client-thumb-box {
  display: table;
  width: 100%;
}
.client-thumb-content {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}
.client-thumb {
  position: relative;
  max-width: 80px;
  margin-right: 20px;
  border-radius: 50%;
  border: 5px solid rgba(45, 45, 45, 0.1);
  padding: 5px;
  float: left;
}
.client-info h5 {
  color: #3151b7;
  font-size: 16px;
}
.client-info .small-font {
  font-size: 13px;
}
.testimonial-wrap p {
  line-height: 1.8;
  font-size: 15px;
}
/*------------- testimonial 2 Smart testimonials -------------*/
.smart-tes-author {
  text-align: left;
  padding: 0rem 0 1.2rem;
  display: inline-block;
  width: 100%;
}
.smart-center {
  text-align: center;
}
.smart-textimonials.smart-light {
  color: #ffffff;
}
.smart-textimonials .item {
  padding: 0 15px;
}

.smart-textimonials .item-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 2rem 1.2rem;
}

.st-author-thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
}
.smart-tes-content p {
  font-size: 15px;
}
.st-author-thumb .quotes {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -5px;
  bottom: -8px;
  color: #ffffff;
}
.st-author-thumb img {
  border-radius: 50%;
}

.smart-light .st-author-thumb,
.st-author-thumb.light {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  float: left;
}
.st-author-info {
  display: inline-block;
  margin-left: 0;
  margin-top: 2rem;
}
.smart-textimonials.smart-light .st-author-title {
  color: #ffffff;
}
.st-author-info .st-author-title {
  margin: 0;
  font-size: 16px;
  line-height: 1;
}
.st-author-info .st-author-subtitle {
  /* font-family: 'Lora', serif; */
  font-style: italic;
  font-size: 12px;
}
.st-author-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.smart-center .st-author-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
/*------------ pricing Box ----------------*/
.pricing-wrap {
  text-align: center;
  width: 100%;
  background: #ffffff;
  border-radius: 0.5rem;
  margin-top: 25px;
  position: relative;
  border: 1px solid #eaecf1;
}
.pricing-header {
  padding: 2em 0 2em;
  width: 100%;
  background: #eeeeee;
  border-radius: 5px;
}
.pricing-header .pr-title {
  text-transform: uppercase;
  font-size: 16px;
  margin: 0 0 5px 0;
  font-weight: 700;
}
.pricing-header .pr-subtitle {
  /* font-family: 'Lora', serif; */
  font-style: italic;
  font-size: 16px;
  letter-spacing: 1px;
}
.pricing-value {
  position: relative;
  display: table;
  margin: 2rem auto;
  text-align: center;
}
.pr-value {
  font-size: 4em;
  position: relative;
  line-height: 1;
  margin-bottom: 1rem;
}
.pr-value sup {
  font-size: 1.5rem;
  vertical-align: super;
}
.recommended .pr-value {
  color: #27cc8f !important;
}

.pricing-body ul {
  text-align: left;
  margin: 0;
  padding: 0;
}
.pricing-body ul li {
  list-style: none;
  padding: 14px 0px;
  position: relative;
  border-bottom: 1px dashed #e1e4ec;
}
.pricing-body ul li:last-child {
  border-bottom: none;
}
.pricing-bottom {
  display: table;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

/*---------- Slick Arrow -------------*/
.slick-next,
.slick-prev {
  width: 40px;
  height: 40px;
}
.slick-next {
  right: 25px;
}
.slick-prev {
  left: 25px;
  z-index: 1;
}
.slick-arrow:before {
  top: 0;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  background: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 900;
  box-shadow: 0 5px 24px rgba(31, 37, 59, 0.15);
  transition: 0.2s;
}
.top-arrow > .slick-next {
  top: -40px;
  right: 40px;
}
.top-arrow > .slick-arrow:before {
  box-shadow: none;
  background: #acc6e0;
}
.top-arrow > .slick-prev {
  top: -40px;
  right: 90px;
  left: auto;
}

/*====================================
	Dashboard
==================================*/
.dashboard-navbar {
  position: relative;
  width: 100%;
  padding: 0;
  background: #ffffff;
  border-radius: 10px;
}
.dashboard-navbar {
  box-shadow: none;
}
.dashboard .sm-sidebar {
  padding: 0.6rem 0;
}
.dashboard-wraper,
.submit-page {
  position: relative;
  width: 100%;
  padding: 2em;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  border-radius: 10px;
}
.d-user-avater {
  text-align: center;
  margin: 2em 0;
}
.d-user-avater img {
  max-width: 180px;
  border-radius: 50%;
  margin-bottom: 8px;
}
.d-user-avater h4 {
  margin: 10px 0 3px 0;
  font-size: 19px;
}
.d-navigation ul {
  padding: 0;
  margin: 0;
}
.d-navigation ul li {
  list-style: none;
  padding: 10px 15px;
  border-bottom: 1px solid #edf0f7;
}
.d-navigation ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.d-navigation ul li.active {
  background: rgba(15, 202, 152, 0.2) !important;
}
.d-navigation ul li.active a {
  color: #4caf50 !important;
}
.d-navigation ul li a {
  padding: 3px 0;
  color: #1b4167;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  /* font-family:'Jost', sans-serif; */
}
.d-navigation ul li a i {
  margin-right: 10px;
}

/*---------- Bookmark Property -------*/
table.property-table-wrap {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
}
table.property-table-wrap th {
  border: none;
  background-color: #66676b;
  text-align: left;
  padding: 18px 20px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 9;
  line-height: 1;
}
table.property-table-wrap th:first-child {
  /* width: 50%; */
  border-radius: 3px 0 0 3px;
}
table.property-table-wrap th:last-child {
  border-radius: 0 3px 3px 0;
}
table.property-table-wrap th i {
  margin-right: 3px;
  font-size: 14px;
}
table.property-table-wrap th {
  background: #2e3d5f;
}
table.property-table-wrap td {
  padding: 35px 20px;
  vertical-align: middle;
  border-bottom: 1px solid #e4e4e4;
}
table.property-table-wrap .property-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
table.property-table-wrap td:first-child {
  padding-left: 0px;
  line-height: 25px;
}
table.property-table-wrap .property-container img {
  width: 150px;
  border-radius: 3px;
  flex: 1.1;
  margin-right: 26px;
}
table.property-table-wrap .property-container .title {
  flex: 2;
}
table.property-table-wrap .property-container .title h4 {
  margin-bottom: 0;
  margin-top: 2px;
  display: block;
  font-size: 16px;
}
table.property-table-wrap td.action a {
  display: block;
  color: #677994;
  line-height: 20px;
  padding: 3px 0;
  position: relative;
  margin-left: 21px;
}
table.property-table-wrap td.action a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #f1f3f7;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
}
.bkmark a.delete {
  float: right;
}
table.property-table-wrap .property-container .title span {
  display: block;
}
table.property-table-wrap .property-container .title .table-property-price {
  display: inline-block;
  background: #f1f3f7;
  color: #677994;
  border-radius: 2px;
  font-size: 13px;
  margin-top: 9px;
  padding: 2px 10px;
}

/*-------------------------------------------------
  Footer Start   
--------------------------------------------------*/
/*---------- Call To Action -------------------*/
.call-to-act-wrap {
  padding: 50px 0;
}
.call-to-act {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #ffffff;
  align-items: center;
}
.call-to-act-head {
  flex: 1;
}
.call-to-act h3 {
  color: #ffffff;
  margin-bottom: 5px;
}
.btn.btn-call-to-act {
  background: #ffffff;
  padding: 18px 40px;
  border-radius: 50px;
  color: #333640;
  font-weight: 600;
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
}
form.newsletter-boxes {
  position: relative;
  background: #ffffff;
  border-radius: 50px !important;
  overflow: hidden;
  padding: 10px;
}
form.newsletter-boxes .form-control,
form.newsletter-boxes .btn {
  height: 60px;
}
form.newsletter-boxes .btn {
  border-radius: 50px !important;
}
/*---------- Small footer -------------------*/
footer {
  z-index: 99;
  position: relative;
}
footer.dark-footer {
  background: #00305c;
}
.footer-logo {
  padding: 20px 0;
  display: inline-block;
  width: 100%;
  border-top: 1px solid #eaeef3;
  border-bottom: 1px solid #eaeef3;
}
.nav-footer-logo {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
}
footer.footer-small {
  padding: 50px 0 0 0;
}
img.img-footer {
  max-width: 180px;
  margin-bottom: 2rem;
}
.ft-itext {
  font-weight: 500;
  font-size: 16px;
  text-align: center !important;
  margin: 10px;
}
.ft-itext i {
  margin-right: 5px;
}
ul.social {
  margin: 0;
  padding: 0;
}
ul.social li {
  display: inline-block;
  list-style: none;
  margin: 5px;
}
ul.social li a {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 12px 0 rgba(71, 119, 244, 0.2);
  box-shadow: 0 0px 12px 0 rgba(71, 119, 244, 0.2);
  line-height: 40px;
  text-align: center;
}
.footer-add {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
footer.skin-light-footer .footer-add {
  color: #6d8294;
}
.ft-copyright {
  border-top: 1px solid #eaeef3;
  padding: 15px 0 12px 0;
}
.ft-copyright p {
  margin-bottom: 0;
}
.footer-logo img {
  max-width: 185px;
}
.footer-widget {
  padding: 70px 0;
}
h4.widget-title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 15px;
  color: #082b41;
}
.footer-widget ul {
  padding: 0;
  margin: 0;
}
.footer-widget ul li {
  list-style: none;
  margin-top: 15px;
  display: block;
}
.footer-widget ul li a {
  color: #243751;
  font-weight: 600;
  font-size: 14px;
}
footer.skin-dark-footer h4 {
  color: #ffffff;
}
footer.skin-light-footer .footer-widget ul li a,
footer.skin-light-footer,
footer.skin-light-footer a {
  color: #6d8294;
  transition: all ease 0.4s;
  font-size: 14px;
  font-weight: 400;
}
footer.skin-dark-footer .footer-widget ul li a,
footer.skin-dark-footer,
footer.skin-dark-footer a {
  color: rgba(255, 255, 255, 0.6);
  transition: all ease 0.4s;
  font-size: 14px;
  font-weight: 400;
}
footer.skin-dark-footer .footer-widget ul li a:hover,
footer.skin-dark-footer .footer-widget ul li a:focus {
  color: #ffffff;
}
.widget_media_image {
  margin-top: 3rem;
}
.footer-bottom {
  padding: 20px 0;
  border-top: 1px solid #e9edf3;
}
.skin-dark-footer .footer-bottom {
  background: #00305c;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
ul.footer-bottom-social {
  margin: 0;
  padding: 0;
  float: right;
}
ul.footer-bottom-social li {
  display: inline-block;
  list-style: none;
  margin-right: 17px;
  margin-top: 0;
}
ul.footer-bottom-social li a {
  color: #546e7a;
}
ul.footer-bottom-social li a:hover,
ul.footer-bottom-social li a:focus {
  color: #007bff;
}
ul.footer-bottom-social li i {
  margin-right: 5px;
}
footer.skin-dark-footer ul.footer-bottom-social li a {
  color: #426ea5;
  font-size: 14px;
}
footer.skin-dark-footer ul.footer-bottom-social li a:hover,
footer.skin-dark-footer ul.footer-bottom-social li a:focus {
  color: #ffffff;
  opacity: 1;
}
.fw {
  margin-bottom: 18px;
  display: inline-block;
  position: relative;
  padding-left: 28px;
  width: 100%;
}
.fw:last-child {
  margin: 0;
}
.fw:before {
  font-family: "themify";
  vertical-align: initial;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 0;
}
.fw.fw-location:before {
  content: "\e693";
}
.fw.fw-mail:before {
  content: "\e75a";
}
.fw.fw-call:before {
  content: "\e621";
}
.fw.fw-skype:before {
  content: "\e72d";
}
.fw.fw-web:before {
  content: "\e665";
}

.other-store-link {
  width: auto;
  margin-bottom: 20px;
  display: inline-block;
}
.other-store-link:last-child {
  margin-bottom: 0;
}
.other-store-app {
  width: 250px;
  height: 70px;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 4px;
  padding: 0 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #ffffff;
}
.skin-light-footer .other-store-app {
  border: 1px solid #e5eaee;
  color: #3b4958;
}
.os-app-caps span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}
.skin-light-footer .os-app-caps span {
  color: rgb(31 62 88 / 60%);
}
.os-app-icon {
  margin-right: 13px;
}
.os-app-icon i {
  font-size: 32px;
}
.os-app-caps {
  font-size: 18px;
  font-weight: 600;
  color: #243751;
}
footer.skin-dark-footer .os-app-caps {
  color: #ffffff;
}
.f-newsletter {
  max-width: 510px;
  margin-top: 15px;
  display: block;
  position: relative;
}

.f-newsletter .form-control {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #7f8ca2;
  background-color: transparent;
  border: 2px solid #a5aebf;
  padding: 18px 20px 18px;
  border-radius: 3px;
  height: 60px;
}
.skin-dark-footer .f-newsletter .form-control {
  border: 2px solid #9ea6ba;
}
.f-newsletter .btn {
  bottom: 4px;
  position: absolute;
  right: 4px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 18px 12px 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: normal;
  width: auto;
  border-width: 0;
  color: #9ea6ba;
  cursor: pointer;
  border-radius: 2px;
  height: 52px;
  background: transparent;
}
.skin-dark-footer .form-control::-webkit-input-placeholder {
  color: #9ea6ba;
}
.skin-dark-footer .form-control:-ms-input-placeholder {
  color: #9ea6ba;
}
.skin-dark-footer .form-control::placeholder {
  color: #9ea6ba;
}

/* ====================== New Updated Styles ==================*/
.slick-dots li button:before {
  font-family: slick;
  font-size: 18px;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  content: "•";
  text-align: center;
  opacity: 0.3;
  color: #26345f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(1, 41, 91, 0.1);
  border-radius: 50%;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #626e80;
  line-height: 18px;
  content: "•";
  font-size: 7px;
}
/*--------- Team Styles -----------*/
.team-grid {
  background: #ffffff;
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  padding: 3em 0;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -moz-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.single-team {
  padding: 15px 10px;
}
.teamgrid-user {
  max-width: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.teamgrid-content {
  padding: 15px 0;
  text-align: center;
}
.teamgrid-content h4 {
  margin: 0;
}
.teamgrid-social ul {
  text-align: center;
  margin: 0;
  padding: 0;
}
.teamgrid-social ul li {
  display: inline-block;
  list-style: none;
  padding: 0 10px;
}
.f-cl {
  color: #1943d0;
}
.t-cl {
  color: #12b0e8;
}
.i-cl {
  color: #d62ba4;
}
.l-cl {
  color: #0c9a9e;
}
.g-cl {
  color: #f53500;
}
.p-cl {
  color: #d21818;
}
.icon-mi-left {
  display: flex;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 6px;
  padding: 1.2em 2em;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.icon-mi-left i {
  font-size: 55px;
  color: #333;
  text-align: left;
  position: relative;
  top: 5px;
  left: 0;
  line-height: 55px;
}
.icon-mi-left .icon-mi-left-content {
  padding: 10px 0 0 15px;
}
.icon-mi-left h4 {
  text-align: left;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}
.icon-mi-left p {
  line-height: 1.7;
}

/*--------- FAQ Design -----------*/
.faq-search form {
  position: relative;
  max-width: 610px;
  margin: 1.5rem auto;
}
.faq-search form input {
  padding-left: 30px !important;
  height: 60px;
  border: none;
  border-radius: 4px;
  padding-right: 50px !important;
}
.faq-search form button[type="submit"] {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  line-height: 63px;
  right: 0;
  color: #0050b4;
  font-size: 18px;
  border: 0;
  cursor: pointer;
  background-color: transparent;
}
.ac-para {
  line-height: 1.8;
}
.contact-box {
  border-radius: 10px;
  overflow: hidden;
  padding: 2.5em 2em;
  margin-bottom: 30px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -moz-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.contact-box i {
  font-size: 3em;
  display: table;
  margin: 0 auto 1rem auto;
}
.contact-box h4 {
  font-size: 18px;
  margin-bottom: 3px;
}
.contact-box p {
  margin-bottom: 0.3rem;
}
.contact-box .live-chat {
  background: rgba(25, 189, 88, 0.1);
  color: #19bd58;
  border: none;
  border-radius: 2px;
  padding: 4px 12px;
  cursor: pointer;
  outline: none;
  display: table;
  margin: 5px auto 0 auto;
}

/*----------- Checkout Design -----------*/
.pay-wrap {
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -moz-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.pay-wrap-header {
  width: 100%;
  padding: 12px 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e7eaf1;
}
.pay-wrap-header h4 {
  flex: 1;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}
.pw-right h3 {
  color: red;
  margin: 0;
}
.pw-right h3 sub {
  font-size: 15px;
  font-weight: 500;
  color: #767a8a;
}
.pw-first-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pw-first-content h4 {
  flex: 1;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}
.pw-first-content {
  padding: 10px 25px;
}
.pay-wrap button,
.pay-wrap button:hover,
.pay-wrap button:focus {
  background: rgba(25, 189, 88, 0.1);
  color: #19bd58;
  border: none;
  border-radius: 2px;
  padding: 4px 12px;
  cursor: pointer;
  outline: none;
}
#change-plan {
  padding: 10px 25px;
  position: relative;
}
.pw-content-detail {
  padding: 10px 25px;
  width: 100%;
  position: relative;
}
.pw-content-detail ul {
  position: relative;
  padding: 0;
  margin: 0;
}
.pw-content-detail ul li {
  position: relative;
  display: block;
  margin-bottom: 10px;
  list-style: none;
  padding-left: 30px;
}
.pw-btn-wrap {
  padding: 10px 25px 25px;
  width: 100%;
  position: relative;
}
.btn.btn-payment {
  background: #ffffff;
  color: #19bd58;
  text-align: center;
  width: 100%;
  border: 2px solid #19bd58;
  font-size: 17px;
  border-radius: 4px;
}
.pw-content-detail ul li:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-size: 10px;
  width: 20px;
  height: 20px;
  background: rgba(25, 189, 88, 0.1);
  display: flex;
  font-weight: 900;
  position: absolute;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #19bd58;
  top: 0;
  left: 0;
}
.product-wrap {
  border-radius: 10px;
  overflow: hidden;
  padding: 2em 1rem;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -webkit-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  -moz-box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.product-wrap ul {
  padding: 0;
  margin: 0;
}

.product-wrap ul li {
  display: flex;
  padding: 14px 0;
  border-bottom: 1px dashed #dadde8;
  flex-wrap: wrap;
}
.product-wrap ul li strong {
  flex: 1;
}
/*---------- Update 1.2 -------------------*/
form.dropzone.dz-clickable.primary-dropzone {
  border-radius: 0.4rem;
  background: #f8fbfd;
  border: 1px solid #eff3f6;
}
.dz-default.dz-message i {
  font-size: 3rem;
  display: block;
  margin-bottom: 1rem;
}
.dz-default.dz-message span {
  font-weight: 600;
}
.alert-success {
  color: #27b737;
  background-color: #e9ffeb;
  border-color: #e9ffeb;
}
.block-header.ags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}
.block-header.ags .block-title {
  flex: 1;
  margin: 0;
  line-height: 0;
}
.btn.btn-agent-add {
  background: #00ba74;
  border-radius: 4px;
  color: #ffffff !important;
}
form.dropzone.profile-logo.dz-clickable {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  font-size: 49px;
}
.form-submit.middle-logo {
  text-align: center;
  display: table;
  margin: 1rem auto;
}
.checkout-wrap {
  padding: 2rem 0;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #eef2f7;
}
.checkout-side {
  padding: 2rem 0 0;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  border: 1px solid #eef2f7;
}
.invoice-vew-detail {
  padding: 1rem 1rem;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eef2f7;
}
.checkout-head {
  padding-bottom: 2rem;
  border-bottom: 1px solid #e7eaec;
}
.checkout-body {
  padding: 2rem 2rem 0;
}
.checkout-head ul {
  display: flex;
  width: 100%;
  align-items: center;
}
.checkout-head ul li {
  width: 33.333333%;
  flex: 0 0 33.333333%;
  list-style: none;
  text-align: center;
}
.checkout-head ul li span {
  display: table;
  background: white;
  width: 30px;
  height: 30px;
  margin: 5px auto;
  border-radius: 50%;
  line-height: 30px;
  color: #009688;
  font-weight: 500;
  box-shadow: 0px 0px 0px 4px rgba(210, 204, 201, 0.4);
  font-size: 12px;
}
.checkout-head ul li.active span {
  background: #009688;
  color: #ffffff;
  box-shadow: 0px 0px 0px 4px rgba(0, 150, 136, 0.4);
  -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 150, 136, 0.4);
}
.success-message {
  text-align: center;
}
span.thumb-check {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: table;
  margin: 5px auto 20px;
  line-height: 80px;
  font-size: 28px;
  background: #009688;
  color: #ffffff;
  box-shadow: 0px 0px 0px 4px rgba(0, 150, 136, 0.4);
  -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 150, 136, 0.4);
}
ul.booking-detail-list {
  margin: 0;
  padding: 0;
}
ul.booking-detail-list li {
  display: block;
  margin-bottom: 13px;
  width: 100%;
}
ul.booking-detail-list li span {
  float: right;
}
.booking-short {
  padding: 0 1rem 1rem;
}
.booking-short img {
  margin-bottom: 0.5rem;
  border-radius: 4px;
}

.booking-short h4 {
  font-size: 15px;
  margin-bottom: 0px;
}
.card-header {
  background: #ffffff;
  border: none;
  border-top: 1px solid #e9edf3;
  border-radius: 0;
}
#accordionExample {
  border-radius: 0;
  border: none;
}
.accordion .btn-link {
  padding: 3px 0;
  font-size: 13px;
  color: #333c56;
  font-size: 15px;
  font-weight: 600;
  height: 0;
}
.booking-bio ul {
  padding: 0;
  margin: 0;
}
.booking-bio ul li {
  margin-bottom: 12px;
  list-style: none;
}
.booking-bio ul li strong {
  display: block;
}
.invoice-vew-detail h4 {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
  font-size: 16px;
}
.invoice-vew-detail h4:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 2px;
  background: #ff5722;
  left: 0;
  bottom: 0;
}

/*-------------- Dashboard -------------*/
.dashboard-stat {
  display: inline-block;
  padding: 0;
  height: 160px;
  background-color: #444;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  transition: 0.3s;
  cursor: default;
}
.dashboard-stat-content {
  position: absolute;
  left: 32px;
  top: 50%;
  transform: translateY(-50%);
}

.dashboard-stat-content h4 {
  font-size: 42px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #ffffff;
  letter-spacing: 1px;
}
.dashboard-stat-content span {
  font-size: 18px;
  margin-top: 4px;
  line-height: 24px;
  font-weight: 300;
  display: inline-block;
}
.dashboard-stat-icon {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-40%);
  font-size: 80px;
  opacity: 0.2;
}
.dashboard-stat-icon {
  font-size: 65px;
}
.dashboard-stat.widget-1 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #1baf65;
}
.dashboard-stat.widget-2 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #f39f00;
}
.dashboard-stat.widget-3 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #002758;
}
.dashboard-stat.widget-4 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #ce024b;
}
.dashboard-stat.widget-5 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #a671ce;
}
.dashboard-stat.widget-6 {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.1)
  );
  background-color: #3a9dec;
}
body #Packages {
  background: #ffffff !important;
  display: inline-block;
  width: 100%;
  padding: 5px 0px;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
  border-radius: 4px;
  margin: 10px 0;
}
.btn.btn-link.collapsed,
.btn.btn-link:focus {
  text-decoration: none !important;
}
#extraPackages {
  padding: 2rem 1.5rem;
  border-radius: 4px;
  margin-bottom: 25px;
  box-shadow: 0 0 20px 0 rgb(19 115 234 / 10%);
}
.package-box {
  padding: 1rem 0rem 0;
  border: 1px solid #e8eaf1;
  border-radius: 4px;
  text-align: center;
}
ul.packages-lists-list {
  padding: 0 1rem 1rem;
  margin: 0;
}
ul.packages-lists-list li {
  list-style: none;
  padding: 5px 0;
}
.buypackage {
  background: #f4f5f7;
  padding: 1rem 1rem;
  border-top: 1px solid #e3e6ec;
}
.packages-features-title {
  font-size: 17px;
}
.buypackage span {
  margin-top: 0.7rem;
  display: block;
}
.pay-btn {
  padding: 15px 20px;
  border-radius: 4px;
  background: #f4f5f7;
  margin: 0px 13px 5px 0px;
  display: inline-block;
}
.pay-btn.paypal {
  background: #1468d0;
  color: #ffffff !important;
}
.pay-btn.stripe {
  background: #7f35e0;
  color: #ffffff !important;
}
.pay-btn.wire-trans {
  background: #1ca6e4;
  color: #ffffff !important;
}

/*-------- My Property Design -----------*/
.singles-dashboard-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border: 1px solid #eaeef3;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 30px;
}
.sd-list-left {
  flex: 1;
  max-width: 240px;
  height: 180px;
  max-height: 180px;
}
.sd-list-left img {
  height: 100%;
  object-fit: cover;
}
.sd-list-right {
  padding: 1rem 2rem;
}
.sd-list-right .listing_dashboard_title {
  font-size: 16px;
  margin-bottom: 5px;
}
.user_dashboard_listed {
  display: block;
  margin-bottom: 2px;
}
.sd-list-right .action {
  margin-top: 1rem;
}
.sd-list-right .action a {
  margin-right: 5px;
  display: inline-block;
  font-size: 12px;
  width: 30px;
  height: 30px;
  background: #474f5f;
  text-align: center;
  line-height: 32px;
  border-radius: 3px;
  color: #ffffff !important;
}

/*------------------------------------------------------------------
	New Update 13 July 2021
-------------------------------------------------------------------*/
.short_wraping {
  width: 100%;
  border: 1px solid #ebedf1;
  border-radius: 0.3rem;
  background: #ffffff;
  margin-bottom: 2rem;
  padding: 0.5rem 0.6rem;
}
ul.shorting_grid {
  padding: 0;
  margin: 0;
}
ul.shorting_grid li {
  display: inline-block;
  margin: 0;
  list-style: none;
  margin-right: 10px !important;
}
ul.shorting_grid li:last-child {
  margin-right: 0px !important;
}
ul.shorting_grid li a {
  font-size: 14px;
  font-weight: 600;
  color: #074da3;
}
ul.shorting_grid li a.active,
ul.shorting_grid li a.active span {
  color: #1266e3;
}
ul.shorting_grid li a span {
  color: #484c65;
  margin-right: 4px !important;
}
.shorting_pagination {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
}
.shorting_pagination_laft {
  flex: 1;
}
.shorting_pagination_laft h5 {
  margin: 0;
  line-height: 1;
  font-size: 13px;
}
.shorting_pagination_right ul {
  margin: 0;
  padding: 0;
}
.shorting_pagination_right ul li {
  list-style: none;
  display: inline-block;
  padding: 0 3px;
}
.shorting_pagination_right ul li a {
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #c9d1dc;
  color: #77859a;
  font-size: 11px;
  font-weight: 700;
}
.shorting_pagination_right ul li a.active {
  color: #1266e3;
  border-color: #1266e3;
}
.elco_bor {
  border-right: 1px solid #dfe2e8;
  border-left: 1px solid #dfe2e8;
}
.shorting-right .dropdown.show {
  display: inline-block;
}
.shorting-right {
  width: auto;
  background: transparent;
  border-radius: 2px;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.shorting-right .btn-filter {
  color: #1764d6;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
}

/*---------------- Property Styles -------------------*/
.property-listing {
  position: relative;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  background: #ffffff;
}

.property-listing.list_view .listing-img-wrapper {
  flex: 1;
  position: relative;
  min-width: 250px;
}
.property-listing.list_view .list-img-slide {
  overflow: hidden;
  position: relative;
  min-height: 220px;
  max-height: 220px;
  border-radius: 4px;
}
.property-listing.list_view.style_new .slick-slide img,
.property-listing.list_view.style_new .list-img-slide img {
  height: 220px;
  width: 100%;
  object-fit: cover;
}
.list-img-slide .slick-dots {
  position: absolute;
  bottom: 12px;
}
.list-img-slide .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 3px;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.list-img-slide .slick-dots li button:before {
  font-family: slick;
  font-size: 18px;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 6px;
  opacity: 1;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(51, 60, 86);
  border-radius: 50%;
  color: transparent !important;
  box-shadow: 0px 0px 0px 4px rgb(31 38 62 / 20%);
  -webkit-box-shadow: 0px 0px 0px 3px rgb(79 91 125);
}
.list-img-slide .slick-dots li.slick-active button:before {
  font-family: slick;
  font-size: 18px;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 6px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #27ae60;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 4px rgb(39 174 96 / 30%);
  -webkit-box-shadow: 0px 0px 0px 4px rgb(39 174 96 / 30%);
}
.list-img-slide .slick-dots li button:before {
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 0px 2px rgb(255 255 255 / 20%);
  -webkit-box-shadow: 0px 0px 0px 2px rgb(255 255 255 / 20%);
}
.property-listing.list_view .list_view_flex {
  padding-left: 15px;
}
.property-listing.list_view.style_new .listing-short-detail-wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}
._card_list_flex {
  display: flex;
  align-items: center;
  width: 100%;
}
._card_flex_01 {
  flex: 1;
}
.listing-card-info-price {
  font-weight: 700;
  position: relative;
  font-size: 22px;
  line-height: 1.4;
}
._card_list_flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.property-listing.list_view .listing-name {
  font-size: 15px;
  line-height: 1.4;
  max-width: 375px;
}
.property-listing.list_view.style_new {
  padding: 0.8rem;
  display: flex;
  align-items: flex-start;
}
.property-listing.list_view.style_new .price-features-wrapper {
  padding: 0;
}
.property-listing.list_view.style_new .list-fx-features {
  margin-top: 15px;
  justify-content: flex-start;
}
.property-listing.list_view.style_new .listing-detail-footer {
  border: none;
  padding: 1rem 0.4rem;
}
.property-listing.list_view.style_new .footer-first {
  flex: 1;
}
.property-listing.list_view.style_new .foot-rates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.foot-rates span.elio_rate {
  width: auto;
  padding: 2px 8px;
  background: #e42d29;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  color: #ffffff;
  margin-right: 5px;
}
span.elio_rate.good {
  background: rgba(33, 150, 243, 0.1);
  color: #2196f3;
}
span.elio_rate.perfect {
  background: rgba(0, 150, 129, 0.1);
  color: #009681;
}
span.elio_rate.poor {
  background: rgba(222, 27, 23, 0.1);
  color: #de1b17;
}
._rate_stio {
  display: flex;
  align-items: center;
  letter-spacing: 3px;
  font-size: 10px;
  color: #ff9800;
  position: relative;
}
.call-view {
  padding: 4px 18px;
  background: #ecf0f8;
  color: #455588;
  border-radius: 0.2rem;
  height: 42px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.default-terms_wrap {
  display: flex;
  align-items: flex-start;
}
.like_unlike_prt {
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 12px;
  z-index: 1;
}
.like_unlike_prt .toggler {
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1;
}
.like_unlike_prt [type="checkbox"]:checked,
.like_unlike_prt [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.like_unlike_prt input:checked + i {
  color: #ff5722;
}
ul.featur_5269 {
  padding: 0 !important;
  display: flex;
  align-items: center;
  width: 100%;
}
ul.featur_5269 li {
  display: inline-block !important;
  margin-right: 10px;
  flex: 0 !important;
  width: auto !important;
}
ul.featur_5269 li .ft_th {
  width: 20px;
  display: inline-flex;
}
ul.featur_5269 li .ft_th img {
  max-width: 18px;
}
.single-frt-slide {
  padding: 0 8px;
}
/*------------ Half Map Setting -------------*/
._mp_filter {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
._mp_filter.center {
  align-items: center;
}
._mp_filter_first {
  flex: 1;
}
.selected_item_wrap {
  display: flex;
  align-items: center;
  width: 100%;
}
.slt_single_item {
  margin-right: 5px;
}
.slt_single_item .remove_pills {
  font-size: 13px;
  font-weight: 600;
  color: #144273;
  padding: 10px 12px;
  background: #f0f4f9;
  border-radius: 0.2rem;
  display: inline-flex;
}
span.remove_cross:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  cursor: pointer;
  margin-left: 4px;
}
.slt_single_item .pills_clears {
  font-weight: 600;
  color: #144273;
}
a.map_filter {
  height: 54px;
  padding: 0 25px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  background: #e8ebf1;
  border-radius: 0.4rem;
  font-weight: 600;
  color: #16447d;
}
a.map_filter.min {
  height: 46px;
  border-radius: 0.2rem;
  border: 2px solid #d2d7e2;
  color: #333c56;
  font-weight: 600;
  background: #ffffff;
}
.modal-lg .modal-body {
  padding: 1.4rem 1em 2em;
}
.filter_modal {
  z-index: 1;
  width: 100%;
  height: 70vh;
  min-height: 470px;
  position: relative;
}
.filter_modal {
  position: relative;
  overflow: hidden;
}
.filter_modal_inner {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.filter_modal_flex {
  width: 100%;
  height: 70vh;
  min-height: 470px;
}
.adv_ft_title {
  border-bottom: 1px solid #e6eaef;
}
.modal-lg .mod-close {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 13px;
  right: 14px;
  background: #e6eaef;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 13px;
  color: #1a1e2b !important;
  cursor: pointer;
  z-index: 2;
}
.flt_single_item {
  position: relative;
  display: block;
  padding: 1rem 0;
  border-bottom: 1px solid #e6eaef;
}
.flt_item_lablel label {
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 0.4rem;
  color: #122250;
}
.flt_item_content {
  display: flex;
  flex-wrap: wrap;
}
.flt_item_content.flcl {
  width: 100%;
  flex-direction: column;
}
.switchbtn-wrap {
  margin-right: 12px;
  margin-bottom: 5px;
}
.switchbtn {
  position: relative;
  width: 100%;
  display: flex;
}
.switchbtn-wrap:last-child {
  margin-right: 0;
  margin-bottom: 5px;
}
.switchbtn {
  position: relative;
}
.switchbtn-checkbox {
  display: none;
}
.switchbtn-wrap label.switchbtn-label {
  letter-spacing: 0.5px;
  height: 42px;
  border-radius: 0.2rem;
  background: #eff1f7;
  font-weight: 500;
  font-size: 13px;
  color: #1e3569;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  min-width: 120px;
}
input:checked + .switchbtn-label {
  background: #2196f3;
  color: #ffffff;
}
label.switchbtn-label {
  background: #f4f5f7;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.switchbtn.paying [type="radio"]:checked + label:after {
  top: 14px;
  left: 6px;
}
.selected_item_wrap {
  display: flex;
  align-items: center;
  width: 100%;
}
.slt_single_item {
  margin-right: 5px;
}
.slt_single_item .remove_pills {
  font-size: 13px;
  font-weight: 600;
  color: #144273;
  padding: 10px 12px;
  background: #f0f4f9;
  border-radius: 0.2rem;
  display: inline-flex;
}
.slt_single_item .pills_clears {
  font-weight: 600;
  color: #144273;
}
span.remove_cross:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  cursor: pointer;
  margin-left: 4px;
}
._mp_filter.center {
  align-items: center;
}
.flt_item_content.align_center .switchbtn-wrap {
  width: 23%;
}
.elgio_filter {
  display: flex;
  align-items: center;
  width: 100%;
}
.elgio_ft_first {
  flex: 1;
}
.elgio_filter .btn {
  height: 50px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  border-radius: 0.2rem;
  padding: 0 25px;
}
.btn.elgio_reset {
  background: transparent;
  padding-left: 0;
  color: #333c56;
}
.btn .reset_counter {
  position: relative;
  margin-left: 4px;
  background: #00b074;
  color: #ffffff;
  padding: 1px 7px;
  border-radius: 50px;
  font-size: 12px;
}
.elgio_filter .btn {
  height: 50px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  border-radius: 0.2rem;
  padding: 0 25px;
}
.btn.elgio_reset {
  background: transparent;
  padding-left: 0;
  color: #333c56;
}
.adv_ft_title h5 {
  font-weight: 500;
}
.filter_modal_inner::-webkit-scrollbar {
  display: none;
}
._mp_filter_first .input-group {
  max-width: 410px;
  padding-right: 15px;
}
._mp_filter_first .form-control {
  background: #f1f4f7;
  border-radius: 0.4rem 0px 0px 0.4rem;
  border: none;
}
._mp_filter_first .input-group-append,
._mp_filter_first .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}
._mp_filter_first .input-group-append {
  margin-left: -1px;
}
._mp_filter_first .input-group-text {
  background: #1266e3;
  color: #ffffff;
  border-radius: 0rem 0.4rem 0.4rem 0rem;
  padding: 0 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
.simple-input span.select2-selection.select2-selection--single {
  padding-left: 15px;
  border-radius: 4px;
  border: 1px solid #e7eaf1;
}

/*------------ Single Property Design--------------*/
.cld-box,
.guests-box {
  position: relative;
  height: 48px;
  border: none;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  background: #f0f2f7;
}
.guests .guests-box {
  position: relative;
  height: 45px;
  border: 2px solid #f0f2f7;
  border-radius: 0.2rem;
}
.side-booking-body .form-group .form-control {
  padding-left: 35px;
}
.cld-box i {
  position: absolute;
  left: 10px;
  top: 16px;
}
.side-booking-body .form-group {
  margin-bottom: 0px;
  height: auto;
}
.cld-box .form-group .form-control {
  padding-left: 36px;
  font-size: 14px;
}
.guests-box {
  display: flex;
  width: 100%;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
}
.guests-box #guestNo,
.guests-box #kidsNo {
  border: none;
  width: 100px;
  text-align: center;
  text-align: center;
  color: #333c56;
  background: transparent;
  font-weight: 600;
}
.guests-box .counter-btn {
  background: #ffffff;
  border: none;
  border-radius: 0.2rem;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 13px;
  outline: none !important;
  line-height: 30px;
  text-shadow: none;
  color: #707e9c;
  cursor: pointer;
}
.guests-box #cnt-down,
.guests-box #kcnt-down {
  position: absolute;
  top: 6px;
  left: 6px;
}
.guests-box #cnt-up,
.guests-box #kcnt-up {
  position: absolute;
  top: 6px;
  right: 6px;
}
.side-booking-foot .price {
  margin: 0;
  font-size: 17px;
}
._adv_features {
  background: #f0f4f7;
  padding: 10px 0;
  border-radius: 6px;
  width: 100%;
}
._adv_features ul {
  padding: 0;
  margin: 0;
}
._adv_features ul li {
  font-size: 13px;
  padding: 0.3rem 1rem;
  font-weight: 600;
  color: #333c56;
}
._adv_features ul li span {
  float: right;
  font-weight: 700;
}
.btn.book_btn {
  width: 100%;
  height: 55px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}
input.form-control.light,
.form-control.light {
  background: transparent;
  border: 1px solid #e8eaf1;
}
.nav-pills.sider_tab {
  width: 100%;
}
.nav-pills.sider_tab li {
  width: 50%;
  flex: 0 0 50%;
  padding: 0 5px;
  margin: 0;
}
.nav-pills li {
  margin-right: 5px;
}
.nav-pills.sider_tab li a,
.nav-pills.sider_tab li .nav-link,
.nav-pills li a,
.nav-pills li .nav-link {
  padding: 17px 18px;
  border-radius: 6px;
  background: #0c2339;
  text-align: center;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 13px;
  width: 100%;
}
.nav-pills.sider_tab li a.active,
.nav-pills.sider_tab li .nav-link.active,
.nav-pills li a.active,
.nav-pills li .nav-link.active {
  background: #074da3;
}
.tabs_system.nav-pills {
  margin-bottom: 1rem;
}
._adv_features_list .form-check-input:checked + .form-check-label:before {
  background: #0fb376 !important;
}
.tabs_system.nav-pills li {
  padding: 0px 10px 0 0px;
}
.tabs_system.nav-pills .nav-link {
  background: #e9ebf1;
  padding: 0.9rem 1.5rem;
  font-size: 13px;
  font-weight: 600;
  color: #5e6888;
}
.tabs_system.nav-pills .nav-link.active {
  background: #27ae60;
  color: #ffffff;
}
.sider_blocks_wrap {
  background-color: #fff;
  color: #707070;
  border-radius: 6px;
  transition: 0.25s;
  margin-bottom: 20px;
}
.sider_blocks_wrap.shadows {
  border: none;
  box-shadow: 0 0px 10px 0px rgb(62 28 131 / 10%);
  -webkit-box-shadow: 0 0px 10px 0px rgb(62 28 131 / 10%);
}
.side-booking-body {
  padding: 1rem;
}
.side-booking-body .form-group {
  height: 80px;
  color: #707e9c;
  font-weight: 500;
  position: relative;
  padding: 10px 5px 10px 5px;
}
.side-booking-body .form-group {
  margin-bottom: 0px;
  height: auto;
}
.cld-box,
.guests-box {
  position: relative;
  height: 48px;
  border: none;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  background: #f0f4f7;
}
.side-booking-body .form-group .form-control {
  padding-left: 35px;
  font-size: 14px;
  margin: 0 !important;
  height: 100%;
  box-shadow: none !important;
  background: transparent;
  border: none;
  color: #707e9c;
}
.side-booking-body label,
.sider-block-body label {
  font-size: 13px;
  color: #282d3e;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
._adv_features_list label i {
  font-style: initial;
  font-weight: 600;
  float: right;
}
.side-booking-foot {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  padding: 0.5rem 0rem 0;
  /* width: 100%; */
  /* margin: 9px 18px 5px; */
}
.sb-header-left {
  flex: 1;
  font-weight: 800;
  font-size: 13px;
  color: #0c2339;
}
.side-booking-foot .price {
  margin: 0;
  font-size: 17px;
}
.side-booking-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid #f0f2f7;
  padding: 0.8rem 1rem;
}
.dismob_block.d-block {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background: #ffffff;
  border-top: 1px solid #f1f3f7;
  padding: 0.8rem 0.5rem;
}
.dismob_block_left {
  position: relative;
  padding: 0 0.5rem;
  width: 50%;
  flex: 0 0 50%;
  float: left;
  text-align: center;
}
a.edlio_btn_block {
  width: 100%;
  background: #2196f3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff !important;
  border-radius: 4px;
  text-decoration: none;
}
a.edlio_btn_block.light {
  background: #edf1f9;
  color: #304580 !important;
}
a.edlio_btn_block i {
  margin-right: 0.2rem;
}

/*---------- Datepicker ---------*/
.daterangepicker {
  border: 1px solid #dee3ea;
}
.daterangepicker {
  font-size: 15px;
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  color: #0c2339;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  color: #545d79 !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #ed3271;
  color: #fff !important;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: rgba(237, 50, 113, 0.1);
  color: #fe1445;
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  min-width: 35px;
  width: 37px;
  height: 37px;
  line-height: 34px;
  font-size: 13px;
}
th.next.available,
.prev.available {
  background: rgba(237, 50, 113, 0.1);
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: red !important;
}
.default-terms_wrap .default-terms_flex {
  margin-right: 5px;
}
/*--------------------------------------------------
	Responsiveness Start
---------------------------------------------------*/
@media all and (min-height: 600px) and (min-width: 767px) {
  .height-10 {
    height: 10vh;
  }
  .height-20 {
    height: 20vh;
  }
  .height-30 {
    height: 30vh;
  }
  .height-40 {
    height: 40vh;
  }
  .height-50 {
    height: 50vh;
  }
  .height-60 {
    height: 60vh;
  }
  .height-70 {
    height: 70vh;
  }
  .height-80 {
    height: 80vh;
  }
  .height-90 {
    height: 90vh;
  }
  .height-100 {
    height: 100vh;
  }
}

@media all and (max-width: 1199px) and (min-width: 991px) {
  .list-layout .property-listing.property-1 .listing-name {
    font-size: 13px;
  }
  .listing-card-info-price {
    font-size: 18px;
  }
}

@media (max-height: 600px) {
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
}

@media (min-width: 768px) {
  form.search-big-form.search-shadow .form-group,
  form.search-form.search-shadow .form-group {
    margin-bottom: 0;
  }
  .no-ul-list.third-row li {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
  .advance-search.advn-fixed {
    position: fixed;
    top: 89px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
}

@media (min-width: 992px) {
  .modal-dialog.modal-lg {
    max-width: 700px;
    margin: 100px auto 30px;
  }
  .side_stiky {
    position: sticky;
    top: 100px;
  }
  .header.header-fixed {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  .filter_search_opt {
    display: none;
  }
  .header.header-transparent {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 100%;
    z-index: 4;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .change-logo .nav-brand.fixed-logo {
    display: none;
  }
  .change-logo.header-fixed .nav-brand.fixed-logo {
    display: block;
  }
  .change-logo.header-fixed .nav-brand.static-logo {
    display: none;
  }
  .header.header-transparent.header-fixed {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  .header.header-transparent .nav-brand,
  .header.header-transparent .nav-brand:focus,
  .header.header-transparent .nav-brand:hover,
  .header.header-transparent .nav-menu > li > a {
    color: #ffffff;
  }
  .header.header-transparent.dark .nav-brand,
  .header.header-transparent.dark .nav-brand:focus,
  .header.header-transparent.dark .nav-brand:hover,
  .header.header-transparent.dark .nav-menu > li > a {
    color: #0c2339;
  }
  .header.header-transparent .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-transparent .nav-submenu .submenu-indicator-chevron {
    border-color: transparent #70798b #70798b transparent;
  }

  .header.header-transparent.dark .submenu-indicator-chevron {
    border-color: transparent #0c2339 #0c2339 transparent;
  }
  .header .nav-menu > .active > a .submenu-indicator-chevron,
  .header .nav-menu > .focus > a .submenu-indicator-chevron,
  .header .nav-menu > li:hover > a .submenu-indicator-chevron {
    border-color: transparent #074da3 #074da3 transparent;
  }
  .header.header-transparent .nav-menu > .active > a .submenu-indicator-chevron,
  .header.header-transparent .nav-menu > .focus > a .submenu-indicator-chevron,
  .header.header-transparent
    .nav-menu
    > li:hover
    > a
    .submenu-indicator-chevron {
    border-color: transparent #ffffff #ffffff transparent;
  }
  .header.header-transparent.dark
    .nav-menu
    > .active
    > a
    .submenu-indicator-chevron,
  .header.header-transparent.dark
    .nav-menu
    > .focus
    > a
    .submenu-indicator-chevron,
  .header.header-transparent.dark
    .nav-menu
    > li:hover
    > a
    .submenu-indicator-chevron {
    border-color: transparent #0c2339 #0c2339 transparent;
  }
  .header.header-transparent.header-fixed
    .nav-menu
    > .active
    > a
    .submenu-indicator-chevron,
  .header.header-transparent.header-fixed
    .nav-menu
    > .focus
    > a
    .submenu-indicator-chevron,
  .header.header-transparent.header-fixed
    .nav-menu
    > li:hover
    > a
    .submenu-indicator-chevron {
    border-color: transparent #074da3 #074da3 transparent;
  }
  .header.header-transparent.header-fixed .nav-brand,
  .header.header-transparent.header-fixed .nav-brand:focus,
  .header.header-transparent.header-fixed .nav-brand:hover,
  .header.header-transparent.header-fixed .nav-menu > li > a {
    color: #2c3f56;
  }
  .header.header-transparent.header-fixed
    .nav-menu.nav-menu-social
    > li.add-listing:hover
    a,
  .header.header-transparent.header-fixed
    .nav-menu.nav-menu-social
    > li.add-listing:focus
    a {
    color: #ffffff !important;
  }
  .header.header-transparent.header-fixed .submenu-indicator-chevron {
    border-color: transparent #2c3f56 #2c3f56 transparent;
  }
  .hero-banner {
    min-height: 580px;
  }
  .hero-search h1 {
    font-size: 40px;
  }
  h1,
  .h1 {
    font-size: 4.142em;
    line-height: 1.31818182em;
  }
  .breadcrumbs {
    margin-top: 92px;
  }
  .ct-header h1.big {
    font-size: 70px;
    line-height: 1;
  }
  .ct-u-display-tablex {
    min-height: 500px;
    height: 100vh;
  }
  .main-banner.image-banner,
  .hero-header,
  .hero-banner {
    min-height: 660px;
  }
  .hero-header.min-banner {
    height: 80vh;
    min-height: 400px;
  }

  .hero-banner h2,
  .main-banner h1 {
    font-size: 60px;
    line-height: 1.1;
  }

  .hero-content h1 {
    font-size: 60px;
  }
  .content h1 {
    font-size: 70px;
  }
  .hero-content p {
    font-size: 22px;
  }

  .jumbo-banner.software .hero-content {
    margin-top: 0em;
  }
  .jumbo-banner.software .hero-content h1 {
    font-size: 60px;
  }
  .page-title-wrap h1 {
    font-size: 55px;
    line-height: 1.4;
  }

  .modal-dialog {
    max-width: 600px;
    margin: 30px auto;
  }
  .cta-sec h1,
  .cta-sec h2 {
    font-size: 38px;
    margin-bottom: 12px;
  }
}
@media (min-width: 993px) {
  .head-shadow {
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
  }
  .header.head-border {
    border-bottom: 1px solid #e9ecef;
  }
  .mobile_nav {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hero-content h1 {
    font-size: 80px;
  }
  .core-nav .megamenu.open > .megamenu-content {
    width: 700px !important;
  }
}
@media (min-width: 1400px) {
  .main-banner.image-banner,
  .hero-header,
  .hero-banner {
    min-height: 660px;
    height: 100vh;
  }
}
@media (max-width: 1199px) {
  .property-listing.property-1 .listing-location {
    font-size: 13px;
    margin-bottom: 2px;
  }
  .job-new-list .btn.btn-outline-info.float-right {
    float: none !important;
    margin: 0;
    display: table;
  }
  .listing-card-info-icon {
    margin-right: 0.7rem;
    font-size: 12px;
  }
  .author-box {
    width: 55px;
    height: 55px;
  }
  .property-listing.property-2 .listing-detail-wrapper {
    padding: 1rem 1rem;
  }
  .map-popup-wrap .property-listing.property-2 .listing-detail-wrapper {
    padding: 0rem;
  }
  .foot-location {
    font-size: 13px;
    display: flex;
  }
  .listing-short-detail-flex.fl-2 {
    flex: 2.5;
  }
  .verticle-job-detail .jb-title {
    font-size: 18px;
  }
  .verticle-job-thumb {
    max-width: 60px;
    width: 60px;
  }
  .verticle-job-detail {
    margin-left: 65px;
  }
  ul li.submit-attri {
    display: none;
  }

  .dw-proprty-info li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 1023px) {
  .half-map .fs-inner-container {
    width: 100%;
  }

  .fs-left-map-box .hm-map-container.fw-map {
    width: 100%;
    height: 585px;
    position: relative;
    -webkit-transform: translate3d(0, 0, 0);
    overflow: hidden;
  }
  .half-map .fs-left-map-box {
    float: none;
  }
  .fr-grid-info ul li {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .header.nav-left-side .core-nav .wrap-core-nav-list .core-nav-list {
    float: none;
  }
  .nav-menu > li .nav-dropdown {
    background: transparent;
    padding: 0;
    box-shadow: none;
  }
  .nav-menu > li > .nav-dropdown:before {
    display: none;
  }
  .navigation-portrait .nav-menu.nav-menu-social {
    padding-top: 1rem;
  }
  .header-dark-transparent nav .menu li a {
    color: #606c8e;
  }
  .header-dark-transparent button.toggle-bar.core-nav-toggle {
    color: #ffffff;
  }
  .header-dark-transparent.header-fixed button.toggle-bar.core-nav-toggle {
    color: #728496;
  }
  .navigation-portrait .nav-menu.nav-menu-social > li > a {
    color: #006dbe !important;
  }
  .nav-menu.nav-menu-social > li.add-listing.blue > a {
    color: #ffffff !important;
  }
  .nav-menu.nav-menu-social > li.add-listing.light {
    background: #2471ff !important;
  }
  .nav-menu.nav-menu-social > li.add-listing.light a,
  .nav-menu.nav-menu-social > li.add-listing a {
    color: #ffffff !important;
  }
  .single-counter-wrap {
    flex: 0 0 50%;
    width: 50%;
  }
  .counter-info-groups {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .main-banner.image-banner,
  .hero-header,
  .hero-banner,
  .large-banner {
    min-height: 580px;
  }
  .pricing-body ul li {
    padding: 9px 10px;
    font-size: 15px;
  }
  .sm-sidebar {
    height: 100%;
    max-width: 300px;
    min-width: 310px;
    background-color: #fff;
    position: fixed !important;
    top: 0;
    z-index: +2000;
    overflow: auto;
    -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
    box-shadow: 0 2px 10px 0 #d8dde6;
    position: relative;
    animation: animateleft 0.4s;
    left: 0;
    display: none;
    border-radius: 0;
  }
  .dashboard .search-sidebar_header {
    padding: 0 1rem;
  }
  .change-logo .nav-brand.static-logo {
    display: none;
  }
  .nav-menu.nav-menu-social > li.add-listing a {
    top: 0px;
    padding: 15px 20px;
  }
  .search-sidebar_header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  ul.attributes {
    float: right;
  }
  button.toggle-bar.core-nav-toggle {
    color: #8594b3;
  }
  .nav-menu.nav-menu-social > li.add-listing {
    top: 0;
  }
  .core-nav .dropdown > .dropdown-menu {
    width: 100%;
  }
  .show-mb {
    display: initial !important;
  }

  h1.italian-header-capt {
    font-size: 25px;
  }
  .pricing-body ul li {
    padding: 9px 0;
    font-size: 15px;
  }
  .list-layout .property-listing.property-1 .listing-img-wrapper {
    flex: initial;
  }
  .list-layout .property-listing.property-1 .listing-content {
    flex: initial;
    position: relative;
  }
  .list-layout .property-listing.property-1 {
    display: block;
    margin: 0 0 30px 0;
  }
  .list-layout .property-listing.property-1 .listing-footer-wrapper {
    padding: 0.9rem 1rem;
    border-top: 1px solid #e5e9ec;
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    justify-content: initial;
    left: 0;
  }

  .agency-list {
    display: block;
  }

  .avl-features li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .slide-property-detail {
    position: relative;
    display: block;
  }
  .slide-property-first {
    flex: initial;
    margin-bottom: 1.5rem;
  }
  .single-advance-property .container-fluid.p-0 {
    padding: 0 15px !important;
  }
  .drops-dashboard {
    display: none;
  }
}

@media (max-width: 767px) {
  .default-terms_wrap .default-terms_flex {
    margin-right: 5px;
  }
  .call-to-act {
    display: block;
    text-align: center;
  }
  .flt_item_content.align_center .switchbtn-wrap {
    width: 46%;
    margin-right: 10px;
  }
  .hero-search h1 {
    font-size: 20px;
  }
  ._mp_filter.center.mb-3 {
    display: block;
  }
  .selected_item_wrap {
    display: flex;
    flex-flow: wrap;
  }
  .slt_single_item .remove_pills {
    font-size: 11px;
    font-weight: 600;
    color: #144273;
    padding: 10px 7px;
  }
  .slt_single_item .pills_clears {
    font-size: 13px;
  }
  .sty_1523 ._mp_filter_first {
    margin-bottom: 1rem;
  }
  a.map_filter.min {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .property-listing.list_view.style_new {
    display: flex;
    flex-wrap: wrap;
  }
  .property-listing.list_view .list-img-slide {
    min-height: auto;
    max-height: initial;
  }
  .property-listing.list_view.style_new .slick-slide img,
  .property-listing.list_view.style_new .list-img-slide img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
  .property-listing.list_view .list_view_flex {
    padding-left: 0;
    padding-top: 12px;
  }
  .property-listing.list_view.style_new .slick-dotted.slick-slider {
    margin-bottom: 00px;
  }
  ul.shorting_grid {
    display: none;
  }
  .elco_bor {
    border: none;
  }
  .shorting_pagination_laft h5 {
    font-size: 10px;
  }
  .shorting_pagination_right ul li {
    padding: 0 1px;
  }
  .shorting_pagination_right ul li a {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .shorting-right {
    width: auto;
    padding: 1px 0px 5px;
    justify-content: flex-start;
  }
  .shorting_pagination {
    margin-top: 10px;
  }
  .icon-features-wrap:before {
    display: none;
  }
  .middle-icon-features-content h4 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .item-shorting-box {
    display: block;
  }
  .item-shorting {
    margin-bottom: 1rem;
  }
  .call-to-act-head {
    margin-bottom: 1rem;
  }
  .footer-widget {
    padding-bottom: 0;
  }
  .footer-bottom {
    margin-top: 70px;
  }
  .dropdown-submenu > .dropdown-menu.show {
    display: block;
  }
  .full-search-2.hero-search-radius #advance-search {
    top: auto;
  }
  .property-search-type label {
    padding: 8px 16px;
    margin: 0 7px 0 0;
    font-size: 14px;
  }
  .property-search-type label:nth-child(3) {
    margin-right: 0;
  }
  .full-search-2.hero-search-radius #advance-search {
    padding: 2rem 0.5rem;
  }
  .full-search-2.hero-search-radius .form-check-input,
  .full-search-2.hero-search-radius .form-check-label,
  .full-search-2.hero-search-radius .radio-custom,
  .radio-custom-label {
    font-size: 14px;
  }
  .p-0.elio {
    padding: 0 6px !important;
  }
  .full-search-2.eclip-search.italian-search .b-r {
    border-right: none !important;
  }
  .full-search-2.eclip-search.italian-search .b-l {
    border-left: none !important;
  }
  .full-search-2.italian-search .form-group.borders {
    margin-bottom: 10px;
    height: auto;
    border: 1px solid #dce3ec;
    border-radius: 6px;
    overflow: hidden;
  }
  ul.deatil_features li {
    width: 50%;
    flex: 0 0 50%;
  }
  .property_block_wrap.style-3 {
    display: block;
  }
  .pbw-flex-1 {
    margin-bottom: 1rem;
  }
  .pbw-flex {
    padding-left: 0;
  }
  .rating-overview {
    display: block;
  }
  .rating-overview-box {
    width: 100%;
    border-right: none;
    margin-bottom: 1rem;
  }
  .rating-bars-item {
    width: calc(100% - 30px);
  }
  .seo-contact .p-0 {
    padding: 0 15px !important;
  }
  .seo-contact button.btn {
    width: 100%;
  }
  .nav-tabs .nav-item a {
    padding: 0.5em 1em;
  }

  .boxed.boxed--lg {
    padding: 2.77em 1em;
  }
  .icon--lg {
    font-size: 3.57142857em;
  }
  .error-page h2 {
    font-size: 100px;
  }
  .pos-vertical-center {
    top: 0;
    transform: none;
    -webkit-transform: none;
  }
  .listing-detail-item {
    display: inline-block;
    margin-left: 4px;
  }
  .tr-list-thumb {
    max-width: 75px;
    height: 75px;
    margin-right: 10px;
  }
  .tr-list-wrap {
    position: relative;
    bottom: 50px;
    display: block;
  }
  a.btn.btn-list {
    padding: 12px 10px;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .advance-search .form-group {
    margin: 0.6rem 0;
    border: 1px solid #e0ecf5;
  }
  .advance-search .search-big-form {
    padding: 10px !important;
  }
  .job-new-list .vc-thumb,
  .job-new-list .vc-content {
    float: none;
    margin: 0.5rem 0;
    display: table;
  }
  .mpl-0 {
    padding-left: 0 !important;
  }
  .verticle-candidate-list .vc-content {
    margin-top: 1rem;
  }

  .top-header .cn-info {
    display: none;
  }

  .full-search-2.hero-search-radius {
    border-radius: 10px;
  }

  .dashboard-navbar {
    margin-bottom: 2rem;
  }
  table.property-table-wrap .property-container img {
    display: none;
  }
  table.property-table-wrap td {
    padding: 35px 2px;
  }
  table.property-table-wrap .property-container .title h4 {
    font-size: 14px;
  }
  table.property-table-wrap td.action a {
    margin-left: 10px;
  }
  th.expire-date {
    display: none;
  }
  .page-sidebar {
    margin-top: 2rem;
  }
  li.login-attri.theme-log a {
    padding: 0 22px !important;
  }
  .blog-page .blog-details blockquote {
    padding: 20px;
    margin: 30px 0;
  }
  .blog-page .blog-details blockquote .icon {
    font-size: 20px;
    left: 20px;
    top: 15px;
  }
  .blog-page .blog-details .post-bottom-meta {
    display: block;
  }
  .blog-page .blog-details .post-bottom-meta .post-tags {
    margin-bottom: 1rem;
  }
  .blog-page .blog-details .post-bottom-meta .post-share {
    text-align: left;
  }
  .single-post-pagination {
    display: block;
  }
  .single-post-pagination .next-post {
    text-align: left;
  }
  .single-post-pagination .post-pagination-center-grid {
    display: none;
  }
  .blog-page .blog-details .comment-area .all-comments article .comment-author {
    width: 55px;
  }
  .blog-page
    .blog-details
    .comment-area
    .all-comments
    article
    .comment-author
    img {
    max-width: 50px;
    border-radius: 50%;
  }
  .blog-page
    .blog-details
    .comment-area
    .all-comments
    article
    .comment-details {
    overflow: hidden;
    padding-left: 10px;
  }
  .blog-page
    .blog-details
    .comment-area
    .all-comments
    article
    .comment-details
    .comment-meta
    .comment-left-meta
    .author-name {
    font-size: 14px;
    line-height: 13px;
    margin-bottom: 0;
  }
  .blog-page .blog-details .comment-area .all-comments .comment-list ul li ul {
    padding-left: 30px;
    list-style: none;
  }

  .modal-body {
    padding: 2.5em 1em;
  }
  .counter-info-groups {
    flex-wrap: wrap;
  }
  .single-counter-wrap {
    flex: 0 0 100%;
    width: 100%;
  }
  .half-map .fs-content {
    padding: 2em 1rem;
  }
}

@media (max-width: 600px) {
  .hero-header {
    min-height: 400px;
  }
  .signup-frm .btn {
    bottom: 4px;
    position: relative;
    right: 0;
    border-width: 0;
    height: 52px;
    margin-top: 12px;
    width: 100%;
  }
  ul.our-team-profile li a {
    width: 34px;
    height: 34px;
    line-height: 33px;
  }
  .rt-content-box ul.our-team-profile li {
    padding: 0;
    margin-right: 6px;
  }
  .lt-img-box {
    display: table;
    width: 105px;
    float: none;
    margin: 0 auto;
  }
  .rt-content-box {
    display: table;
    margin-left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .jumbo-banner .btn-trans-video,
  .hero-banner .btn-trans-video {
    display: block;
    margin-top: 10px;
    margin-left: 0 !important;
  }
  .error-page h2 {
    font-size: 50px;
  }
  .freelancer-thumb {
    float: none;
    margin-bottom: 1rem;
  }
  .freelancer-caption {
    display: block;
    margin-left: 0;
  }

  .hero-search-wrap {
    padding: 45px 20px 30px;
  }
  .header.nav-left-side ul.attributes li a {
    padding: 22px 8px;
    font-size: 12px;
  }

  .avl-features li {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .agency-list .agency-content {
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 20px;
  }
  .agency-list .agency-name h4 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .agency-list .social-icons li {
    display: inline-block;
    margin-right: 7px;
  }
  .btn.btn-agent-add {
    display: none;
  }
  .block-wraps-header .block-body {
    padding: 1rem;
  }
  .block-header.ags {
    padding: 1.4rem 0;
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: 0.2rem;
  }
  .block-wrap {
    padding: 0 1.5rem 1rem;
  }
  .ad-search,
  .ad-search:hover,
  .ad-search:focus {
    height: 52px;
    width: 100%;
    background: #f4f5f7;
    margin-bottom: 0.6rem;
  }
  .sec-heading.mss {
    width: calc(100% - 80px);
  }
  .sec-heading.mss h2 {
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
  }
  .sec-heading.mss p {
    font-size: 14px;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .list-layout .property-listing.property-1 .listing-name {
    font-size: 14px;
  }
  .elgio_filter .btn {
    height: 40px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    border-radius: 0.2rem;
    padding: 0 12px;
  }
  .listing-card-info-price {
    font-size: 17px;
  }
  .fs-inner-container .property-listing.property-1 .listing-location {
    font-size: 12px;
  }
  .prt-detail-title-desc h3 {
    font-size: 18px;
    line-height: 1.4;
  }
  ul.deatil_features li {
    width: 100%;
    flex: 0 0 100%;
  }
  .author-review article .article_comments_thumb {
    float: left;
    width: 60px;
  }
  .author-review article .article_comments_thumb img {
    max-width: 60px;
    border-radius: 50%;
  }
  .author-review article .comment-details {
    overflow: hidden;
    padding-left: 20px;
  }
  .author-review article .comment-details .comment-text p {
    font-size: 13px;
  }
  .nearby_header_first h5 {
    margin: 0;
    font-size: 13px;
  }
  .single-post-item .post-details .post-title {
    font-size: 20px;
    line-height: 1.3;
    margin: 5px 0 0;
  }
  .single-post-item .post-details p,
  .single-post-item .post-details .text {
    font-size: 15px;
    margin: 15px 0 0;
  }
}

/*====================================
 Bottom To top Scroll
=====================================*/
#back2Top {
  width: 40px;
  line-height: 40px;
  overflow: hidden;
  z-index: 999;
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 10px;
  right: 20px;
  text-align: center;
  font-size: 15px;
  border-radius: 4px;
  text-decoration: none;
  background: #333c56;
  color: #ffffff;
}
#back2Top:hover {
  background-color: #ffffff;
  color: #333c56;
}

@media only screen and (max-width: 767px) {
  .singles-dashboard-list {
    display: block;
    width: 100%;
  }
  .sd-list-left {
    max-width: 100%;
  }
  .sd-list-right {
    padding: 1rem 1rem;
  }
}

/*------------ Design For Dropdown menu --------------*/
.account-drop .dropdown-menu {
  top: 81px !important;
  right: 0 !important;
  background: #fff;
  box-shadow: none;
  border: solid 1px #ebedf3;
  min-width: 200px;
  left: initial !important;
  padding: 0;
}
.account-drop .dropdown-menu a {
  border-bottom: 1px solid #e9ebf3;
  padding: 1rem;
  width: 100%;
}
.account-drop .dropdown-menu a i {
  margin-right: 5px;
}
.account-drop .dropdown-menu a.active {
  color: #4caf50;
}
.form-group label {
  padding-bottom: 5px;
  margin-top: 15px;
}
