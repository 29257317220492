
.sidebar {
  width: 250px;
  background-color: #0b2948;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-menu-item {
  padding: 10px 20px;
  color: #b6c8d9;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.sidebar-menu-item:hover {
  background-color: #00458b;
}

.sidebar-menu-item-icon {
  margin-right: 10px;
}

.sidebar-menu-submenu {
  padding-left: 20px;
}

.sidebar-menu-submenu-header {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #ffffff;
}

.theme-light .sidebar {
  background-color: #ffffff;
  color: #607489;
}

.theme-light .sidebar-menu-item {
  color: #44596e;
}

.theme-light .sidebar-menu-item:hover {
  background-color: #c5e4ff;
  color: #44596e;
}

.theme-light .sidebar-menu-submenu-header {
  color: #44596e;
}
.ps-submenu-content{
  width: 100%;
}
.card-header p {
  margin-bottom: 0;
  color: #2f3d7e;
  font-weight: 600;
}
.content-body {
  background: #f5f5f5;
  padding-top: 1.875rem;
  padding-right: 1.875rem;
  padding-left: 1.875rem;
}
.page-titles {
  padding: 0.9375rem 1.875rem;
  background: #fff;
  margin-bottom: 1.875rem;
  border-radius: 0.5rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  font-size: 0.875rem;
}
.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
}
.page-titles .breadcrumb li a {
  color: #828690;
}
.page-titles .breadcrumb li.active {
  color: #3b4cb8;
  font-weight: 600;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #3b4cb8;
}
.page-titles .breadcrumb li.active a {
  color: #3b4cb8;
}
.label {
  font-size: 14px !important;
}
.form-control {
  padding: 8px 10px;
}
.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}
.text-gray-300 {
  color: #dddfeb !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}
.text-primary {
  color: #4e73df !important;
}
.text-gray-800 {
  color: #5a5c69 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

.text-xs {
  font-size: 0.7rem;
}
.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}
.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}
.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}
.card-title,
.modal-title {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  margin-bottom: 0;
}
table {
  font-size: 14px;
}
.button-list {
  text-align: center;
}
.button-list button {
  font-size: 14px;
  padding: 5px 10px;
}
.table-sub-header th {
  font-weight: 500;
  background: #2f3d7e;
  color: #fff;
}
.btn i {
  padding-right: 5px;
}
.card-body label {
  font-size: 14px;
}
.menu-vertical .menu-sub .menu-item {
  margin: 0.125rem 0;
}
.bg-menu-theme {
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  background-color: #fff;
  color: #6f6b7d;
}

.user-menu{
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1450px) {
  .button-list .btn{
   margin-top: 1rem;
  }
}