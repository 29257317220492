.spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea1 {
  width: 100% !important;
  padding: 1rem 2rem;
  border: 1px solid rgb(231, 227, 227);
  border-radius: 10px;
  outline: none;
  background:none;
  color: #000;
  margin-top: 5px;
}

.input {
  width: 100% !important;
  padding: 1rem 2rem;
  border: 1px solid rgb(231, 227, 227);
  border-radius: 10px;
  outline: none;
  margin-top: 5px;
  background:none;
  color: #000;
}

.col {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.label {
  font-weight: 600;
  color: #000;
}
.profileImgSection {
  margin-top: 2rem;
  /* margin-bottom: 1rem; */
}
.profileImg {
  width: 10rem;
  height: 10rem;
  border-radius: 30rem;
}

.btnCol {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.cardSectionComplete{
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commentCard {
  width: 100%;
  height: auto;
  /* height: 100vh; */
  overflow: scroll;
  padding: 3rem 3rem;
  background-color: #fff;
  box-shadow:
  12.5px 12.5px 10px rgba(0, 0, 0, 0.01),
  100px 100px 80px rgba(0, 0, 0, 0.02)
;
}
.profileSectionImg {
  width: 2rem;
  height: 2rem;
  border-radius: 3rem;
}

.loopingCommentSectionCard{
  margin-top: 1rem;
  width: 100%;

}

.firstSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileSectionLast{
  cursor: pointer;
}

.name {
  font-weight: 600;
  color: #000;
}

.profileSectionDetail {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.secondSection {
  margin-top: 1rem;
  margin-left: 4rem;
}
.renderingChild{
  margin-top: 1rem;
  margin-left: 0 !important;
}
.children{
  /* margin-left: 1rem; */
}
.depth0 {
  /* Top-level comments */
  margin-left: 0;
  margin-top: 1rem;
  width: 100%;
  /* border: 2px solid red; */
  background: #f1f1f1;
  padding: 10px;
}

.depth1 {
  /* First level of replies */
  margin-left: 10px;
  padding: 10px;
}
/* 
.depth2 {

  margin-left: 20px;
} */
.messageDescriptionReply {
  width: 100%;

}

.textareaDescription {
  width: 100% !important;
  height: 4rem;
  padding: 1rem 2rem;
  border: 1px solid rgb(231, 227, 227);
  border-radius: 10px;
  outline: none;
  background:none;
  color: #000;

}

/* .addCommentBtn{
  width: 15%;
  margin-top: 2rem;
} */


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-menu a {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: #f1f1f1;
}