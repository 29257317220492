.header {
  max-width: 60rem;
  margin: auto;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
}
.main-header{
  /* z-index: 999999999 !important; */
  z-index: 999 !important;
}

.list {
  display: flex;
  gap: 1rem;
}

.list a {
  text-decoration: none;
  color: var(--color-primary-400);
}

.list a:hover,
.list a.active {
  color: var(--color-primary-800);
}

.dropdown-toggle{
  display: flex !important;
    align-items: center !important;
    justify-content: center;
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -9px;
  background-color:#ED7D3A;
  color: white;
  border-radius: 2rem;
  padding: 5px;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}