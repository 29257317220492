.cardTop {
  display: flex;
  /* margin-top: 4px; */
  align-items: center;
  /* justify-content: flex-start; */
}
.nameSection {
  display: flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
}
.timeAgo {
  margin-left: 1rem;
  color: grey;
}
.profilePic {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mainCard {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
}
.profilePic img {
  width: 2rem;
  height: 2rem;
  border-radius: 5rem;
}

.badgebootstrap {
  margin-left: 1rem;
  margin-top: -10px;
}
.menu {
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;
}
.allCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.smallCard {
  /* width: auto; */
  height: 2rem;
  /* border: 2px solid #000; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-radius: 2rem;
  background-color: #f5f5f5;
}
.allCard {
  /* overflow-x: scroll; */
}
.smallCard > p {
  margin-bottom: 0;
  font-size: 14px;
}
.smallCard p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Ensure the width is constrained */
  display: inline-block;
}

.comment-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.faComment {
  background-color: #f5f5f5 !important;
}

.comment-section .fa-comment {
  font-size: 1.2em;
  margin-right: 5px;
  cursor: pointer;
}

.comment-count {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: -5rem !important;
}

@media only screen and (max-width: 773px) {
  .extraSmall {
    margin-top: -1rem;
  }
  .smallCard {
    margin-top: 1rem;
  }
  .profilePic {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
  }
  .mainCardAreaInside
  {
    width: 100%;
  }
  .menu{
    display: flex;
    align-items: center;
    justify-content: flex-start;
 
  }
}
